const schoolCodes = [
	{
		schoolCode: 1001,
		schoolFullName: "Abbotsford Public School",
	},
	{
		schoolCode: 1002,
		schoolFullName: "Aberdeen Public School",
	},
	{
		schoolCode: 1003,
		schoolFullName: "Abermain Public School",
	},
	{
		schoolCode: 1007,
		schoolFullName: "Adaminaby Public School",
	},
	{
		schoolCode: 1008,
		schoolFullName: "Adamstown Public School",
	},
	{
		schoolCode: 1009,
		schoolFullName: "Adelong Public School",
	},
	{
		schoolCode: 1015,
		schoolFullName: "Albion Park Public School",
	},
	{
		schoolCode: 1016,
		schoolFullName: "Timbumburi Public School",
	},
	{
		schoolCode: 1017,
		schoolFullName: "Albury Public School",
	},
	{
		schoolCode: 1019,
		schoolFullName: "Albury West Public School",
	},
	{
		schoolCode: 1028,
		schoolFullName: "Alma Public School",
	},
	{
		schoolCode: 1030,
		schoolFullName: "Alstonville Public School",
	},
	{
		schoolCode: 1040,
		schoolFullName: "Anna Bay Public School",
	},
	{
		schoolCode: 1041,
		schoolFullName: "Annandale Public School",
	},
	{
		schoolCode: 1042,
		schoolFullName: "Annandale North Public School",
	},
	{
		schoolCode: 1045,
		schoolFullName: "Appin Public School",
	},
	{
		schoolCode: 1048,
		schoolFullName: "Arcadia Public School",
	},
	{
		schoolCode: 1051,
		schoolFullName: "Ardlethan Central School",
	},
	{
		schoolCode: 1055,
		schoolFullName: "Ariah Park Central School",
	},
	{
		schoolCode: 1057,
		schoolFullName: "Armidale City Public School",
	},
	{
		schoolCode: 1058,
		schoolFullName: "Drummond Memorial Public School",
	},
	{
		schoolCode: 1061,
		schoolFullName: "Artarmon Public School",
	},
	{
		schoolCode: 1064,
		schoolFullName: "Ashbury Public School",
	},
	{
		schoolCode: 1065,
		schoolFullName: "Ashfield Public School",
	},
	{
		schoolCode: 1066,
		schoolFullName: "Ashford Central School",
	},
	{
		schoolCode: 1070,
		schoolFullName: "Asquith Public School",
	},
	{
		schoolCode: 1071,
		schoolFullName: "Attunga Public School",
	},
	{
		schoolCode: 1073,
		schoolFullName: "Auburn North Public School",
	},
	{
		schoolCode: 1075,
		schoolFullName: "Auburn West Public School",
	},
	{
		schoolCode: 1076,
		schoolFullName: "Austinmer Public School",
	},
	{
		schoolCode: 1077,
		schoolFullName: "Austral Public School",
	},
	{
		schoolCode: 1081,
		schoolFullName: "Avoca Public School",
	},
	{
		schoolCode: 1082,
		schoolFullName: "Avoca Beach Public School",
	},
	{
		schoolCode: 1084,
		schoolFullName: "Awaba Public School",
	},
	{
		schoolCode: 1085,
		schoolFullName: "Colo Vale Public School",
	},
	{
		schoolCode: 1098,
		schoolFullName: "Bald Blair Public School",
	},
	{
		schoolCode: 1104,
		schoolFullName: "Seaforth Public School",
	},
	{
		schoolCode: 1106,
		schoolFullName: "Balgownie Public School",
	},
	{
		schoolCode: 1111,
		schoolFullName: "Ballimore Public School",
	},
	{
		schoolCode: 1112,
		schoolFullName: "Ballina Public School",
	},
	{
		schoolCode: 1115,
		schoolFullName: "Balranald Central School",
	},
	{
		schoolCode: 1118,
		schoolFullName: "Bangalow Public School",
	},
	{
		schoolCode: 1121,
		schoolFullName: "Banksmeadow Public School",
	},
	{
		schoolCode: 1123,
		schoolFullName: "Greenacre Public School",
	},
	{
		schoolCode: 1124,
		schoolFullName: "Bankstown North Public School",
	},
	{
		schoolCode: 1129,
		schoolFullName: "Banora Point Public School",
	},
	{
		schoolCode: 1130,
		schoolFullName: "Baradine Central School",
	},
	{
		schoolCode: 1131,
		schoolFullName: "Barellan Central School",
	},
	{
		schoolCode: 1134,
		schoolFullName: "Bargo Public School",
	},
	{
		schoolCode: 1135,
		schoolFullName: "Barham Public School",
	},
	{
		schoolCode: 1136,
		schoolFullName: "Barkers Vale Public School",
	},
	{
		schoolCode: 1137,
		schoolFullName: "Barmedman Public School",
	},
	{
		schoolCode: 1138,
		schoolFullName: "Barnsley Public School",
	},
	{
		schoolCode: 1139,
		schoolFullName: "Barooga Public School",
	},
	{
		schoolCode: 1140,
		schoolFullName: "Barraba Central School",
	},
	{
		schoolCode: 1143,
		schoolFullName: "Barrington Public School",
	},
	{
		schoolCode: 1146,
		schoolFullName: "Baryulgil Public School",
	},
	{
		schoolCode: 1148,
		schoolFullName: "Bass Hill Public School",
	},
	{
		schoolCode: 1149,
		schoolFullName: "Batemans Bay Public School",
	},
	{
		schoolCode: 1150,
		schoolFullName: "Bathurst Public School",
	},
	{
		schoolCode: 1152,
		schoolFullName: "Batlow Technology School",
	},
	{
		schoolCode: 1160,
		schoolFullName: "Beckom Public School",
	},
	{
		schoolCode: 1164,
		schoolFullName: "Bedgerabong Public School",
	},
	{
		schoolCode: 1165,
		schoolFullName: "Beechwood Public School",
	},
	{
		schoolCode: 1166,
		schoolFullName: "Beecroft Public School",
	},
	{
		schoolCode: 1167,
		schoolFullName: "Beelbangera Public School",
	},
	{
		schoolCode: 1168,
		schoolFullName: "Bega Valley Public School",
	},
	{
		schoolCode: 1175,
		schoolFullName: "Bellata Public School",
	},
	{
		schoolCode: 1177,
		schoolFullName: "Bellbird Public School",
	},
	{
		schoolCode: 1178,
		schoolFullName: "Bellbrook Public School",
	},
	{
		schoolCode: 1179,
		schoolFullName: "Bellevue Hill Public School",
	},
	{
		schoolCode: 1181,
		schoolFullName: "Bellingen Public School",
	},
	{
		schoolCode: 1182,
		schoolFullName: "Belltrees Public School",
	},
	{
		schoolCode: 1183,
		schoolFullName: "Belmont Public School",
	},
	{
		schoolCode: 1185,
		schoolFullName: "Belmore North Public School",
	},
	{
		schoolCode: 1186,
		schoolFullName: "Belmore South Public School",
	},
	{
		schoolCode: 1189,
		schoolFullName: "Bemboka Public School",
	},
	{
		schoolCode: 1191,
		schoolFullName: "Bendemeer Public School",
	},
	{
		schoolCode: 1196,
		schoolFullName: "Ben Lomond Public School",
	},
	{
		schoolCode: 1198,
		schoolFullName: "Ben Venue Public School",
	},
	{
		schoolCode: 1199,
		schoolFullName: "Berala Public School",
	},
	{
		schoolCode: 1201,
		schoolFullName: "Beresfield Public School",
	},
	{
		schoolCode: 1202,
		schoolFullName: "Berkeley Public School",
	},
	{
		schoolCode: 1203,
		schoolFullName: "Berkeley Vale Public School",
	},
	{
		schoolCode: 1204,
		schoolFullName: "Bermagui Public School",
	},
	{
		schoolCode: 1205,
		schoolFullName: "Berowra Public School",
	},
	{
		schoolCode: 1206,
		schoolFullName: "Berridale Public School",
	},
	{
		schoolCode: 1207,
		schoolFullName: "Berrigan Public School",
	},
	{
		schoolCode: 1209,
		schoolFullName: "Berrima Public School",
	},
	{
		schoolCode: 1211,
		schoolFullName: "Berry Public School",
	},
	{
		schoolCode: 1215,
		schoolFullName: "Beverly Hills Public School",
	},
	{
		schoolCode: 1217,
		schoolFullName: "Bexhill Public School",
	},
	{
		schoolCode: 1223,
		schoolFullName: "Bigga Public School",
	},
	{
		schoolCode: 1224,
		schoolFullName: "Bilambil Public School",
	},
	{
		schoolCode: 1227,
		schoolFullName: "Ocean Shores Public School",
	},
	{
		schoolCode: 1228,
		schoolFullName: "Bilpin Public School",
	},
	{
		schoolCode: 1230,
		schoolFullName: "Binalong Public School",
	},
	{
		schoolCode: 1231,
		schoolFullName: "Binda Public School",
	},
	{
		schoolCode: 1234,
		schoolFullName: "Bingara Central School",
	},
	{
		schoolCode: 1237,
		schoolFullName: "Binnaway Central School",
	},
	{
		schoolCode: 1238,
		schoolFullName: "Binya Public School",
	},
	{
		schoolCode: 1240,
		schoolFullName: "Birchgrove Public School",
	},
	{
		schoolCode: 1246,
		schoolFullName: "Blackheath Public School",
	},
	{
		schoolCode: 1247,
		schoolFullName: "Black Hill Public School",
	},
	{
		schoolCode: 1249,
		schoolFullName: "Black Mountain Public School",
	},
	{
		schoolCode: 1252,
		schoolFullName: "Blackville Public School",
	},
	{
		schoolCode: 1254,
		schoolFullName: "Blakebrook Public School",
	},
	{
		schoolCode: 1255,
		schoolFullName: "Blakehurst Public School",
	},
	{
		schoolCode: 1256,
		schoolFullName: "Blandford Public School",
	},
	{
		schoolCode: 1257,
		schoolFullName: "Blaxcell Street Public School",
	},
	{
		schoolCode: 1258,
		schoolFullName: "Blaxland Public School",
	},
	{
		schoolCode: 1260,
		schoolFullName: "Blayney Public School",
	},
	{
		schoolCode: 1261,
		schoolFullName: "Blighty Public School",
	},
	{
		schoolCode: 1266,
		schoolFullName: "Boambee Public School",
	},
	{
		schoolCode: 1269,
		schoolFullName: "Bobin Public School",
	},
	{
		schoolCode: 1271,
		schoolFullName: "Bobs Farm Public School",
	},
	{
		schoolCode: 1272,
		schoolFullName: "Bodalla Public School",
	},
	{
		schoolCode: 1274,
		schoolFullName: "Bogan Gate Public School",
	},
	{
		schoolCode: 1275,
		schoolFullName: "Boggabilla Central School",
	},
	{
		schoolCode: 1276,
		schoolFullName: "Boggabri Public School",
	},
	{
		schoolCode: 1286,
		schoolFullName: "Bolwarra Public School",
	},
	{
		schoolCode: 1287,
		schoolFullName: "Bomaderry Public School",
	},
	{
		schoolCode: 1288,
		schoolFullName: "Bombala Public School",
	},
	{
		schoolCode: 1290,
		schoolFullName: "Bonalbo Central School",
	},
	{
		schoolCode: 1292,
		schoolFullName: "Bondi Beach Public School",
	},
	{
		schoolCode: 1293,
		schoolFullName: "Bongongo Public School",
	},
	{
		schoolCode: 1296,
		schoolFullName: "Bonshaw Public School",
	},
	{
		schoolCode: 1297,
		schoolFullName: "Bonville Public School",
	},
	{
		schoolCode: 1302,
		schoolFullName: "Boolaroo Public School",
	},
	{
		schoolCode: 1303,
		schoolFullName: "Booligal Public School",
	},
	{
		schoolCode: 1307,
		schoolFullName: "Boomi Public School",
	},
	{
		schoolCode: 1311,
		schoolFullName: "Booral Public School",
	},
	{
		schoolCode: 1313,
		schoolFullName: "Boorowa Central School",
	},
	{
		schoolCode: 1318,
		schoolFullName: "Boree Creek Public School",
	},
	{
		schoolCode: 1320,
		schoolFullName: "Boronia Park Public School",
	},
	{
		schoolCode: 1321,
		schoolFullName: "Bossley Park Public School",
	},
	{
		schoolCode: 1323,
		schoolFullName: "Botany Public School",
	},
	{
		schoolCode: 1326,
		schoolFullName: "Bourke Public School",
	},
	{
		schoolCode: 1327,
		schoolFullName: "Norfolk Island Central School",
	},
	{
		schoolCode: 1334,
		schoolFullName: "Bowning Public School",
	},
	{
		schoolCode: 1335,
		schoolFullName: "Bowral Public School",
	},
	{
		schoolCode: 1336,
		schoolFullName: "Bowraville Central School",
	},
	{
		schoolCode: 1340,
		schoolFullName: "Braidwood Central School",
	},
	{
		schoolCode: 1341,
		schoolFullName: "Branxton Public School",
	},
	{
		schoolCode: 1345,
		schoolFullName: "Breadalbane Public School",
	},
	{
		schoolCode: 1346,
		schoolFullName: "Colo Heights Public School",
	},
	{
		schoolCode: 1347,
		schoolFullName: "Bredbo Public School",
	},
	{
		schoolCode: 1350,
		schoolFullName: "Brewarrina Central School",
	},
	{
		schoolCode: 1354,
		schoolFullName: "Bribbaree Public School",
	},
	{
		schoolCode: 1358,
		schoolFullName: "Brighton-Le-Sands Public School",
	},
	{
		schoolCode: 1360,
		schoolFullName: "Bringelly Public School",
	},
	{
		schoolCode: 1361,
		schoolFullName: "Broadwater Public School",
	},
	{
		schoolCode: 1363,
		schoolFullName: "Brocklesby Public School",
	},
	{
		schoolCode: 1367,
		schoolFullName: "Broke Public School",
	},
	{
		schoolCode: 1369,
		schoolFullName: "Broken Hill Public School",
	},
	{
		schoolCode: 1371,
		schoolFullName: "Broken Hill North Public School",
	},
	{
		schoolCode: 1372,
		schoolFullName: "Bronte Public School",
	},
	{
		schoolCode: 1377,
		schoolFullName: "Brooklyn Public School",
	},
	{
		schoolCode: 1379,
		schoolFullName: "Brookvale Public School",
	},
	{
		schoolCode: 1387,
		schoolFullName: "Brungle Public School",
	},
	{
		schoolCode: 1389,
		schoolFullName: "Brunswick Heads Public School",
	},
	{
		schoolCode: 1402,
		schoolFullName: "Bulahdelah Central School",
	},
	{
		schoolCode: 1411,
		schoolFullName: "Bulli Public School",
	},
	{
		schoolCode: 1414,
		schoolFullName: "Bundanoon Public School",
	},
	{
		schoolCode: 1415,
		schoolFullName: "Bundarra Central School",
	},
	{
		schoolCode: 1421,
		schoolFullName: "Bungendore Public School",
	},
	{
		schoolCode: 1424,
		schoolFullName: "Bungwahl Public School",
	},
	{
		schoolCode: 1425,
		schoolFullName: "Bunnaloo Public School",
	},
	{
		schoolCode: 1432,
		schoolFullName: "Burke Ward Public School",
	},
	{
		schoolCode: 1433,
		schoolFullName: "Burnside Public School",
	},
	{
		schoolCode: 1445,
		schoolFullName: "Burrawang Public School",
	},
	{
		schoolCode: 1447,
		schoolFullName: "Burren Junction Public School",
	},
	{
		schoolCode: 1449,
		schoolFullName: "Burringbar Public School",
	},
	{
		schoolCode: 1453,
		schoolFullName: "Burrumbuttock Public School",
	},
	{
		schoolCode: 1455,
		schoolFullName: "Burwood Public School",
	},
	{
		schoolCode: 1460,
		schoolFullName: "Buxton Public School",
	},
	{
		schoolCode: 1470,
		schoolFullName: "Byron Bay Public School",
	},
	{
		schoolCode: 1471,
		schoolFullName: "Cabbage Tree Island Public School",
	},
	{
		schoolCode: 1472,
		schoolFullName: "Cabramatta Public School",
	},
	{
		schoolCode: 1481,
		schoolFullName: "Cambewarra Public School",
	},
	{
		schoolCode: 1482,
		schoolFullName: "Camden Public School",
	},
	{
		schoolCode: 1483,
		schoolFullName: "Camdenville Public School",
	},
	{
		schoolCode: 1485,
		schoolFullName: "Cammeray Public School",
	},
	{
		schoolCode: 1486,
		schoolFullName: "Campbelltown Public School",
	},
	{
		schoolCode: 1488,
		schoolFullName: "Campsie Public School",
	},
	{
		schoolCode: 1490,
		schoolFullName: "Candelo Public School",
	},
	{
		schoolCode: 1492,
		schoolFullName: "Caniaba Public School",
	},
	{
		schoolCode: 1494,
		schoolFullName: "Canley Vale Public School",
	},
	{
		schoolCode: 1495,
		schoolFullName: "Canobolas Public School",
	},
	{
		schoolCode: 1497,
		schoolFullName: "Canterbury Public School",
	},
	{
		schoolCode: 1498,
		schoolFullName: "Brisbania Public School",
	},
	{
		schoolCode: 1499,
		schoolFullName: "Uranquinty Public School",
	},
	{
		schoolCode: 1501,
		schoolFullName: "Capertee Public School",
	},
	{
		schoolCode: 1502,
		schoolFullName: "Captains Flat Public School",
	},
	{
		schoolCode: 1503,
		schoolFullName: "Caragabal Public School",
	},
	{
		schoolCode: 1504,
		schoolFullName: "Carcoar Public School",
	},
	{
		schoolCode: 1505,
		schoolFullName: "Cardiff Public School",
	},
	{
		schoolCode: 1506,
		schoolFullName: "Cargo Public School",
	},
	{
		schoolCode: 1507,
		schoolFullName: "Carinda Public School",
	},
	{
		schoolCode: 1508,
		schoolFullName: "Caringbah Public School",
	},
	{
		schoolCode: 1512,
		schoolFullName: "Carlton Public School",
	},
	{
		schoolCode: 1513,
		schoolFullName: "Carlton South Public School",
	},
	{
		schoolCode: 1515,
		schoolFullName: "Carool Public School",
	},
	{
		schoolCode: 1517,
		schoolFullName: "Carrathool Public School",
	},
	{
		schoolCode: 1518,
		schoolFullName: "Carrington Public School",
	},
	{
		schoolCode: 1520,
		schoolFullName: "Carroll Public School",
	},
	{
		schoolCode: 1526,
		schoolFullName: "Casino Public School",
	},
	{
		schoolCode: 1527,
		schoolFullName: "Cassilis Public School",
	},
	{
		schoolCode: 1529,
		schoolFullName: "Castle Hill Public School",
	},
	{
		schoolCode: 1535,
		schoolFullName: "Cattai Public School",
	},
	{
		schoolCode: 1537,
		schoolFullName: "Cawdor Public School",
	},
	{
		schoolCode: 1542,
		schoolFullName: "Cessnock Public School",
	},
	{
		schoolCode: 1543,
		schoolFullName: "Cessnock East Public School",
	},
	{
		schoolCode: 1545,
		schoolFullName: "Cessnock West Public School",
	},
	{
		schoolCode: 1547,
		schoolFullName: "Chandler Public School",
	},
	{
		schoolCode: 1548,
		schoolFullName: "The Channon Public School",
	},
	{
		schoolCode: 1550,
		schoolFullName: "Charlestown Public School",
	},
	{
		schoolCode: 1551,
		schoolFullName: "Pacific Palms Public School",
	},
	{
		schoolCode: 1553,
		schoolFullName: "Chatsworth Island Public School",
	},
	{
		schoolCode: 1558,
		schoolFullName: "Chillingham Public School",
	},
	{
		schoolCode: 1560,
		schoolFullName: "Chipping Norton Public School",
	},
	{
		schoolCode: 1564,
		schoolFullName: "Clarence Town Public School",
	},
	{
		schoolCode: 1566,
		schoolFullName: "Clemton Park Public School",
	},
	{
		schoolCode: 1573,
		schoolFullName: "Clovelly Public School",
	},
	{
		schoolCode: 1574,
		schoolFullName: "Clunes Public School",
	},
	{
		schoolCode: 1578,
		schoolFullName: "Cobar Public School",
	},
	{
		schoolCode: 1579,
		schoolFullName: "Cobargo Public School",
	},
	{
		schoolCode: 1580,
		schoolFullName: "Cobbitty Public School",
	},
	{
		schoolCode: 1583,
		schoolFullName: "Coffee Camp Public School",
	},
	{
		schoolCode: 1584,
		schoolFullName: "Coffs Harbour Public School",
	},
	{
		schoolCode: 1587,
		schoolFullName: "Coledale Public School",
	},
	{
		schoolCode: 1589,
		schoolFullName: "Collarenebri Central School",
	},
	{
		schoolCode: 1590,
		schoolFullName: "Collector Public School",
	},
	{
		schoolCode: 1593,
		schoolFullName: "Collins Creek Public School",
	},
	{
		schoolCode: 1596,
		schoolFullName: "Colyton Public School",
	},
	{
		schoolCode: 1601,
		schoolFullName: "Comboyne Public School",
	},
	{
		schoolCode: 1604,
		schoolFullName: "Comleroy Road Public School",
	},
	{
		schoolCode: 1605,
		schoolFullName: "Como Public School",
	},
	{
		schoolCode: 1606,
		schoolFullName: "Conargo Public School",
	},
	{
		schoolCode: 1607,
		schoolFullName: "Concord Public School",
	},
	{
		schoolCode: 1608,
		schoolFullName: "Concord West Public School",
	},
	{
		schoolCode: 1609,
		schoolFullName: "Condobolin Public School",
	},
	{
		schoolCode: 1611,
		schoolFullName: "Condong Public School",
	},
	{
		schoolCode: 1612,
		schoolFullName: "Coniston Public School",
	},
	{
		schoolCode: 1613,
		schoolFullName: "Connells Point Public School",
	},
	{
		schoolCode: 1615,
		schoolFullName: "Cooerwull Public School",
	},
	{
		schoolCode: 1616,
		schoolFullName: "Coogee Public School",
	},
	{
		schoolCode: 1621,
		schoolFullName: "Coolah Central School",
	},
	{
		schoolCode: 1624,
		schoolFullName: "Coolamon Central School",
	},
	{
		schoolCode: 1625,
		schoolFullName: "Shoalhaven Heads Public School",
	},
	{
		schoolCode: 1627,
		schoolFullName: "Coolongolook Public School",
	},
	{
		schoolCode: 1628,
		schoolFullName: "Cooma Public School",
	},
	{
		schoolCode: 1630,
		schoolFullName: "Coonabarabran Public School",
	},
	{
		schoolCode: 1631,
		schoolFullName: "Coonamble Public School",
	},
	{
		schoolCode: 1632,
		schoolFullName: "Coopernook Public School",
	},
	{
		schoolCode: 1634,
		schoolFullName: "Coorabell Public School",
	},
	{
		schoolCode: 1635,
		schoolFullName: "Cooranbong Public School",
	},
	{
		schoolCode: 1636,
		schoolFullName: "Cootamundra Public School",
	},
	{
		schoolCode: 1642,
		schoolFullName: "Copmanhurst Public School",
	},
	{
		schoolCode: 1644,
		schoolFullName: "Coraki Public School",
	},
	{
		schoolCode: 1647,
		schoolFullName: "Coramba Public School",
	},
	{
		schoolCode: 1654,
		schoolFullName: "Corindi Public School",
	},
	{
		schoolCode: 1656,
		schoolFullName: "Corndale Public School",
	},
	{
		schoolCode: 1658,
		schoolFullName: "Corowa Public School",
	},
	{
		schoolCode: 1659,
		schoolFullName: "Corowa South Public School",
	},
	{
		schoolCode: 1661,
		schoolFullName: "Corrimal Public School",
	},
	{
		schoolCode: 1666,
		schoolFullName: "Coutts Crossing Public School",
	},
	{
		schoolCode: 1668,
		schoolFullName: "Cowan Public School",
	},
	{
		schoolCode: 1670,
		schoolFullName: "Cowper Public School",
	},
	{
		schoolCode: 1671,
		schoolFullName: "Cowra Public School",
	},
	{
		schoolCode: 1674,
		schoolFullName: "Crabbes Creek Public School",
	},
	{
		schoolCode: 1681,
		schoolFullName: "Crescent Head Public School",
	},
	{
		schoolCode: 1682,
		schoolFullName: "Cronulla Public School",
	},
	{
		schoolCode: 1684,
		schoolFullName: "Crookwell Public School",
	},
	{
		schoolCode: 1685,
		schoolFullName: "Crossmaglen Public School",
	},
	{
		schoolCode: 1688,
		schoolFullName: "Buronga Public School",
	},
	{
		schoolCode: 1690,
		schoolFullName: "Crystal Creek Public School",
	},
	{
		schoolCode: 1695,
		schoolFullName: "Cudal Public School",
	},
	{
		schoolCode: 1696,
		schoolFullName: "Cudgen Public School",
	},
	{
		schoolCode: 1698,
		schoolFullName: "Culcairn Public School",
	},
	{
		schoolCode: 1700,
		schoolFullName: "Cullen Bullen Public School",
	},
	{
		schoolCode: 1707,
		schoolFullName: "Cumnock Public School",
	},
	{
		schoolCode: 1708,
		schoolFullName: "Cundletown Public School",
	},
	{
		schoolCode: 1712,
		schoolFullName: "Curlewis Public School",
	},
	{
		schoolCode: 1714,
		schoolFullName: "Currabubula Public School",
	},
	{
		schoolCode: 1722,
		schoolFullName: "Dalgety Public School",
	},
	{
		schoolCode: 1723,
		schoolFullName: "Dalton Public School",
	},
	{
		schoolCode: 1727,
		schoolFullName: "Dapto Public School",
	},
	{
		schoolCode: 1732,
		schoolFullName: "Dareton Public School",
	},
	{
		schoolCode: 1735,
		schoolFullName: "Darlington Public School",
	},
	{
		schoolCode: 1736,
		schoolFullName: "Darlington Point Public School",
	},
	{
		schoolCode: 1740,
		schoolFullName: "Leeville Public School",
	},
	{
		schoolCode: 1742,
		schoolFullName: "Deepwater Public School",
	},
	{
		schoolCode: 1744,
		schoolFullName: "Dee Why Public School",
	},
	{
		schoolCode: 1745,
		schoolFullName: "Delegate Public School",
	},
	{
		schoolCode: 1747,
		schoolFullName: "Delungra Public School",
	},
	{
		schoolCode: 1749,
		schoolFullName: "Denman Public School",
	},
	{
		schoolCode: 1760,
		schoolFullName: "Doonside Public School",
	},
	{
		schoolCode: 1762,
		schoolFullName: "Dora Creek Public School",
	},
	{
		schoolCode: 1768,
		schoolFullName: "Double Bay Public School",
	},
	{
		schoolCode: 1769,
		schoolFullName: "Doubtful Creek Public School",
	},
	{
		schoolCode: 1770,
		schoolFullName: "Douglas Park Public School",
	},
	{
		schoolCode: 1771,
		schoolFullName: "Drake Public School",
	},
	{
		schoolCode: 1774,
		schoolFullName: "Drummoyne Public School",
	},
	{
		schoolCode: 1775,
		schoolFullName: "Dubbo Public School",
	},
	{
		schoolCode: 1776,
		schoolFullName: "Dubbo North Public School",
	},
	{
		schoolCode: 1778,
		schoolFullName: "Dudley Public School",
	},
	{
		schoolCode: 1780,
		schoolFullName: "Lismore Public School",
	},
	{
		schoolCode: 1788,
		schoolFullName: "Dundurrabin Public School",
	},
	{
		schoolCode: 1789,
		schoolFullName: "Dunedoo Central School",
	},
	{
		schoolCode: 1791,
		schoolFullName: "Dungay Public School",
	},
	{
		schoolCode: 1792,
		schoolFullName: "Dungog Public School",
	},
	{
		schoolCode: 1793,
		schoolFullName: "Dungowan Public School",
	},
	{
		schoolCode: 1797,
		schoolFullName: "Dunoon Public School",
	},
	{
		schoolCode: 1799,
		schoolFullName: "Dural Public School",
	},
	{
		schoolCode: 1801,
		schoolFullName: "Duranbah Public School",
	},
	{
		schoolCode: 1802,
		schoolFullName: "Duri Public School",
	},
	{
		schoolCode: 1803,
		schoolFullName: "Durrumbul Public School",
	},
	{
		schoolCode: 1807,
		schoolFullName: "Earlwood Public School",
	},
	{
		schoolCode: 1808,
		schoolFullName: "Eastern Creek Public School",
	},
	{
		schoolCode: 1809,
		schoolFullName: "Panania Public School",
	},
	{
		schoolCode: 1811,
		schoolFullName: "Dubbo South Public School",
	},
	{
		schoolCode: 1813,
		schoolFullName: "Ebenezer Public School",
	},
	{
		schoolCode: 1814,
		schoolFullName: "Ebor Public School",
	},
	{
		schoolCode: 1816,
		schoolFullName: "Eden Public School",
	},
	{
		schoolCode: 1822,
		schoolFullName: "Congewai Public School",
	},
	{
		schoolCode: 1823,
		schoolFullName: "Eglinton Public School",
	},
	{
		schoolCode: 1824,
		schoolFullName: "Elands Public School",
	},
	{
		schoolCode: 1826,
		schoolFullName: "Ellalong Public School",
	},
	{
		schoolCode: 1830,
		schoolFullName: "Ellerston Public School",
	},
	{
		schoolCode: 1835,
		schoolFullName: "Eltham Public School",
	},
	{
		schoolCode: 1838,
		schoolFullName: "Emmaville Central School",
	},
	{
		schoolCode: 1839,
		schoolFullName: "Empire Bay Public School",
	},
	{
		schoolCode: 1840,
		schoolFullName: "Empire Vale Public School",
	},
	{
		schoolCode: 1841,
		schoolFullName: "Emu Plains Public School",
	},
	{
		schoolCode: 1844,
		schoolFullName: "Enfield Public School",
	},
	{
		schoolCode: 1845,
		schoolFullName: "Engadine Public School",
	},
	{
		schoolCode: 1849,
		schoolFullName: "The Entrance Public School",
	},
	{
		schoolCode: 1850,
		schoolFullName: "Epping Public School",
	},
	{
		schoolCode: 1851,
		schoolFullName: "Epping West Public School",
	},
	{
		schoolCode: 1856,
		schoolFullName: "Erina Heights Public School",
	},
	{
		schoolCode: 1857,
		schoolFullName: "Woodport Public School",
	},
	{
		schoolCode: 1860,
		schoolFullName: "Ermington Public School",
	},
	{
		schoolCode: 1864,
		schoolFullName: "Ettalong Public School",
	},
	{
		schoolCode: 1867,
		schoolFullName: "Euabalong West Public School",
	},
	{
		schoolCode: 1869,
		schoolFullName: "Euchareena Public School",
	},
	{
		schoolCode: 1870,
		schoolFullName: "Eugowra Public School",
	},
	{
		schoolCode: 1873,
		schoolFullName: "Eumungerie Public School",
	},
	{
		schoolCode: 1874,
		schoolFullName: "Eungai Public School",
	},
	{
		schoolCode: 1879,
		schoolFullName: "Eureka Public School",
	},
	{
		schoolCode: 1883,
		schoolFullName: "Eurongilly Public School",
	},
	{
		schoolCode: 1885,
		schoolFullName: "Euston Public School",
	},
	{
		schoolCode: 1886,
		schoolFullName: "Evans River Community School",
	},
	{
		schoolCode: 1890,
		schoolFullName: "Excelsior Public School",
	},
	{
		schoolCode: 1891,
		schoolFullName: "Exeter Public School",
	},
	{
		schoolCode: 1894,
		schoolFullName: "Fairfax Public School",
	},
	{
		schoolCode: 1895,
		schoolFullName: "Fairfield Public School",
	},
	{
		schoolCode: 1896,
		schoolFullName: "Fairfield West Public School",
	},
	{
		schoolCode: 1898,
		schoolFullName: "Fairy Meadow Public School",
	},
	{
		schoolCode: 1901,
		schoolFullName: "Falls Creek Public School",
	},
	{
		schoolCode: 1904,
		schoolFullName: "Fassifern Public School",
	},
	{
		schoolCode: 1915,
		schoolFullName: "Fingal Head Public School",
	},
	{
		schoolCode: 1920,
		schoolFullName: "Five Dock Public School",
	},
	{
		schoolCode: 1921,
		schoolFullName: "Lord Howe Island Central School",
	},
	{
		schoolCode: 1926,
		schoolFullName: "Forbes Public School",
	},
	{
		schoolCode: 1930,
		schoolFullName: "Forest Hill Public School",
	},
	{
		schoolCode: 1932,
		schoolFullName: "Forest Lodge Public School",
	},
	{
		schoolCode: 1936,
		schoolFullName: "Forster Public School",
	},
	{
		schoolCode: 1937,
		schoolFullName: "Fort Street Public School",
	},
	{
		schoolCode: 1943,
		schoolFullName: "Frederickton Public School",
	},
	{
		schoolCode: 1944,
		schoolFullName: "Freemans Reach Public School",
	},
	{
		schoolCode: 1946,
		schoolFullName: "Frenchs Forest Public School",
	},
	{
		schoolCode: 1952,
		schoolFullName: "Galston Public School",
	},
	{
		schoolCode: 1954,
		schoolFullName: "Ganmain Public School",
	},
	{
		schoolCode: 1956,
		schoolFullName: "Garah Public School",
	},
	{
		schoolCode: 1969,
		schoolFullName: "Gerogery Public School",
	},
	{
		schoolCode: 1971,
		schoolFullName: "Gerringong Public School",
	},
	{
		schoolCode: 1972,
		schoolFullName: "Geurie Public School",
	},
	{
		schoolCode: 1974,
		schoolFullName: "Sandy Hollow Public School",
	},
	{
		schoolCode: 1978,
		schoolFullName: "Gilgai Public School",
	},
	{
		schoolCode: 1979,
		schoolFullName: "Gilgandra Public School",
	},
	{
		schoolCode: 1982,
		schoolFullName: "Gillieston Public School",
	},
	{
		schoolCode: 1986,
		schoolFullName: "Girilambone Public School",
	},
	{
		schoolCode: 1988,
		schoolFullName: "Girraween Public School",
	},
	{
		schoolCode: 1990,
		schoolFullName: "Gladesville Public School",
	},
	{
		schoolCode: 1991,
		schoolFullName: "Gladstone Public School",
	},
	{
		schoolCode: 1992,
		schoolFullName: "Windang Public School",
	},
	{
		schoolCode: 1994,
		schoolFullName: "Glenbrook Public School",
	},
	{
		schoolCode: 2001,
		schoolFullName: "Glenfield Public School",
	},
	{
		schoolCode: 2003,
		schoolFullName: "Glenhaven Public School",
	},
	{
		schoolCode: 2005,
		schoolFullName: "Glen Innes Public School",
	},
	{
		schoolCode: 2009,
		schoolFullName: "Glenmore Road Public School",
	},
	{
		schoolCode: 2012,
		schoolFullName: "Glenorie Public School",
	},
	{
		schoolCode: 2015,
		schoolFullName: "Glenquarry Public School",
	},
	{
		schoolCode: 2016,
		schoolFullName: "Glenreagh Public School",
	},
	{
		schoolCode: 2020,
		schoolFullName: "Glen William Public School",
	},
	{
		schoolCode: 2021,
		schoolFullName: "Glossodia Public School",
	},
	{
		schoolCode: 2027,
		schoolFullName: "Gol Gol Public School",
	},
	{
		schoolCode: 2033,
		schoolFullName: "Goodooga Central School",
	},
	{
		schoolCode: 2035,
		schoolFullName: "Goolgowi Public School",
	},
	{
		schoolCode: 2036,
		schoolFullName: "Goolma Public School",
	},
	{
		schoolCode: 2037,
		schoolFullName: "Goolmangar Public School",
	},
	{
		schoolCode: 2038,
		schoolFullName: "Gooloogong Public School",
	},
	{
		schoolCode: 2041,
		schoolFullName: "Goonellabah Public School",
	},
	{
		schoolCode: 2042,
		schoolFullName: "Goonengerry Public School",
	},
	{
		schoolCode: 2049,
		schoolFullName: "Gosford Public School",
	},
	{
		schoolCode: 2053,
		schoolFullName: "Goulburn Public School",
	},
	{
		schoolCode: 2054,
		schoolFullName: "Goulburn East Public School",
	},
	{
		schoolCode: 2055,
		schoolFullName: "Goulburn North Public School",
	},
	{
		schoolCode: 2056,
		schoolFullName: "Goulburn South Public School",
	},
	{
		schoolCode: 2060,
		schoolFullName: "Grafton Public School",
	},
	{
		schoolCode: 2061,
		schoolFullName: "South Grafton Public School",
	},
	{
		schoolCode: 2064,
		schoolFullName: "Granville Public School",
	},
	{
		schoolCode: 2066,
		schoolFullName: "Granville South Public School",
	},
	{
		schoolCode: 2068,
		schoolFullName: "Gravesend Public School",
	},
	{
		schoolCode: 2070,
		schoolFullName: "Greenethorpe Public School",
	},
	{
		schoolCode: 2071,
		schoolFullName: "Green Hill Public School",
	},
	{
		schoolCode: 2075,
		schoolFullName: "Green Valley Public School",
	},
	{
		schoolCode: 2076,
		schoolFullName: "Greenwich Public School",
	},
	{
		schoolCode: 2082,
		schoolFullName: "Grenfell Public School",
	},
	{
		schoolCode: 2083,
		schoolFullName: "Gresford Public School",
	},
	{
		schoolCode: 2084,
		schoolFullName: "Greta Public School",
	},
	{
		schoolCode: 2086,
		schoolFullName: "Greystanes Public School",
	},
	{
		schoolCode: 2087,
		schoolFullName: "Griffith Public School",
	},
	{
		schoolCode: 2093,
		schoolFullName: "Guildford Public School",
	},
	{
		schoolCode: 2094,
		schoolFullName: "Gulargambone Central School",
	},
	{
		schoolCode: 2097,
		schoolFullName: "Gulgong Public School",
	},
	{
		schoolCode: 2099,
		schoolFullName: "Gulmarrad Public School",
	},
	{
		schoolCode: 2100,
		schoolFullName: "Gum Flat Public School",
	},
	{
		schoolCode: 2102,
		schoolFullName: "Gundagai Public School",
	},
	{
		schoolCode: 2103,
		schoolFullName: "Gundagai South Public School",
	},
	{
		schoolCode: 2104,
		schoolFullName: "Gundaroo Public School",
	},
	{
		schoolCode: 2109,
		schoolFullName: "Gunnedah Public School",
	},
	{
		schoolCode: 2111,
		schoolFullName: "Gunning Public School",
	},
	{
		schoolCode: 2114,
		schoolFullName: "Guyra Central School",
	},
	{
		schoolCode: 2115,
		schoolFullName: "Gwabegar Public School",
	},
	{
		schoolCode: 2116,
		schoolFullName: "Gymea Bay Public School",
	},
	{
		schoolCode: 2117,
		schoolFullName: "Haberfield Public School",
	},
	{
		schoolCode: 2128,
		schoolFullName: "Hammondville Public School",
	},
	{
		schoolCode: 2129,
		schoolFullName: "Hampton Public School",
	},
	{
		schoolCode: 2131,
		schoolFullName: "Hannam Vale Public School",
	},
	{
		schoolCode: 2132,
		schoolFullName: "Hanwood Public School",
	},
	{
		schoolCode: 2133,
		schoolFullName: "Harbord Public School",
	},
	{
		schoolCode: 2136,
		schoolFullName: "Hargraves Public School",
	},
	{
		schoolCode: 2139,
		schoolFullName: "Harrington Public School",
	},
	{
		schoolCode: 2142,
		schoolFullName: "Harwood Island Public School",
	},
	{
		schoolCode: 2145,
		schoolFullName: "Hay Public School",
	},
	{
		schoolCode: 2149,
		schoolFullName: "Heathcote Public School",
	},
	{
		schoolCode: 2153,
		schoolFullName: "Helensburgh Public School",
	},
	{
		schoolCode: 2154,
		schoolFullName: "Henty Public School",
	},
	{
		schoolCode: 2155,
		schoolFullName: "Hermidale Public School",
	},
	{
		schoolCode: 2156,
		schoolFullName: "Hernani Public School",
	},
	{
		schoolCode: 2157,
		schoolFullName: "Herons Creek Public School",
	},
	{
		schoolCode: 2161,
		schoolFullName: "Hill End Public School",
	},
	{
		schoolCode: 2164,
		schoolFullName: "Hillside Public School",
	},
	{
		schoolCode: 2165,
		schoolFullName: "Hillston Central School",
	},
	{
		schoolCode: 2166,
		schoolFullName: "Hill Top Public School",
	},
	{
		schoolCode: 2167,
		schoolFullName: "Hinton Public School",
	},
	{
		schoolCode: 2170,
		schoolFullName: "Holbrook Public School",
	},
	{
		schoolCode: 2171,
		schoolFullName: "Holgate Public School",
	},
	{
		schoolCode: 2172,
		schoolFullName: "Holmwood Public School",
	},
	{
		schoolCode: 2173,
		schoolFullName: "Homebush Public School",
	},
	{
		schoolCode: 2177,
		schoolFullName: "Telarah Public School",
	},
	{
		schoolCode: 2182,
		schoolFullName: "Horsley Park Public School",
	},
	{
		schoolCode: 2186,
		schoolFullName: "Howlong Public School",
	},
	{
		schoolCode: 2187,
		schoolFullName: "Hoxton Park Public School",
	},
	{
		schoolCode: 2188,
		schoolFullName: "Humula Public School",
	},
	{
		schoolCode: 2189,
		schoolFullName: "Hunters Hill Public School",
	},
	{
		schoolCode: 2190,
		schoolFullName: "Huntingdon Public School",
	},
	{
		schoolCode: 2193,
		schoolFullName: "Rathmines Public School",
	},
	{
		schoolCode: 2197,
		schoolFullName: "Hurstville Public School",
	},
	{
		schoolCode: 2198,
		schoolFullName: "Hurstville South Public School",
	},
	{
		schoolCode: 2199,
		schoolFullName: "Huskisson Public School",
	},
	{
		schoolCode: 2201,
		schoolFullName: "Ilford Public School",
	},
	{
		schoolCode: 2203,
		schoolFullName: "Illabo Public School",
	},
	{
		schoolCode: 2204,
		schoolFullName: "Iluka Public School",
	},
	{
		schoolCode: 2206,
		schoolFullName: "Ingleburn Public School",
	},
	{
		schoolCode: 2209,
		schoolFullName: "Inverell Public School",
	},
	{
		schoolCode: 2211,
		schoolFullName: "Iona Public School",
	},
	{
		schoolCode: 2212,
		schoolFullName: "Islington Public School",
	},
	{
		schoolCode: 2213,
		schoolFullName: "Ivanhoe Central School",
	},
	{
		schoolCode: 2214,
		schoolFullName: "Fernleigh Public School",
	},
	{
		schoolCode: 2218,
		schoolFullName: "Jamberoo Public School",
	},
	{
		schoolCode: 2223,
		schoolFullName: "Jennings Public School",
	},
	{
		schoolCode: 2225,
		schoolFullName: "Jerangle Public School",
	},
	{
		schoolCode: 2226,
		schoolFullName: "Jerilderie Public School",
	},
	{
		schoolCode: 2231,
		schoolFullName: "Jerrys Plains Public School",
	},
	{
		schoolCode: 2234,
		schoolFullName: "Jesmond Public School",
	},
	{
		schoolCode: 2235,
		schoolFullName: "Jiggi Public School",
	},
	{
		schoolCode: 2236,
		schoolFullName: "Jilliby Public School",
	},
	{
		schoolCode: 2237,
		schoolFullName: "Jindabyne Central School",
	},
	{
		schoolCode: 2239,
		schoolFullName: "Jindera Public School",
	},
	{
		schoolCode: 2243,
		schoolFullName: "Johns River Public School",
	},
	{
		schoolCode: 2245,
		schoolFullName: "Jugiong Public School",
	},
	{
		schoolCode: 2246,
		schoolFullName: "The Junction Public School",
	},
	{
		schoolCode: 2247,
		schoolFullName: "Junee Public School",
	},
	{
		schoolCode: 2248,
		schoolFullName: "Junee North Public School",
	},
	{
		schoolCode: 2254,
		schoolFullName: "Kandos Public School",
	},
	{
		schoolCode: 2255,
		schoolFullName: "Kangaloon Public School",
	},
	{
		schoolCode: 2256,
		schoolFullName: "Kangaroo Valley Public School",
	},
	{
		schoolCode: 2259,
		schoolFullName: "Kanwal Public School",
	},
	{
		schoolCode: 2260,
		schoolFullName: "Karangi Public School",
	},
	{
		schoolCode: 2263,
		schoolFullName: "Karuah Public School",
	},
	{
		schoolCode: 2267,
		schoolFullName: "Kearsley Public School",
	},
	{
		schoolCode: 2270,
		schoolFullName: "Kegworth Public School",
	},
	{
		schoolCode: 2271,
		schoolFullName: "Keiraville Public School",
	},
	{
		schoolCode: 2273,
		schoolFullName: "Kellys Plains Public School",
	},
	{
		schoolCode: 2274,
		schoolFullName: "Kellyville Public School",
	},
	{
		schoolCode: 2275,
		schoolFullName: "Kelso Public School",
	},
	{
		schoolCode: 2278,
		schoolFullName: "Kempsey East Public School",
	},
	{
		schoolCode: 2279,
		schoolFullName: "Kempsey West Public School",
	},
	{
		schoolCode: 2280,
		schoolFullName: "Kendall Public School",
	},
	{
		schoolCode: 2283,
		schoolFullName: "Kensington Public School",
	},
	{
		schoolCode: 2284,
		schoolFullName: "Kenthurst Public School",
	},
	{
		schoolCode: 2285,
		schoolFullName: "Kentlyn Public School",
	},
	{
		schoolCode: 2286,
		schoolFullName: "Kentucky Public School",
	},
	{
		schoolCode: 2293,
		schoolFullName: "Kiama Public School",
	},
	{
		schoolCode: 2303,
		schoolFullName: "Kinchela Public School",
	},
	{
		schoolCode: 2304,
		schoolFullName: "Kincumber Public School",
	},
	{
		schoolCode: 2307,
		schoolFullName: "Kingsgrove Public School",
	},
	{
		schoolCode: 2308,
		schoolFullName: "Bexley North Public School",
	},
	{
		schoolCode: 2310,
		schoolFullName: "Kingstown Public School",
	},
	{
		schoolCode: 2312,
		schoolFullName: "Kingswood Public School",
	},
	{
		schoolCode: 2314,
		schoolFullName: "Kirkton Public School",
	},
	{
		schoolCode: 2315,
		schoolFullName: "Kitchener Public School",
	},
	{
		schoolCode: 2319,
		schoolFullName: "Kogarah Public School",
	},
	{
		schoolCode: 2326,
		schoolFullName: "Koorawatha Public School",
	},
	{
		schoolCode: 2327,
		schoolFullName: "Kootingal Public School",
	},
	{
		schoolCode: 2330,
		schoolFullName: "Kororo Public School",
	},
	{
		schoolCode: 2333,
		schoolFullName: "Krambach Public School",
	},
	{
		schoolCode: 2334,
		schoolFullName: "Kulnura Public School",
	},
	{
		schoolCode: 2340,
		schoolFullName: "Kurmond Public School",
	},
	{
		schoolCode: 2341,
		schoolFullName: "Kurnell Public School",
	},
	{
		schoolCode: 2342,
		schoolFullName: "Kurrajong Public School",
	},
	{
		schoolCode: 2343,
		schoolFullName: "Kurrajong East Public School",
	},
	{
		schoolCode: 2344,
		schoolFullName: "Kurrajong North Public School",
	},
	{
		schoolCode: 2349,
		schoolFullName: "Kyogle Public School",
	},
	{
		schoolCode: 2353,
		schoolFullName: "Ladysmith Public School",
	},
	{
		schoolCode: 2354,
		schoolFullName: "Laggan Public School",
	},
	{
		schoolCode: 2356,
		schoolFullName: "Laguna Public School",
	},
	{
		schoolCode: 2357,
		schoolFullName: "Lake Albert Public School",
	},
	{
		schoolCode: 2359,
		schoolFullName: "Lake Cargelligo Central School",
	},
	{
		schoolCode: 2360,
		schoolFullName: "Lakemba Public School",
	},
	{
		schoolCode: 2362,
		schoolFullName: "Lake Munmorah Public School",
	},
	{
		schoolCode: 2363,
		schoolFullName: "Lake Wyangan Public School",
	},
	{
		schoolCode: 2366,
		schoolFullName: "Lambton Public School",
	},
	{
		schoolCode: 2368,
		schoolFullName: "Lane Cove Public School",
	},
	{
		schoolCode: 2370,
		schoolFullName: "Lansdowne Public School",
	},
	{
		schoolCode: 2372,
		schoolFullName: "Upper Lansdowne Public School",
	},
	{
		schoolCode: 2373,
		schoolFullName: "La Perouse Public School",
	},
	{
		schoolCode: 2374,
		schoolFullName: "Largs Public School",
	},
	{
		schoolCode: 2375,
		schoolFullName: "Larnook Public School",
	},
	{
		schoolCode: 2377,
		schoolFullName: "Laurieton Public School",
	},
	{
		schoolCode: 2378,
		schoolFullName: "Lavington Public School",
	},
	{
		schoolCode: 2379,
		schoolFullName: "Lawrence Public School",
	},
	{
		schoolCode: 2381,
		schoolFullName: "Lawson Public School",
	},
	{
		schoolCode: 2386,
		schoolFullName: "Leeton Public School",
	},
	{
		schoolCode: 2391,
		schoolFullName: "Leura Public School",
	},
	{
		schoolCode: 2394,
		schoolFullName: "Lidcombe Public School",
	},
	{
		schoolCode: 2395,
		schoolFullName: "Lightning Ridge Central School",
	},
	{
		schoolCode: 2403,
		schoolFullName: "Lindfield Public School",
	},
	{
		schoolCode: 2404,
		schoolFullName: "Lindfield East Public School",
	},
	{
		schoolCode: 2407,
		schoolFullName: "Lisarow Public School",
	},
	{
		schoolCode: 2409,
		schoolFullName: "Lismore South Public School",
	},
	{
		schoolCode: 2410,
		schoolFullName: "Lithgow Public School",
	},
	{
		schoolCode: 2413,
		schoolFullName: "Llandilo Public School",
	},
	{
		schoolCode: 2417,
		schoolFullName: "Lochinvar Public School",
	},
	{
		schoolCode: 2418,
		schoolFullName: "Lockhart Central School",
	},
	{
		schoolCode: 2421,
		schoolFullName: "Londonderry Public School",
	},
	{
		schoolCode: 2423,
		schoolFullName: "Long Flat Public School",
	},
	{
		schoolCode: 2428,
		schoolFullName: "Lowanna Public School",
	},
	{
		schoolCode: 2429,
		schoolFullName: "Lowesdale Public School",
	},
	{
		schoolCode: 2431,
		schoolFullName: "Luddenham Public School",
	},
	{
		schoolCode: 2432,
		schoolFullName: "Lue Public School",
	},
	{
		schoolCode: 2436,
		schoolFullName: "Lyndhurst Public School",
	},
	{
		schoolCode: 2439,
		schoolFullName: "Macdonald Valley Public School",
	},
	{
		schoolCode: 2444,
		schoolFullName: "Macksville Public School",
	},
	{
		schoolCode: 2448,
		schoolFullName: "Maimuru Public School",
	},
	{
		schoolCode: 2451,
		schoolFullName: "Maitland East Public School",
	},
	{
		schoolCode: 2454,
		schoolFullName: "Malabar Public School",
	},
	{
		schoolCode: 2460,
		schoolFullName: "Mandurama Public School",
	},
	{
		schoolCode: 2464,
		schoolFullName: "Manifold Public School",
	},
	{
		schoolCode: 2465,
		schoolFullName: "Manildra Public School",
	},
	{
		schoolCode: 2469,
		schoolFullName: "Manly West Public School",
	},
	{
		schoolCode: 2472,
		schoolFullName: "Maraylya Public School",
	},
	{
		schoolCode: 2476,
		schoolFullName: "Pelican Flat Public School",
	},
	{
		schoolCode: 2480,
		schoolFullName: "Maroota Public School",
	},
	{
		schoolCode: 2482,
		schoolFullName: "Maroubra Bay Public School",
	},
	{
		schoolCode: 2485,
		schoolFullName: "Marrar Public School",
	},
	{
		schoolCode: 2489,
		schoolFullName: "Marsden Park Public School",
	},
	{
		schoolCode: 2492,
		schoolFullName: "Martindale Public School",
	},
	{
		schoolCode: 2493,
		schoolFullName: "Martins Creek Public School",
	},
	{
		schoolCode: 2494,
		schoolFullName: "Martins Gully Public School",
	},
	{
		schoolCode: 2496,
		schoolFullName: "Marulan Public School",
	},
	{
		schoolCode: 2499,
		schoolFullName: "Mascot Public School",
	},
	{
		schoolCode: 2500,
		schoolFullName: "Mathoura Public School",
	},
	{
		schoolCode: 2501,
		schoolFullName: "Matong Public School",
	},
	{
		schoolCode: 2502,
		schoolFullName: "Matraville Public School",
	},
	{
		schoolCode: 2506,
		schoolFullName: "Mayfield East Public School",
	},
	{
		schoolCode: 2507,
		schoolFullName: "Mayfield West Public School",
	},
	{
		schoolCode: 2508,
		schoolFullName: "Mayrung Public School",
	},
	{
		schoolCode: 2509,
		schoolFullName: "Meadow Flat Public School",
	},
	{
		schoolCode: 2510,
		schoolFullName: "The Meadows Public School",
	},
	{
		schoolCode: 2511,
		schoolFullName: "Medlow Public School",
	},
	{
		schoolCode: 2512,
		schoolFullName: "Medowie Public School",
	},
	{
		schoolCode: 2515,
		schoolFullName: "Megalong Public School",
	},
	{
		schoolCode: 2520,
		schoolFullName: "Menai Public School",
	},
	{
		schoolCode: 2522,
		schoolFullName: "Mendooran Central School",
	},
	{
		schoolCode: 2523,
		schoolFullName: "Menindee Central School",
	},
	{
		schoolCode: 2525,
		schoolFullName: "Merewether Public School",
	},
	{
		schoolCode: 2526,
		schoolFullName: "Merimbula Public School",
	},
	{
		schoolCode: 2528,
		schoolFullName: "Merriwa Central School",
	},
	{
		schoolCode: 2531,
		schoolFullName: "Merrylands Public School",
	},
	{
		schoolCode: 2532,
		schoolFullName: "Bonnells Bay Public School",
	},
	{
		schoolCode: 2540,
		schoolFullName: "Michelago Public School",
	},
	{
		schoolCode: 2542,
		schoolFullName: "Middle Dural Public School",
	},
	{
		schoolCode: 2543,
		schoolFullName: "Middle Harbour Public School",
	},
	{
		schoolCode: 2550,
		schoolFullName: "Milbrodale Public School",
	},
	{
		schoolCode: 2554,
		schoolFullName: "Millers Forest Public School",
	},
	{
		schoolCode: 2555,
		schoolFullName: "Millfield Public School",
	},
	{
		schoolCode: 2558,
		schoolFullName: "Millthorpe Public School",
	},
	{
		schoolCode: 2560,
		schoolFullName: "Milperra Public School",
	},
	{
		schoolCode: 2565,
		schoolFullName: "Milton Public School",
	},
	{
		schoolCode: 2571,
		schoolFullName: "Minmi Public School",
	},
	{
		schoolCode: 2572,
		schoolFullName: "Minto Public School",
	},
	{
		schoolCode: 2574,
		schoolFullName: "Miranda Public School",
	},
	{
		schoolCode: 2580,
		schoolFullName: "Mitchells Island Public School",
	},
	{
		schoolCode: 2581,
		schoolFullName: "Mittagong Public School",
	},
	{
		schoolCode: 2584,
		schoolFullName: "Moama Public School",
	},
	{
		schoolCode: 2585,
		schoolFullName: "Modanville Public School",
	},
	{
		schoolCode: 2586,
		schoolFullName: "Mogo Public School",
	},
	{
		schoolCode: 2590,
		schoolFullName: "Molong Central School",
	},
	{
		schoolCode: 2593,
		schoolFullName: "Mona Vale Public School",
	},
	{
		schoolCode: 2599,
		schoolFullName: "Monteagle Public School",
	},
	{
		schoolCode: 2608,
		schoolFullName: "Moonbi Public School",
	},
	{
		schoolCode: 2616,
		schoolFullName: "Moorland Public School",
	},
	{
		schoolCode: 2618,
		schoolFullName: "Moree Public School",
	},
	{
		schoolCode: 2621,
		schoolFullName: "Morisset Public School",
	},
	{
		schoolCode: 2623,
		schoolFullName: "Morpeth Public School",
	},
	{
		schoolCode: 2624,
		schoolFullName: "Mortdale Public School",
	},
	{
		schoolCode: 2625,
		schoolFullName: "Mortlake Public School",
	},
	{
		schoolCode: 2628,
		schoolFullName: "Moruya Public School",
	},
	{
		schoolCode: 2631,
		schoolFullName: "Moss Vale Public School",
	},
	{
		schoolCode: 2632,
		schoolFullName: "Moulamein Public School",
	},
	{
		schoolCode: 2637,
		schoolFullName: "Mount George Public School",
	},
	{
		schoolCode: 2640,
		schoolFullName: "Mount Hunter Public School",
	},
	{
		schoolCode: 2641,
		schoolFullName: "Mount Kanwary Public School",
	},
	{
		schoolCode: 2642,
		schoolFullName: "Mount Keira Public School",
	},
	{
		schoolCode: 2643,
		schoolFullName: "Mount Kembla Public School",
	},
	{
		schoolCode: 2647,
		schoolFullName: "Mount Pleasant Public School",
	},
	{
		schoolCode: 2648,
		schoolFullName: "Mount Pritchard Public School",
	},
	{
		schoolCode: 2652,
		schoolFullName: "Mount Victoria Public School",
	},
	{
		schoolCode: 2656,
		schoolFullName: "Mudgee Public School",
	},
	{
		schoolCode: 2658,
		schoolFullName: "Mulbring Public School",
	},
	{
		schoolCode: 2659,
		schoolFullName: "Mulgoa Public School",
	},
	{
		schoolCode: 2660,
		schoolFullName: "Mullaley Public School",
	},
	{
		schoolCode: 2664,
		schoolFullName: "Mullion Creek Public School",
	},
	{
		schoolCode: 2665,
		schoolFullName: "Mullumbimby Public School",
	},
	{
		schoolCode: 2667,
		schoolFullName: "Mulwala Public School",
	},
	{
		schoolCode: 2668,
		schoolFullName: "Clergate Public School",
	},
	{
		schoolCode: 2669,
		schoolFullName: "Mumbil Public School",
	},
	{
		schoolCode: 2671,
		schoolFullName: "Mummulgum Public School",
	},
	{
		schoolCode: 2675,
		schoolFullName: "Mungindi Central School",
	},
	{
		schoolCode: 2681,
		schoolFullName: "Murringo Public School",
	},
	{
		schoolCode: 2685,
		schoolFullName: "Murrumburrah Public School",
	},
	{
		schoolCode: 2686,
		schoolFullName: "Murrurundi Public School",
	},
	{
		schoolCode: 2687,
		schoolFullName: "Murwillumbah Public School",
	},
	{
		schoolCode: 2688,
		schoolFullName: "Muswellbrook Public School",
	},
	{
		schoolCode: 2695,
		schoolFullName: "Nabiac Public School",
	},
	{
		schoolCode: 2696,
		schoolFullName: "Nambucca Heads Public School",
	},
	{
		schoolCode: 2698,
		schoolFullName: "Nana Glen Public School",
	},
	{
		schoolCode: 2699,
		schoolFullName: "Nangus Public School",
	},
	{
		schoolCode: 2701,
		schoolFullName: "Naradhan Public School",
	},
	{
		schoolCode: 2703,
		schoolFullName: "Narara Public School",
	},
	{
		schoolCode: 2704,
		schoolFullName: "Narellan Public School",
	},
	{
		schoolCode: 2706,
		schoolFullName: "Narrabeen Lakes Public School",
	},
	{
		schoolCode: 2708,
		schoolFullName: "Narrabri Public School",
	},
	{
		schoolCode: 2709,
		schoolFullName: "Narrabri West Public School",
	},
	{
		schoolCode: 2710,
		schoolFullName: "Narrandera Public School",
	},
	{
		schoolCode: 2713,
		schoolFullName: "Narromine Public School",
	},
	{
		schoolCode: 2714,
		schoolFullName: "Nashdale Public School",
	},
	{
		schoolCode: 2723,
		schoolFullName: "Tomaree Public School",
	},
	{
		schoolCode: 2725,
		schoolFullName: "Nemingha Public School",
	},
	{
		schoolCode: 2730,
		schoolFullName: "Neutral Bay Public School",
	},
	{
		schoolCode: 2732,
		schoolFullName: "Neville Public School",
	},
	{
		schoolCode: 2736,
		schoolFullName: "Newcastle East Public School",
	},
	{
		schoolCode: 2738,
		schoolFullName: "New Lambton Public School",
	},
	{
		schoolCode: 2740,
		schoolFullName: "Newport Public School",
	},
	{
		schoolCode: 2741,
		schoolFullName: "Newrybar Public School",
	},
	{
		schoolCode: 2744,
		schoolFullName: "Newtown North Public School",
	},
	{
		schoolCode: 2748,
		schoolFullName: "Niangala Public School",
	},
	{
		schoolCode: 2749,
		schoolFullName: "Nicholson Street Public School",
	},
	{
		schoolCode: 2751,
		schoolFullName: "Nimbin Central School",
	},
	{
		schoolCode: 2752,
		schoolFullName: "Nimmitabel Public School",
	},
	{
		schoolCode: 2758,
		schoolFullName: "Narooma Public School",
	},
	{
		schoolCode: 2759,
		schoolFullName: "Nords Wharf Public School",
	},
	{
		schoolCode: 2760,
		schoolFullName: "Normanhurst Public School",
	},
	{
		schoolCode: 2761,
		schoolFullName: "Northbridge Public School",
	},
	{
		schoolCode: 2763,
		schoolFullName: "Northmead Public School",
	},
	{
		schoolCode: 2764,
		schoolFullName: "North Rocks Public School",
	},
	{
		schoolCode: 2765,
		schoolFullName: "North Star Public School",
	},
	{
		schoolCode: 2766,
		schoolFullName: "North Sydney Public School",
	},
	{
		schoolCode: 2768,
		schoolFullName: "Nowendoc Public School",
	},
	{
		schoolCode: 2769,
		schoolFullName: "Nowra Public School",
	},
	{
		schoolCode: 2771,
		schoolFullName: "Nulkaba Public School",
	},
	{
		schoolCode: 2781,
		schoolFullName: "Nundle Public School",
	},
	{
		schoolCode: 2784,
		schoolFullName: "Nymboida Public School",
	},
	{
		schoolCode: 2785,
		schoolFullName: "Nyngan Public School",
	},
	{
		schoolCode: 2789,
		schoolFullName: "Oakdale Public School",
	},
	{
		schoolCode: 2791,
		schoolFullName: "Oaklands Central School",
	},
	{
		schoolCode: 2793,
		schoolFullName: "The Oaks Public School",
	},
	{
		schoolCode: 2794,
		schoolFullName: "Oakville Public School",
	},
	{
		schoolCode: 2796,
		schoolFullName: "Oatley Public School",
	},
	{
		schoolCode: 2797,
		schoolFullName: "Oberon Public School",
	},
	{
		schoolCode: 2798,
		schoolFullName: "O'Connell Public School",
	},
	{
		schoolCode: 2799,
		schoolFullName: "Old Bar Public School",
	},
	{
		schoolCode: 2800,
		schoolFullName: "Old Bonalbo Public School",
	},
	{
		schoolCode: 2801,
		schoolFullName: "Old Guildford Public School",
	},
	{
		schoolCode: 2811,
		schoolFullName: "Orange East Public School",
	},
	{
		schoolCode: 2812,
		schoolFullName: "Orange Grove Public School",
	},
	{
		schoolCode: 2814,
		schoolFullName: "Orara Upper Public School",
	},
	{
		schoolCode: 2815,
		schoolFullName: "Orchard Hills Public School",
	},
	{
		schoolCode: 2818,
		schoolFullName: "Otford Public School",
	},
	{
		schoolCode: 2820,
		schoolFullName: "Ourimbah Public School",
	},
	{
		schoolCode: 2826,
		schoolFullName: "Padstow Park Public School",
	},
	{
		schoolCode: 2827,
		schoolFullName: "Pallamallawa Public School",
	},
	{
		schoolCode: 2829,
		schoolFullName: "Palmers Island Public School",
	},
	{
		schoolCode: 2831,
		schoolFullName: "Pambula Public School",
	},
	{
		schoolCode: 2835,
		schoolFullName: "Parkes Public School",
	},
	{
		schoolCode: 2838,
		schoolFullName: "Parklea Public School",
	},
	{
		schoolCode: 2840,
		schoolFullName: "Parramatta Public School",
	},
	{
		schoolCode: 2841,
		schoolFullName: "Wooli Public School",
	},
	{
		schoolCode: 2842,
		schoolFullName: "Parramatta North Public School",
	},
	{
		schoolCode: 2843,
		schoolFullName: "Parramatta West Public School",
	},
	{
		schoolCode: 2844,
		schoolFullName: "Paterson Public School",
	},
	{
		schoolCode: 2846,
		schoolFullName: "Paxton Public School",
	},
	{
		schoolCode: 2848,
		schoolFullName: "Peak Hill Central School",
	},
	{
		schoolCode: 2849,
		schoolFullName: "Peakhurst Public School",
	},
	{
		schoolCode: 2852,
		schoolFullName: "Peats Ridge Public School",
	},
	{
		schoolCode: 2855,
		schoolFullName: "Pelaw Main Public School",
	},
	{
		schoolCode: 2857,
		schoolFullName: "Pennant Hills Public School",
	},
	{
		schoolCode: 2858,
		schoolFullName: "West Pennant Hills Public School",
	},
	{
		schoolCode: 2859,
		schoolFullName: "Penrith Public School",
	},
	{
		schoolCode: 2860,
		schoolFullName: "Penrose Public School",
	},
	{
		schoolCode: 2861,
		schoolFullName: "Penshurst Public School",
	},
	{
		schoolCode: 2862,
		schoolFullName: "Perthville Public School",
	},
	{
		schoolCode: 2867,
		schoolFullName: "Picton Public School",
	},
	{
		schoolCode: 2870,
		schoolFullName: "Pilliga Public School",
	},
	{
		schoolCode: 2884,
		schoolFullName: "Pitt Town Public School",
	},
	{
		schoolCode: 2886,
		schoolFullName: "Pleasant Hills Public School",
	},
	{
		schoolCode: 2887,
		schoolFullName: "Plumpton Public School",
	},
	{
		schoolCode: 2890,
		schoolFullName: "The Pocket Public School",
	},
	{
		schoolCode: 2893,
		schoolFullName: "Pomona Public School",
	},
	{
		schoolCode: 2895,
		schoolFullName: "Pooncarie Public School",
	},
	{
		schoolCode: 2896,
		schoolFullName: "Port Kembla Public School",
	},
	{
		schoolCode: 2897,
		schoolFullName: "Portland Central School",
	},
	{
		schoolCode: 2899,
		schoolFullName: "Port Macquarie Public School",
	},
	{
		schoolCode: 2900,
		schoolFullName: "Premer Public School",
	},
	{
		schoolCode: 2901,
		schoolFullName: "Prestons Public School",
	},
	{
		schoolCode: 2902,
		schoolFullName: "Pretty Beach Public School",
	},
	{
		schoolCode: 2904,
		schoolFullName: "Primbee Public School",
	},
	{
		schoolCode: 2910,
		schoolFullName: "Punchbowl Public School",
	},
	{
		schoolCode: 2913,
		schoolFullName: "Putney Public School",
	},
	{
		schoolCode: 2917,
		schoolFullName: "Quaama Public School",
	},
	{
		schoolCode: 2918,
		schoolFullName: "Quakers Hill Public School",
	},
	{
		schoolCode: 2919,
		schoolFullName: "Quambone Public School",
	},
	{
		schoolCode: 2920,
		schoolFullName: "Quandialla Public School",
	},
	{
		schoolCode: 2922,
		schoolFullName: "Queanbeyan Public School",
	},
	{
		schoolCode: 2926,
		schoolFullName: "Leppington Public School",
	},
	{
		schoolCode: 2927,
		schoolFullName: "Raglan Public School",
	},
	{
		schoolCode: 2930,
		schoolFullName: "Rainbow Street Public School",
	},
	{
		schoolCode: 2931,
		schoolFullName: "Raleigh Public School",
	},
	{
		schoolCode: 2933,
		schoolFullName: "Rand Public School",
	},
	{
		schoolCode: 2935,
		schoolFullName: "Rankins Springs Public School",
	},
	{
		schoolCode: 2937,
		schoolFullName: "Rappville Public School",
	},
	{
		schoolCode: 2943,
		schoolFullName: "Raymond Terrace Public School",
	},
	{
		schoolCode: 2946,
		schoolFullName: "Redhead Public School",
	},
	{
		schoolCode: 2948,
		schoolFullName: "Red Range Public School",
	},
	{
		schoolCode: 2950,
		schoolFullName: "Regents Park Public School",
	},
	{
		schoolCode: 2951,
		schoolFullName: "Regentville Public School",
	},
	{
		schoolCode: 2955,
		schoolFullName: "Repton Public School",
	},
	{
		schoolCode: 2958,
		schoolFullName: "Revesby Public School",
	},
	{
		schoolCode: 2965,
		schoolFullName: "Richmond North Public School",
	},
	{
		schoolCode: 2968,
		schoolFullName: "The Risk Public School",
	},
	{
		schoolCode: 2970,
		schoolFullName: "Riverstone Public School",
	},
	{
		schoolCode: 2971,
		schoolFullName: "Robertson Public School",
	},
	{
		schoolCode: 2973,
		schoolFullName: "Rockdale Public School",
	},
	{
		schoolCode: 2974,
		schoolFullName: "Rockley Public School",
	},
	{
		schoolCode: 2976,
		schoolFullName: "The Rock Central School",
	},
	{
		schoolCode: 2980,
		schoolFullName: "Whian Whian Public School",
	},
	{
		schoolCode: 2983,
		schoolFullName: "Rocky River Public School",
	},
	{
		schoolCode: 2985,
		schoolFullName: "Rollands Plains Upper Public School",
	},
	{
		schoolCode: 2987,
		schoolFullName: "Rosebank Public School",
	},
	{
		schoolCode: 2988,
		schoolFullName: "Rose Bay Public School",
	},
	{
		schoolCode: 2994,
		schoolFullName: "Roseville Public School",
	},
	{
		schoolCode: 2996,
		schoolFullName: "Rosewood Public School",
	},
	{
		schoolCode: 2997,
		schoolFullName: "Ross Hill Public School",
	},
	{
		schoolCode: 2999,
		schoolFullName: "Rossmore Public School",
	},
	{
		schoolCode: 3006,
		schoolFullName: "Rous Public School",
	},
	{
		schoolCode: 3008,
		schoolFullName: "Rouse Hill Public School",
	},
	{
		schoolCode: 3013,
		schoolFullName: "Rukenvale Public School",
	},
	{
		schoolCode: 3020,
		schoolFullName: "Rydalmere Public School",
	},
	{
		schoolCode: 3021,
		schoolFullName: "Ryde Public School",
	},
	{
		schoolCode: 3022,
		schoolFullName: "North Ryde Public School",
	},
	{
		schoolCode: 3023,
		schoolFullName: "West Ryde Public School",
	},
	{
		schoolCode: 3024,
		schoolFullName: "Rye Park Public School",
	},
	{
		schoolCode: 3025,
		schoolFullName: "Rylstone Public School",
	},
	{
		schoolCode: 3029,
		schoolFullName: "St Johns Park Public School",
	},
	{
		schoolCode: 3031,
		schoolFullName: "St Marys Public School",
	},
	{
		schoolCode: 3032,
		schoolFullName: "St Peters Public School",
	},
	{
		schoolCode: 3035,
		schoolFullName: "Salt Ash Public School",
	},
	{
		schoolCode: 3038,
		schoolFullName: "Sans Souci Public School",
	},
	{
		schoolCode: 3041,
		schoolFullName: "Savernake Public School",
	},
	{
		schoolCode: 3042,
		schoolFullName: "Sawtell Public School",
	},
	{
		schoolCode: 3045,
		schoolFullName: "Scarborough Public School",
	},
	{
		schoolCode: 3046,
		schoolFullName: "Schofields Public School",
	},
	{
		schoolCode: 3048,
		schoolFullName: "Seaham Public School",
	},
	{
		schoolCode: 3053,
		schoolFullName: "Seven Hills North Public School",
	},
	{
		schoolCode: 3060,
		schoolFullName: "Shellharbour Public School",
	},
	{
		schoolCode: 3065,
		schoolFullName: "Shortland Public School",
	},
	{
		schoolCode: 3070,
		schoolFullName: "Singleton Public School",
	},
	{
		schoolCode: 3073,
		schoolFullName: "Smithfield Public School",
	},
	{
		schoolCode: 3075,
		schoolFullName: "Smithtown Public School",
	},
	{
		schoolCode: 3077,
		schoolFullName: "Sofala Public School",
	},
	{
		schoolCode: 3078,
		schoolFullName: "Somersby Public School",
	},
	{
		schoolCode: 3079,
		schoolFullName: "Somerton Public School",
	},
	{
		schoolCode: 3083,
		schoolFullName: "South West Rocks Public School",
	},
	{
		schoolCode: 3089,
		schoolFullName: "Spring Hill Public School",
	},
	{
		schoolCode: 3091,
		schoolFullName: "Spring Ridge Public School",
	},
	{
		schoolCode: 3093,
		schoolFullName: "Spring Terrace Public School",
	},
	{
		schoolCode: 3095,
		schoolFullName: "Springwood Public School",
	},
	{
		schoolCode: 3103,
		schoolFullName: "Stanwell Park Public School",
	},
	{
		schoolCode: 3104,
		schoolFullName: "Warrawong Public School",
	},
	{
		schoolCode: 3109,
		schoolFullName: "Stockinbingal Public School",
	},
	{
		schoolCode: 3110,
		schoolFullName: "Stockton Public School",
	},
	{
		schoolCode: 3112,
		schoolFullName: "Stokers Siding Public School",
	},
	{
		schoolCode: 3119,
		schoolFullName: "Stratford Public School",
	},
	{
		schoolCode: 3122,
		schoolFullName: "Stratheden Public School",
	},
	{
		schoolCode: 3123,
		schoolFullName: "Strathfield North Public School",
	},
	{
		schoolCode: 3124,
		schoolFullName: "Strathfield South Public School",
	},
	{
		schoolCode: 3125,
		schoolFullName: "Stroud Public School",
	},
	{
		schoolCode: 3126,
		schoolFullName: "Stroud Road Public School",
	},
	{
		schoolCode: 3127,
		schoolFullName: "Stuart Town Public School",
	},
	{
		schoolCode: 3129,
		schoolFullName: "Summer Hill Public School",
	},
	{
		schoolCode: 3136,
		schoolFullName: "Sutherland Public School",
	},
	{
		schoolCode: 3137,
		schoolFullName: "Sutton Public School",
	},
	{
		schoolCode: 3141,
		schoolFullName: "Swansea Public School",
	},
	{
		schoolCode: 3143,
		schoolFullName: "Sylvania Public School",
	},
	{
		schoolCode: 3145,
		schoolFullName: "Tabulam Public School",
	},
	{
		schoolCode: 3147,
		schoolFullName: "Tacoma Public School",
	},
	{
		schoolCode: 3148,
		schoolFullName: "Tahmoor Public School",
	},
	{
		schoolCode: 3153,
		schoolFullName: "Tallimba Public School",
	},
	{
		schoolCode: 3154,
		schoolFullName: "Tallong Public School",
	},
	{
		schoolCode: 3156,
		schoolFullName: "Tambar Springs Public School",
	},
	{
		schoolCode: 3157,
		schoolFullName: "Tamworth Public School",
	},
	{
		schoolCode: 3158,
		schoolFullName: "Tamworth West Public School",
	},
	{
		schoolCode: 3159,
		schoolFullName: "Tanja Public School",
	},
	{
		schoolCode: 3162,
		schoolFullName: "Tarago Public School",
	},
	{
		schoolCode: 3163,
		schoolFullName: "Taralga Public School",
	},
	{
		schoolCode: 3165,
		schoolFullName: "Tarcutta Public School",
	},
	{
		schoolCode: 3167,
		schoolFullName: "Taree Public School",
	},
	{
		schoolCode: 3168,
		schoolFullName: "Taren Point Public School",
	},
	{
		schoolCode: 3173,
		schoolFullName: "Tathra Public School",
	},
	{
		schoolCode: 3175,
		schoolFullName: "Tea Gardens Public School",
	},
	{
		schoolCode: 3176,
		schoolFullName: "Telegraph Point Public School",
	},
	{
		schoolCode: 3178,
		schoolFullName: "Temora Public School",
	},
	{
		schoolCode: 3180,
		schoolFullName: "Tenambit Public School",
	},
	{
		schoolCode: 3182,
		schoolFullName: "The Sir Henry Parkes Memorial Public Sch",
	},
	{
		schoolCode: 3183,
		schoolFullName: "Teralba Public School",
	},
	{
		schoolCode: 3185,
		schoolFullName: "Terara Public School",
	},
	{
		schoolCode: 3189,
		schoolFullName: "Terranora Public School",
	},
	{
		schoolCode: 3190,
		schoolFullName: "Terrey Hills Public School",
	},
	{
		schoolCode: 3191,
		schoolFullName: "Terrigal Public School",
	},
	{
		schoolCode: 3195,
		schoolFullName: "Tharbogang Public School",
	},
	{
		schoolCode: 3200,
		schoolFullName: "Thirlmere Public School",
	},
	{
		schoolCode: 3201,
		schoolFullName: "Thirroul Public School",
	},
	{
		schoolCode: 3204,
		schoolFullName: "Thornton Public School",
	},
	{
		schoolCode: 3208,
		schoolFullName: "Thurgoona Public School",
	},
	{
		schoolCode: 3211,
		schoolFullName: "Tibooburra Outback Public School",
	},
	{
		schoolCode: 3213,
		schoolFullName: "Tighes Hill Public School",
	},
	{
		schoolCode: 3214,
		schoolFullName: "Central Tilba Public School",
	},
	{
		schoolCode: 3218,
		schoolFullName: "Tingha Public School",
	},
	{
		schoolCode: 3219,
		schoolFullName: "Tinonee Public School",
	},
	{
		schoolCode: 3221,
		schoolFullName: "Tintinhull Public School",
	},
	{
		schoolCode: 3222,
		schoolFullName: "Tirranna Public School",
	},
	{
		schoolCode: 3225,
		schoolFullName: "Tocumwal Public School",
	},
	{
		schoolCode: 3226,
		schoolFullName: "Tomerong Public School",
	},
	{
		schoolCode: 3235,
		schoolFullName: "Tooleybuc Central School",
	},
	{
		schoolCode: 3236,
		schoolFullName: "Main Arm Upper Public School",
	},
	{
		schoolCode: 3239,
		schoolFullName: "Toomelah Public School",
	},
	{
		schoolCode: 3240,
		schoolFullName: "Toongabbie Public School",
	},
	{
		schoolCode: 3242,
		schoolFullName: "Tooraweenah Public School",
	},
	{
		schoolCode: 3247,
		schoolFullName: "Toronto Public School",
	},
	{
		schoolCode: 3249,
		schoolFullName: "Tottenham Central School",
	},
	{
		schoolCode: 3250,
		schoolFullName: "Toukley Public School",
	},
	{
		schoolCode: 3252,
		schoolFullName: "Towamba Public School",
	},
	{
		schoolCode: 3254,
		schoolFullName: "Trangie Central School",
	},
	{
		schoolCode: 3255,
		schoolFullName: "Tregeagle Public School",
	},
	{
		schoolCode: 3259,
		schoolFullName: "Trundle Central School",
	},
	{
		schoolCode: 3260,
		schoolFullName: "Trunkey Public School",
	},
	{
		schoolCode: 3263,
		schoolFullName: "Tucabia Public School",
	},
	{
		schoolCode: 3269,
		schoolFullName: "Tuggerah Public School",
	},
	{
		schoolCode: 3270,
		schoolFullName: "Tullamore Central School",
	},
	{
		schoolCode: 3272,
		schoolFullName: "Tullibigeal Central School",
	},
	{
		schoolCode: 3275,
		schoolFullName: "Tumbarumba Public School",
	},
	{
		schoolCode: 3277,
		schoolFullName: "Tumbulgum Public School",
	},
	{
		schoolCode: 3278,
		schoolFullName: "Tumut Public School",
	},
	{
		schoolCode: 3283,
		schoolFullName: "Tuntable Creek Public School",
	},
	{
		schoolCode: 3288,
		schoolFullName: "Turramurra North Public School",
	},
	{
		schoolCode: 3290,
		schoolFullName: "Tweed Heads Public School",
	},
	{
		schoolCode: 3293,
		schoolFullName: "Tyalgum Public School",
	},
	{
		schoolCode: 3298,
		schoolFullName: "Uki Public School",
	},
	{
		schoolCode: 3300,
		schoolFullName: "Ulan Public School",
	},
	{
		schoolCode: 3302,
		schoolFullName: "Ulladulla Public School",
	},
	{
		schoolCode: 3303,
		schoolFullName: "Ulmarra Public School",
	},
	{
		schoolCode: 3304,
		schoolFullName: "Ulong Public School",
	},
	{
		schoolCode: 3305,
		schoolFullName: "Ultimo Public School",
	},
	{
		schoolCode: 3308,
		schoolFullName: "Unanderra Public School",
	},
	{
		schoolCode: 3310,
		schoolFullName: "Undercliffe Public School",
	},
	{
		schoolCode: 3311,
		schoolFullName: "Ungarie Central School",
	},
	{
		schoolCode: 3314,
		schoolFullName: "Urana Public School",
	},
	{
		schoolCode: 3316,
		schoolFullName: "Urbenville Public School",
	},
	{
		schoolCode: 3318,
		schoolFullName: "Urunga Public School",
	},
	{
		schoolCode: 3321,
		schoolFullName: "Vacy Public School",
	},
	{
		schoolCode: 3325,
		schoolFullName: "Vaucluse Public School",
	},
	{
		schoolCode: 3328,
		schoolFullName: "Carramar Public School",
	},
	{
		schoolCode: 3329,
		schoolFullName: "Vineyard Public School",
	},
	{
		schoolCode: 3330,
		schoolFullName: "Wagga Wagga Public School",
	},
	{
		schoolCode: 3332,
		schoolFullName: "North Wagga Public School",
	},
	{
		schoolCode: 3333,
		schoolFullName: "South Wagga Public School",
	},
	{
		schoolCode: 3334,
		schoolFullName: "Waitara Public School",
	},
	{
		schoolCode: 3336,
		schoolFullName: "Wakool Burraboi Public School",
	},
	{
		schoolCode: 3338,
		schoolFullName: "Walbundrie Public School",
	},
	{
		schoolCode: 3340,
		schoolFullName: "Walcha Central School",
	},
	{
		schoolCode: 3342,
		schoolFullName: "Walgett Community College Primary School",
	},
	{
		schoolCode: 3343,
		schoolFullName: "Walhallow Public School",
	},
	{
		schoolCode: 3345,
		schoolFullName: "Wallabadah Public School",
	},
	{
		schoolCode: 3346,
		schoolFullName: "Wallacia Public School",
	},
	{
		schoolCode: 3352,
		schoolFullName: "Walla Walla Public School",
	},
	{
		schoolCode: 3353,
		schoolFullName: "Wallendbeen Public School",
	},
	{
		schoolCode: 3354,
		schoolFullName: "Wallerawang Public School",
	},
	{
		schoolCode: 3357,
		schoolFullName: "Wallsend Public School",
	},
	{
		schoolCode: 3358,
		schoolFullName: "West Wallsend Public School",
	},
	{
		schoolCode: 3364,
		schoolFullName: "Wamoon Public School",
	},
	{
		schoolCode: 3365,
		schoolFullName: "Wanaaring Public School",
	},
	{
		schoolCode: 3373,
		schoolFullName: "Wangi Wangi Public School",
	},
	{
		schoolCode: 3377,
		schoolFullName: "Waratah Public School",
	},
	{
		schoolCode: 3378,
		schoolFullName: "Wardell Public School",
	},
	{
		schoolCode: 3382,
		schoolFullName: "Warialda Public School",
	},
	{
		schoolCode: 3384,
		schoolFullName: "Warners Bay Public School",
	},
	{
		schoolCode: 3390,
		schoolFullName: "Warrawee Public School",
	},
	{
		schoolCode: 3392,
		schoolFullName: "Warren Central School",
	},
	{
		schoolCode: 3396,
		schoolFullName: "Waterfall Public School",
	},
	{
		schoolCode: 3402,
		schoolFullName: "Wattle Flat Public School",
	},
	{
		schoolCode: 3404,
		schoolFullName: "Wauchope Public School",
	},
	{
		schoolCode: 3412,
		schoolFullName: "Wee Jasper Public School",
	},
	{
		schoolCode: 3415,
		schoolFullName: "Weethalle Public School",
	},
	{
		schoolCode: 3416,
		schoolFullName: "Wee Waa Public School",
	},
	{
		schoolCode: 3420,
		schoolFullName: "Wellington Public School",
	},
	{
		schoolCode: 3421,
		schoolFullName: "Wentworth Public School",
	},
	{
		schoolCode: 3422,
		schoolFullName: "Wentworth Falls Public School",
	},
	{
		schoolCode: 3423,
		schoolFullName: "Wentworthville Public School",
	},
	{
		schoolCode: 3425,
		schoolFullName: "Werris Creek Public School",
	},
	{
		schoolCode: 3428,
		schoolFullName: "Westdale Public School",
	},
	{
		schoolCode: 3431,
		schoolFullName: "Weston Public School",
	},
	{
		schoolCode: 3435,
		schoolFullName: "White Cliffs Public School",
	},
	{
		schoolCode: 3438,
		schoolFullName: "Whitton-Murrami Public School",
	},
	{
		schoolCode: 3439,
		schoolFullName: "Wiangaree Public School",
	},
	{
		schoolCode: 3441,
		schoolFullName: "Wilberforce Public School",
	},
	{
		schoolCode: 3442,
		schoolFullName: "Wilcannia Central School",
	},
	{
		schoolCode: 3445,
		schoolFullName: "Willawarrin Public School",
	},
	{
		schoolCode: 3449,
		schoolFullName: "Willoughby Public School",
	},
	{
		schoolCode: 3451,
		schoolFullName: "Willow Tree Public School",
	},
	{
		schoolCode: 3453,
		schoolFullName: "Athelstane Public School",
	},
	{
		schoolCode: 3454,
		schoolFullName: "Wilsons Creek Public School",
	},
	{
		schoolCode: 3457,
		schoolFullName: "Windellama Public School",
	},
	{
		schoolCode: 3459,
		schoolFullName: "Windsor Public School",
	},
	{
		schoolCode: 3461,
		schoolFullName: "Wingello Public School",
	},
	{
		schoolCode: 3470,
		schoolFullName: "Wisemans Ferry Public School",
	},
	{
		schoolCode: 3473,
		schoolFullName: "Wollar Public School",
	},
	{
		schoolCode: 3475,
		schoolFullName: "Wollongbar Public School",
	},
	{
		schoolCode: 3477,
		schoolFullName: "Wollongong West Public School",
	},
	{
		schoolCode: 3479,
		schoolFullName: "Wolumla Public School",
	},
	{
		schoolCode: 3482,
		schoolFullName: "Wombat Public School",
	},
	{
		schoolCode: 3487,
		schoolFullName: "Wongarbon Public School",
	},
	{
		schoolCode: 3490,
		schoolFullName: "Woodburn Public School",
	},
	{
		schoolCode: 3491,
		schoolFullName: "Woodenbong Central School",
	},
	{
		schoolCode: 3499,
		schoolFullName: "Woodstock Public School",
	},
	{
		schoolCode: 3501,
		schoolFullName: "Woolbrook Public School",
	},
	{
		schoolCode: 3503,
		schoolFullName: "Woolgoolga Public School",
	},
	{
		schoolCode: 3504,
		schoolFullName: "Borenore Public School",
	},
	{
		schoolCode: 3506,
		schoolFullName: "Woolomin Public School",
	},
	{
		schoolCode: 3510,
		schoolFullName: "Woonona Public School",
	},
	{
		schoolCode: 3518,
		schoolFullName: "Wyalong Public School",
	},
	{
		schoolCode: 3519,
		schoolFullName: "West Wyalong Public School",
	},
	{
		schoolCode: 3521,
		schoolFullName: "Wyangala Dam Public School",
	},
	{
		schoolCode: 3522,
		schoolFullName: "Wyee Public School",
	},
	{
		schoolCode: 3525,
		schoolFullName: "Wyndham Public School",
	},
	{
		schoolCode: 3527,
		schoolFullName: "Wyong Public School",
	},
	{
		schoolCode: 3528,
		schoolFullName: "Wyong Creek Public School",
	},
	{
		schoolCode: 3529,
		schoolFullName: "Wyrallah Public School",
	},
	{
		schoolCode: 3533,
		schoolFullName: "Yamba Public School",
	},
	{
		schoolCode: 3534,
		schoolFullName: "Table Top Public School",
	},
	{
		schoolCode: 3536,
		schoolFullName: "Yanco Public School",
	},
	{
		schoolCode: 3537,
		schoolFullName: "Yanderra Public School",
	},
	{
		schoolCode: 3547,
		schoolFullName: "Yarrowitch Public School",
	},
	{
		schoolCode: 3549,
		schoolFullName: "Yass Public School",
	},
	{
		schoolCode: 3554,
		schoolFullName: "Yenda Public School",
	},
	{
		schoolCode: 3555,
		schoolFullName: "Yeoval Central School",
	},
	{
		schoolCode: 3556,
		schoolFullName: "Yerong Creek Public School",
	},
	{
		schoolCode: 3559,
		schoolFullName: "Yetman Public School",
	},
	{
		schoolCode: 3561,
		schoolFullName: "Yoogali Public School",
	},
	{
		schoolCode: 3563,
		schoolFullName: "Young Public School",
	},
	{
		schoolCode: 3564,
		schoolFullName: "Edgeworth Public School",
	},
	{
		schoolCode: 3566,
		schoolFullName: "Zig Zag Public School",
	},
	{
		schoolCode: 3578,
		schoolFullName: "Pottsville Beach Public School",
	},
	{
		schoolCode: 3580,
		schoolFullName: "Rowena Public School",
	},
	{
		schoolCode: 3591,
		schoolFullName: "Dubbo West Public School",
	},
	{
		schoolCode: 3593,
		schoolFullName: "Oyster Bay Public School",
	},
	{
		schoolCode: 3594,
		schoolFullName: "Louth Public School",
	},
	{
		schoolCode: 3615,
		schoolFullName: "Kemps Creek Public School",
	},
	{
		schoolCode: 3626,
		schoolFullName: "Enngonia Public School",
	},
	{
		schoolCode: 3632,
		schoolFullName: "Fairfield Heights Public School",
	},
	{
		schoolCode: 3635,
		schoolFullName: "Scotts Head Public School",
	},
	{
		schoolCode: 3640,
		schoolFullName: "Afterlee Public School",
	},
	{
		schoolCode: 3643,
		schoolFullName: "Stuarts Point Public School",
	},
	{
		schoolCode: 3659,
		schoolFullName: "Sussex Inlet Public School",
	},
	{
		schoolCode: 3668,
		schoolFullName: "Cronulla South Public School",
	},
	{
		schoolCode: 3673,
		schoolFullName: "St Georges Basin Public School",
	},
	{
		schoolCode: 3678,
		schoolFullName: "Warwick Farm Public School",
	},
	{
		schoolCode: 3690,
		schoolFullName: "Oxley Vale Public School",
	},
	{
		schoolCode: 3696,
		schoolFullName: "Soldiers Point Public School",
	},
	{
		schoolCode: 3698,
		schoolFullName: "Chester Hill Public School",
	},
	{
		schoolCode: 3702,
		schoolFullName: "Georges Hall Public School",
	},
	{
		schoolCode: 3703,
		schoolFullName: "South Coogee Public School",
	},
	{
		schoolCode: 3717,
		schoolFullName: "Croppa Creek Public School",
	},
	{
		schoolCode: 3719,
		schoolFullName: "Annangrove Public School",
	},
	{
		schoolCode: 3735,
		schoolFullName: "Woronora River Public School",
	},
	{
		schoolCode: 3736,
		schoolFullName: "Balgowlah Heights Public School",
	},
	{
		schoolCode: 3741,
		schoolFullName: "Goulburn West Public School",
	},
	{
		schoolCode: 3743,
		schoolFullName: "Riverwood Public School",
	},
	{
		schoolCode: 3744,
		schoolFullName: "Warragamba Public School",
	},
	{
		schoolCode: 3748,
		schoolFullName: "Belrose Public School",
	},
	{
		schoolCode: 3760,
		schoolFullName: "Upper Coopers Creek Public School",
	},
	{
		schoolCode: 3762,
		schoolFullName: "Wiripaang Public School",
	},
	{
		schoolCode: 3765,
		schoolFullName: "Bundeena Public School",
	},
	{
		schoolCode: 3777,
		schoolFullName: "Pymble Public School",
	},
	{
		schoolCode: 3789,
		schoolFullName: "Condell Park Public School",
	},
	{
		schoolCode: 3792,
		schoolFullName: "Avalon Public School",
	},
	{
		schoolCode: 3794,
		schoolFullName: "Bald Face Public School",
	},
	{
		schoolCode: 3795,
		schoolFullName: "Hamilton South Public School",
	},
	{
		schoolCode: 3796,
		schoolFullName: "Lugarno Public School",
	},
	{
		schoolCode: 3797,
		schoolFullName: "Oatley West Public School",
	},
	{
		schoolCode: 3798,
		schoolFullName: "New Lambton South Public School",
	},
	{
		schoolCode: 3808,
		schoolFullName: "Chullora Public School",
	},
	{
		schoolCode: 3820,
		schoolFullName: "Tarrawanna Public School",
	},
	{
		schoolCode: 3822,
		schoolFullName: "Sutherland North Public School",
	},
	{
		schoolCode: 3824,
		schoolFullName: "Bathurst West Public School",
	},
	{
		schoolCode: 3826,
		schoolFullName: "Gwynneville Public School",
	},
	{
		schoolCode: 3827,
		schoolFullName: "Narraweena Public School",
	},
	{
		schoolCode: 3829,
		schoolFullName: "Jannali Public School",
	},
	{
		schoolCode: 3830,
		schoolFullName: "Ramsgate Public School",
	},
	{
		schoolCode: 3831,
		schoolFullName: "Chalmers Road School",
	},
	{
		schoolCode: 3833,
		schoolFullName: "Wahroonga Public School",
	},
	{
		schoolCode: 3840,
		schoolFullName: "Parramatta East Public School",
	},
	{
		schoolCode: 3846,
		schoolFullName: "Melrose Park Public School",
	},
	{
		schoolCode: 3847,
		schoolFullName: "Bathurst South Public School",
	},
	{
		schoolCode: 3851,
		schoolFullName: "Wallsend South Public School",
	},
	{
		schoolCode: 3854,
		schoolFullName: "Hazelbrook Public School",
	},
	{
		schoolCode: 3855,
		schoolFullName: "Turramurra Public School",
	},
	{
		schoolCode: 3856,
		schoolFullName: "Wyrallah Road Public School",
	},
	{
		schoolCode: 3860,
		schoolFullName: "Oak Flats Public School",
	},
	{
		schoolCode: 3862,
		schoolFullName: "Denistone East Public School",
	},
	{
		schoolCode: 3863,
		schoolFullName: "Meadowbank Public School",
	},
	{
		schoolCode: 3864,
		schoolFullName: "Uralla Central School",
	},
	{
		schoolCode: 3866,
		schoolFullName: "Towradgi Public School",
	},
	{
		schoolCode: 3867,
		schoolFullName: "Lake Illawarra South Public School",
	},
	{
		schoolCode: 3869,
		schoolFullName: "Yagoona Public School",
	},
	{
		schoolCode: 3871,
		schoolFullName: "Wattle Grove Public School",
	},
	{
		schoolCode: 3872,
		schoolFullName: "Greenwell Point Public School",
	},
	{
		schoolCode: 3873,
		schoolFullName: "Dobroyd Point Public School",
	},
	{
		schoolCode: 3874,
		schoolFullName: "Kahibah Public School",
	},
	{
		schoolCode: 3875,
		schoolFullName: "Narwee Public School",
	},
	{
		schoolCode: 3876,
		schoolFullName: "Muswellbrook South Public School",
	},
	{
		schoolCode: 3877,
		schoolFullName: "Corrimal East Public School",
	},
	{
		schoolCode: 3881,
		schoolFullName: "Mount St Thomas Public School",
	},
	{
		schoolCode: 3882,
		schoolFullName: "Tamworth South Public School",
	},
	{
		schoolCode: 3883,
		schoolFullName: "Westmead Public School",
	},
	{
		schoolCode: 3885,
		schoolFullName: "Mount Colah Public School",
	},
	{
		schoolCode: 3886,
		schoolFullName: "McCallums Hill Public School",
	},
	{
		schoolCode: 3890,
		schoolFullName: "Cooma North Public School",
	},
	{
		schoolCode: 3893,
		schoolFullName: "Windale Public School",
	},
	{
		schoolCode: 3895,
		schoolFullName: "Cardiff South Public School",
	},
	{
		schoolCode: 3896,
		schoolFullName: "Como West Public School",
	},
	{
		schoolCode: 3897,
		schoolFullName: "Hamilton North Public School",
	},
	{
		schoolCode: 3898,
		schoolFullName: "Marks Point Public School",
	},
	{
		schoolCode: 3903,
		schoolFullName: "Glen Alice Public School",
	},
	{
		schoolCode: 3904,
		schoolFullName: "Argenton Public School",
	},
	{
		schoolCode: 3905,
		schoolFullName: "Pagewood Public School",
	},
	{
		schoolCode: 3906,
		schoolFullName: "Narrabeen North Public School",
	},
	{
		schoolCode: 3908,
		schoolFullName: "Lane Cove West Public School",
	},
	{
		schoolCode: 3909,
		schoolFullName: "Taree West Public School",
	},
	{
		schoolCode: 3912,
		schoolFullName: "Palinyewah Public School",
	},
	{
		schoolCode: 3914,
		schoolFullName: "Grays Point Public School",
	},
	{
		schoolCode: 3918,
		schoolFullName: "Killara Public School",
	},
	{
		schoolCode: 3919,
		schoolFullName: "Castle Cove Public School",
	},
	{
		schoolCode: 3922,
		schoolFullName: "Albury North Public School",
	},
	{
		schoolCode: 3923,
		schoolFullName: "Kapooka Public School",
	},
	{
		schoolCode: 3924,
		schoolFullName: "East Hills Public School",
	},
	{
		schoolCode: 3925,
		schoolFullName: "Curl Curl North Public School",
	},
	{
		schoolCode: 3927,
		schoolFullName: "Belmont North Public School",
	},
	{
		schoolCode: 3928,
		schoolFullName: "Cabramurra Public School",
	},
	{
		schoolCode: 3931,
		schoolFullName: "Seven Hills Public School",
	},
	{
		schoolCode: 3932,
		schoolFullName: "Waratah West Public School",
	},
	{
		schoolCode: 3935,
		schoolFullName: "Crown Street Public School",
	},
	{
		schoolCode: 3939,
		schoolFullName: "Mount Hutton Public School",
	},
	{
		schoolCode: 3943,
		schoolFullName: "Gordon West Public School",
	},
	{
		schoolCode: 3944,
		schoolFullName: "Beverly Hills North Public School",
	},
	{
		schoolCode: 3945,
		schoolFullName: "Finley Public School",
	},
	{
		schoolCode: 3946,
		schoolFullName: "Maclean Public School",
	},
	{
		schoolCode: 3947,
		schoolFullName: "Quirindi Public School",
	},
	{
		schoolCode: 3948,
		schoolFullName: "Tempe Public School",
	},
	{
		schoolCode: 3949,
		schoolFullName: "Maitland Public School",
	},
	{
		schoolCode: 3950,
		schoolFullName: "Manly Village Public School",
	},
	{
		schoolCode: 3951,
		schoolFullName: "Marrickville West Public School",
	},
	{
		schoolCode: 3952,
		schoolFullName: "Marrickville Public School",
	},
	{
		schoolCode: 3953,
		schoolFullName: "Waverley Public School",
	},
	{
		schoolCode: 3954,
		schoolFullName: "Wollongong Public School",
	},
	{
		schoolCode: 3956,
		schoolFullName: "Beaumont Road Public School",
	},
	{
		schoolCode: 3957,
		schoolFullName: "Woolooware Public School",
	},
	{
		schoolCode: 3958,
		schoolFullName: "Padstow Heights Public School",
	},
	{
		schoolCode: 3960,
		schoolFullName: "Waniora Public School",
	},
	{
		schoolCode: 3964,
		schoolFullName: "Figtree Public School",
	},
	{
		schoolCode: 3966,
		schoolFullName: "Blackalls Park Public School",
	},
	{
		schoolCode: 3968,
		schoolFullName: "Speers Point Public School",
	},
	{
		schoolCode: 3970,
		schoolFullName: "Collaroy Plateau Public School",
	},
	{
		schoolCode: 3971,
		schoolFullName: "Beacon Hill Public School",
	},
	{
		schoolCode: 3972,
		schoolFullName: "Caringbah North Public School",
	},
	{
		schoolCode: 3973,
		schoolFullName: "Russell Vale Public School",
	},
	{
		schoolCode: 3976,
		schoolFullName: "Farmborough Road Public School",
	},
	{
		schoolCode: 3977,
		schoolFullName: "Villawood East Public School",
	},
	{
		schoolCode: 3979,
		schoolFullName: "Bellambi Public School",
	},
	{
		schoolCode: 3980,
		schoolFullName: "Cabramatta West Public School",
	},
	{
		schoolCode: 3981,
		schoolFullName: "Chester Hill North Public School",
	},
	{
		schoolCode: 3983,
		schoolFullName: "Canley Heights Public School",
	},
	{
		schoolCode: 3984,
		schoolFullName: "Balgowlah North Public School",
	},
	{
		schoolCode: 3985,
		schoolFullName: "Gordon East Public School",
	},
	{
		schoolCode: 3986,
		schoolFullName: "Yennora Public School",
	},
	{
		schoolCode: 3987,
		schoolFullName: "Katoomba North Public School",
	},
	{
		schoolCode: 3988,
		schoolFullName: "Biraban Public School",
	},
	{
		schoolCode: 3993,
		schoolFullName: "Pendle Hill Public School",
	},
	{
		schoolCode: 3995,
		schoolFullName: "Umina Public School",
	},
	{
		schoolCode: 3996,
		schoolFullName: "Fern Bay Public School",
	},
	{
		schoolCode: 3997,
		schoolFullName: "Hilltop Road Public School",
	},
	{
		schoolCode: 3999,
		schoolFullName: "Richmond Public School",
	},
	{
		schoolCode: 4000,
		schoolFullName: "Guildford West Public School",
	},
	{
		schoolCode: 4004,
		schoolFullName: "Jannali East Public School",
	},
	{
		schoolCode: 4005,
		schoolFullName: "Warilla Public School",
	},
	{
		schoolCode: 4009,
		schoolFullName: "Fairvale Public School",
	},
	{
		schoolCode: 4011,
		schoolFullName: "Lake Heights Public School",
	},
	{
		schoolCode: 4012,
		schoolFullName: "Panania North Public School",
	},
	{
		schoolCode: 4013,
		schoolFullName: "Peakhurst West Public School",
	},
	{
		schoolCode: 4014,
		schoolFullName: "Chatham Public School",
	},
	{
		schoolCode: 4015,
		schoolFullName: "Mowbray Public School",
	},
	{
		schoolCode: 4016,
		schoolFullName: "Revesby South Public School",
	},
	{
		schoolCode: 4017,
		schoolFullName: "Booragul Public School",
	},
	{
		schoolCode: 4018,
		schoolFullName: "Gunnedah South Public School",
	},
	{
		schoolCode: 4019,
		schoolFullName: "Forestville Public School",
	},
	{
		schoolCode: 4020,
		schoolFullName: "Glenroi Heights Public School",
	},
	{
		schoolCode: 4021,
		schoolFullName: "Manly Vale Public School",
	},
	{
		schoolCode: 4022,
		schoolFullName: "Coal Point Public School",
	},
	{
		schoolCode: 4024,
		schoolFullName: "Sylvania Heights Public School",
	},
	{
		schoolCode: 4027,
		schoolFullName: "Griffith North Public School",
	},
	{
		schoolCode: 4029,
		schoolFullName: "Dundas Public School",
	},
	{
		schoolCode: 4034,
		schoolFullName: "Eleebana Public School",
	},
	{
		schoolCode: 4037,
		schoolFullName: "Cringila Public School",
	},
	{
		schoolCode: 4038,
		schoolFullName: "Epping Heights Public School",
	},
	{
		schoolCode: 4041,
		schoolFullName: "Eastwood Heights Public School",
	},
	{
		schoolCode: 4042,
		schoolFullName: "Lindsay Park Public School",
	},
	{
		schoolCode: 4049,
		schoolFullName: "Mount Kuring-gai Public School",
	},
	{
		schoolCode: 4050,
		schoolFullName: "Point Clare Public School",
	},
	{
		schoolCode: 4051,
		schoolFullName: "Penshurst West Public School",
	},
	{
		schoolCode: 4054,
		schoolFullName: "Kingscliff Public School",
	},
	{
		schoolCode: 4055,
		schoolFullName: "Oxley Park Public School",
	},
	{
		schoolCode: 4056,
		schoolFullName: "Rooty Hill Public School",
	},
	{
		schoolCode: 4057,
		schoolFullName: "Birrong Public School",
	},
	{
		schoolCode: 4058,
		schoolFullName: "Leumeah Public School",
	},
	{
		schoolCode: 4059,
		schoolFullName: "Wattawa Heights Public School",
	},
	{
		schoolCode: 4060,
		schoolFullName: "Cambridge Park Public School",
	},
	{
		schoolCode: 4061,
		schoolFullName: "Blacktown North Public School",
	},
	{
		schoolCode: 4064,
		schoolFullName: "Macquarie Fields Public School",
	},
	{
		schoolCode: 4065,
		schoolFullName: "Villawood North Public School",
	},
	{
		schoolCode: 4066,
		schoolFullName: "Windsor South Public School",
	},
	{
		schoolCode: 4067,
		schoolFullName: "Matraville Soldiers Settlement PS ",
	},
	{
		schoolCode: 4071,
		schoolFullName: "Oatlands Public School",
	},
	{
		schoolCode: 4075,
		schoolFullName: "Kirrawee Public School",
	},
	{
		schoolCode: 4077,
		schoolFullName: "Illawong Public School",
	},
	{
		schoolCode: 4083,
		schoolFullName: "Kemblawarra Public School",
	},
	{
		schoolCode: 4084,
		schoolFullName: "Lilli Pilli Public School",
	},
	{
		schoolCode: 4085,
		schoolFullName: "Lismore Heights Public School",
	},
	{
		schoolCode: 4088,
		schoolFullName: "Padstow North Public School",
	},
	{
		schoolCode: 4089,
		schoolFullName: "Middleton Public School",
	},
	{
		schoolCode: 4092,
		schoolFullName: "Hannans Road Public School",
	},
	{
		schoolCode: 4095,
		schoolFullName: "Mount Ousley Public School",
	},
	{
		schoolCode: 4097,
		schoolFullName: "Tuggerawong Public School",
	},
	{
		schoolCode: 4100,
		schoolFullName: "Forbes North Public School",
	},
	{
		schoolCode: 4101,
		schoolFullName: "Murwillumbah East Public School",
	},
	{
		schoolCode: 4103,
		schoolFullName: "Telopea Public School",
	},
	{
		schoolCode: 4106,
		schoolFullName: "Nowra Hill Public School",
	},
	{
		schoolCode: 4110,
		schoolFullName: "Central Mangrove Public School",
	},
	{
		schoolCode: 4111,
		schoolFullName: "Tweed Heads South Public School",
	},
	{
		schoolCode: 4112,
		schoolFullName: "Deniliquin South Public School",
	},
	{
		schoolCode: 4115,
		schoolFullName: "Mingoola Public School",
	},
	{
		schoolCode: 4116,
		schoolFullName: "Blacktown West Public School",
	},
	{
		schoolCode: 4117,
		schoolFullName: "Warnervale Public School",
	},
	{
		schoolCode: 4118,
		schoolFullName: "Moree East Public School",
	},
	{
		schoolCode: 4121,
		schoolFullName: "Albion Park Rail Public School",
	},
	{
		schoolCode: 4122,
		schoolFullName: "Miranda North Public School",
	},
	{
		schoolCode: 4123,
		schoolFullName: "Rydalmere East Public School",
	},
	{
		schoolCode: 4124,
		schoolFullName: "Yowie Bay Public School",
	},
	{
		schoolCode: 4125,
		schoolFullName: "Ermington West Public School",
	},
	{
		schoolCode: 4126,
		schoolFullName: "Campbelltown North Public School",
	},
	{
		schoolCode: 4127,
		schoolFullName: "Queanbeyan West Public School",
	},
	{
		schoolCode: 4129,
		schoolFullName: "Heaton Public School",
	},
	{
		schoolCode: 4131,
		schoolFullName: "Young North Public School",
	},
	{
		schoolCode: 4132,
		schoolFullName: "Arcadia Vale Public School",
	},
	{
		schoolCode: 4136,
		schoolFullName: "Garden Suburb Public School",
	},
	{
		schoolCode: 4138,
		schoolFullName: "Penrith South Public School",
	},
	{
		schoolCode: 4142,
		schoolFullName: "Bankstown West Public School",
	},
	{
		schoolCode: 4144,
		schoolFullName: "Turvey Park Public School",
	},
	{
		schoolCode: 4147,
		schoolFullName: "Erskineville Public School",
	},
	{
		schoolCode: 4148,
		schoolFullName: "Castlereagh Public School",
	},
	{
		schoolCode: 4149,
		schoolFullName: "North Haven Public School",
	},
	{
		schoolCode: 4151,
		schoolFullName: "Tower Street Public School",
	},
	{
		schoolCode: 4155,
		schoolFullName: "Edgeworth Heights Public School",
	},
	{
		schoolCode: 4157,
		schoolFullName: "Yates Avenue Public School",
	},
	{
		schoolCode: 4160,
		schoolFullName: "Kent Road Public School",
	},
	{
		schoolCode: 4161,
		schoolFullName: "Liverpool West Public School",
	},
	{
		schoolCode: 4162,
		schoolFullName: "Seven Hills West Public School",
	},
	{
		schoolCode: 4163,
		schoolFullName: "Elermore Vale Public School",
	},
	{
		schoolCode: 4164,
		schoolFullName: "Truscott Street Public School",
	},
	{
		schoolCode: 4165,
		schoolFullName: "Epping North Public School",
	},
	{
		schoolCode: 4166,
		schoolFullName: "Marayong Public School",
	},
	{
		schoolCode: 4168,
		schoolFullName: "Lavington East Public School",
	},
	{
		schoolCode: 4173,
		schoolFullName: "Kooringal Public School",
	},
	{
		schoolCode: 4177,
		schoolFullName: "Albert Park Public School",
	},
	{
		schoolCode: 4180,
		schoolFullName: "Berkeley West Public School",
	},
	{
		schoolCode: 4183,
		schoolFullName: "Budgewoi Public School",
	},
	{
		schoolCode: 4184,
		schoolFullName: "Lennox Head Public School",
	},
	{
		schoolCode: 4185,
		schoolFullName: "Valentine Public School",
	},
	{
		schoolCode: 4190,
		schoolFullName: "Black Springs Public School",
	},
	{
		schoolCode: 4191,
		schoolFullName: "Lalor Park Public School",
	},
	{
		schoolCode: 4192,
		schoolFullName: "Bert Oldfield Public School",
	},
	{
		schoolCode: 4193,
		schoolFullName: "Blacktown South Public School",
	},
	{
		schoolCode: 4194,
		schoolFullName: "Charlestown East Public School",
	},
	{
		schoolCode: 4195,
		schoolFullName: "Warrimoo Public School",
	},
	{
		schoolCode: 4196,
		schoolFullName: "Kempsey South Public School",
	},
	{
		schoolCode: 4197,
		schoolFullName: "Blacksmiths Public School",
	},
	{
		schoolCode: 4198,
		schoolFullName: "Casula Public School",
	},
	{
		schoolCode: 4199,
		schoolFullName: "Elanora Heights Public School",
	},
	{
		schoolCode: 4200,
		schoolFullName: "Allambie Heights Public School",
	},
	{
		schoolCode: 4203,
		schoolFullName: "Kotara South Public School",
	},
	{
		schoolCode: 4204,
		schoolFullName: "Parkes East Public School",
	},
	{
		schoolCode: 4205,
		schoolFullName: "St Marys North Public School",
	},
	{
		schoolCode: 4206,
		schoolFullName: "Biddabah Public School",
	},
	{
		schoolCode: 4207,
		schoolFullName: "Glendale East Public School",
	},
	{
		schoolCode: 4208,
		schoolFullName: "Fennell Bay Public School",
	},
	{
		schoolCode: 4209,
		schoolFullName: "Temora West Public School",
	},
	{
		schoolCode: 4210,
		schoolFullName: "Mount Pritchard East Public School",
	},
	{
		schoolCode: 4212,
		schoolFullName: "Quakers Hill East Public School",
	},
	{
		schoolCode: 4214,
		schoolFullName: "Narrandera East Infants School",
	},
	{
		schoolCode: 4215,
		schoolFullName: "Mount Austin Public School",
	},
	{
		schoolCode: 4217,
		schoolFullName: "Banksia Road Public School",
	},
	{
		schoolCode: 4218,
		schoolFullName: "Gosford East Public School",
	},
	{
		schoolCode: 4221,
		schoolFullName: "Loftus Public School",
	},
	{
		schoolCode: 4222,
		schoolFullName: "Darcy Road Public School",
	},
	{
		schoolCode: 4225,
		schoolFullName: "Tulloona Public School",
	},
	{
		schoolCode: 4228,
		schoolFullName: "Bullarah Public School",
	},
	{
		schoolCode: 4230,
		schoolFullName: "Laguna Street Public School",
	},
	{
		schoolCode: 4232,
		schoolFullName: "Morgan Street Public School",
	},
	{
		schoolCode: 4233,
		schoolFullName: "Railway Town Public School",
	},
	{
		schoolCode: 4241,
		schoolFullName: "Cromer Public School",
	},
	{
		schoolCode: 4246,
		schoolFullName: "Illaroo Road Public School",
	},
	{
		schoolCode: 4248,
		schoolFullName: "Vardys Road Public School",
	},
	{
		schoolCode: 4249,
		schoolFullName: "Wheeler Heights Public School",
	},
	{
		schoolCode: 4250,
		schoolFullName: "Randwick Public School",
	},
	{
		schoolCode: 4256,
		schoolFullName: "Nowra East Public School",
	},
	{
		schoolCode: 4257,
		schoolFullName: "Ryde East Public School",
	},
	{
		schoolCode: 4258,
		schoolFullName: "Lynwood Park Public School",
	},
	{
		schoolCode: 4259,
		schoolFullName: "Burraneer Bay Public School",
	},
	{
		schoolCode: 4262,
		schoolFullName: "Mallawa Public School",
	},
	{
		schoolCode: 4263,
		schoolFullName: "Tarro Public School",
	},
	{
		schoolCode: 4266,
		schoolFullName: "Lakelands Public School",
	},
	{
		schoolCode: 4268,
		schoolFullName: "Griffith East Public School",
	},
	{
		schoolCode: 4270,
		schoolFullName: "Khancoban Public School",
	},
	{
		schoolCode: 4274,
		schoolFullName: "Hornsby Heights Public School",
	},
	{
		schoolCode: 4275,
		schoolFullName: "Normanhurst West Public School",
	},
	{
		schoolCode: 4276,
		schoolFullName: "Thornleigh West Public School",
	},
	{
		schoolCode: 4277,
		schoolFullName: "Campbelltown East Public School",
	},
	{
		schoolCode: 4278,
		schoolFullName: "Marsden Road Public School",
	},
	{
		schoolCode: 4279,
		schoolFullName: "St Marys South Public School",
	},
	{
		schoolCode: 4281,
		schoolFullName: "Deniliquin North Public School",
	},
	{
		schoolCode: 4282,
		schoolFullName: "Marayong Heights Public School",
	},
	{
		schoolCode: 4283,
		schoolFullName: "West Pymble Public School",
	},
	{
		schoolCode: 4285,
		schoolFullName: "Bennett Road Public School",
	},
	{
		schoolCode: 4286,
		schoolFullName: "Camden South Public School",
	},
	{
		schoolCode: 4288,
		schoolFullName: "Holsworthy Public School",
	},
	{
		schoolCode: 4289,
		schoolFullName: "Lurnea Public School",
	},
	{
		schoolCode: 4290,
		schoolFullName: "Mount Druitt Public School",
	},
	{
		schoolCode: 4291,
		schoolFullName: "Balarang Public School",
	},
	{
		schoolCode: 4292,
		schoolFullName: "Engadine West Public School",
	},
	{
		schoolCode: 4293,
		schoolFullName: "St Ives North Public School",
	},
	{
		schoolCode: 4294,
		schoolFullName: "Roselea Public School",
	},
	{
		schoolCode: 4296,
		schoolFullName: "Woonona East Public School",
	},
	{
		schoolCode: 4298,
		schoolFullName: "Yarrawarrah Public School",
	},
	{
		schoolCode: 4299,
		schoolFullName: "Chifley Public School",
	},
	{
		schoolCode: 4305,
		schoolFullName: "Weilmoringle Public School",
	},
	{
		schoolCode: 4310,
		schoolFullName: "Walters Road Public School",
	},
	{
		schoolCode: 4311,
		schoolFullName: "Bardia Public School",
	},
	{
		schoolCode: 4312,
		schoolFullName: "Ringrose Public School",
	},
	{
		schoolCode: 4313,
		schoolFullName: "Blaxland East Public School",
	},
	{
		schoolCode: 4314,
		schoolFullName: "Harrington Street Public School",
	},
	{
		schoolCode: 4316,
		schoolFullName: "Mannering Park Public School",
	},
	{
		schoolCode: 4318,
		schoolFullName: "Hillsborough Public School",
	},
	{
		schoolCode: 4319,
		schoolFullName: "Sadleir Public School",
	},
	{
		schoolCode: 4320,
		schoolFullName: "Gwandalan Public School",
	},
	{
		schoolCode: 4323,
		schoolFullName: "Peakhurst South Public School",
	},
	{
		schoolCode: 4324,
		schoolFullName: "Charlestown South Public School",
	},
	{
		schoolCode: 4327,
		schoolFullName: "Marayong South Public School",
	},
	{
		schoolCode: 4328,
		schoolFullName: "Sherwood Grange Public School",
	},
	{
		schoolCode: 4329,
		schoolFullName: "Woy Woy South Public School",
	},
	{
		schoolCode: 4331,
		schoolFullName: "St Ives Public School",
	},
	{
		schoolCode: 4333,
		schoolFullName: "Warilla North Public School",
	},
	{
		schoolCode: 4336,
		schoolFullName: "Heckenberg Public School",
	},
	{
		schoolCode: 4337,
		schoolFullName: "Busby Public School",
	},
	{
		schoolCode: 4338,
		schoolFullName: "Miller Public School",
	},
	{
		schoolCode: 4339,
		schoolFullName: "Kingswood South Public School",
	},
	{
		schoolCode: 4342,
		schoolFullName: "Picnic Point Public School",
	},
	{
		schoolCode: 4344,
		schoolFullName: "Ashcroft Public School",
	},
	{
		schoolCode: 4346,
		schoolFullName: "Kingswood Park Public School",
	},
	{
		schoolCode: 4348,
		schoolFullName: "Coleambally Central School",
	},
	{
		schoolCode: 4354,
		schoolFullName: "Faulconbridge Public School",
	},
	{
		schoolCode: 4355,
		schoolFullName: "Smithfield West Public School",
	},
	{
		schoolCode: 4357,
		schoolFullName: "Pleasant Heights Public School",
	},
	{
		schoolCode: 4359,
		schoolFullName: "Bilgola Plateau Public School",
	},
	{
		schoolCode: 4360,
		schoolFullName: "Cartwright Public School",
	},
	{
		schoolCode: 4364,
		schoolFullName: "Toongabbie West Public School",
	},
	{
		schoolCode: 4365,
		schoolFullName: "Hornsby North Public School",
	},
	{
		schoolCode: 4366,
		schoolFullName: "Toongabbie East Public School",
	},
	{
		schoolCode: 4367,
		schoolFullName: "Caves Beach Public School",
	},
	{
		schoolCode: 4368,
		schoolFullName: "Wakehurst Public School",
	},
	{
		schoolCode: 4369,
		schoolFullName: "Lewisham Public School",
	},
	{
		schoolCode: 4370,
		schoolFullName: "Belair Public School",
	},
	{
		schoolCode: 4371,
		schoolFullName: "Queanbeyan East Public School",
	},
	{
		schoolCode: 4372,
		schoolFullName: "Irrawang Public School",
	},
	{
		schoolCode: 4374,
		schoolFullName: "Talbingo Public School",
	},
	{
		schoolCode: 4375,
		schoolFullName: "Ashmont Public School",
	},
	{
		schoolCode: 4377,
		schoolFullName: "Baulkham Hills North Public School",
	},
	{
		schoolCode: 4378,
		schoolFullName: "Whalan Public School",
	},
	{
		schoolCode: 4379,
		schoolFullName: "Floraville Public School",
	},
	{
		schoolCode: 4380,
		schoolFullName: "Calare Public School",
	},
	{
		schoolCode: 4381,
		schoolFullName: "Shelley Public School",
	},
	{
		schoolCode: 4382,
		schoolFullName: "Jasper Road Public School",
	},
	{
		schoolCode: 4383,
		schoolFullName: "E A Southee Public School",
	},
	{
		schoolCode: 4386,
		schoolFullName: "Killarney Heights Public School",
	},
	{
		schoolCode: 4389,
		schoolFullName: "Gymea North Public School",
	},
	{
		schoolCode: 4391,
		schoolFullName: "Lansvale East Public School",
	},
	{
		schoolCode: 4393,
		schoolFullName: "Carlingford West Public School",
	},
	{
		schoolCode: 4394,
		schoolFullName: "Queanbeyan South Public School",
	},
	{
		schoolCode: 4395,
		schoolFullName: "Mount Warrigal Public School",
	},
	{
		schoolCode: 4396,
		schoolFullName: "Busby West Public School",
	},
	{
		schoolCode: 4397,
		schoolFullName: "Wyoming Public School",
	},
	{
		schoolCode: 4398,
		schoolFullName: "Chertsey Primary School",
	},
	{
		schoolCode: 4399,
		schoolFullName: "Hayes Park Public School",
	},
	{
		schoolCode: 4400,
		schoolFullName: "St Ives Park Public School",
	},
	{
		schoolCode: 4401,
		schoolFullName: "Murray Farm Public School",
	},
	{
		schoolCode: 4402,
		schoolFullName: "Tregear Public School",
	},
	{
		schoolCode: 4403,
		schoolFullName: "Bradbury Public School",
	},
	{
		schoolCode: 4404,
		schoolFullName: "Narranga Public School",
	},
	{
		schoolCode: 4405,
		schoolFullName: "Beresford Road Public School",
	},
	{
		schoolCode: 4406,
		schoolFullName: "Bradfordville Public School",
	},
	{
		schoolCode: 4407,
		schoolFullName: "Hume Public School",
	},
	{
		schoolCode: 4408,
		schoolFullName: "Lethbridge Park Public School",
	},
	{
		schoolCode: 4409,
		schoolFullName: "Koonawarra Public School",
	},
	{
		schoolCode: 4410,
		schoolFullName: "Red Hill Public School",
	},
	{
		schoolCode: 4411,
		schoolFullName: "Westport Public School",
	},
	{
		schoolCode: 4413,
		schoolFullName: "Parkview Public School",
	},
	{
		schoolCode: 4416,
		schoolFullName: "Merewether Heights Public School",
	},
	{
		schoolCode: 4417,
		schoolFullName: "Winston Hills Public School",
	},
	{
		schoolCode: 4418,
		schoolFullName: "Edward Public School",
	},
	{
		schoolCode: 4420,
		schoolFullName: "Nuwarra Public School",
	},
	{
		schoolCode: 4421,
		schoolFullName: "Gillwinga Public School",
	},
	{
		schoolCode: 4422,
		schoolFullName: "Emerton Public School",
	},
	{
		schoolCode: 4423,
		schoolFullName: "Mount Brown Public School",
	},
	{
		schoolCode: 4424,
		schoolFullName: "Lapstone Public School",
	},
	{
		schoolCode: 4426,
		schoolFullName: "Killarney Vale Public School",
	},
	{
		schoolCode: 4427,
		schoolFullName: "Niagara Park Public School",
	},
	{
		schoolCode: 4428,
		schoolFullName: "Southern Cross Public School",
	},
	{
		schoolCode: 4431,
		schoolFullName: "Marton Public School",
	},
	{
		schoolCode: 4432,
		schoolFullName: "Mimosa Public School",
	},
	{
		schoolCode: 4433,
		schoolFullName: "Wideview Public School",
	},
	{
		schoolCode: 4435,
		schoolFullName: "Mount Riverview Public School",
	},
	{
		schoolCode: 4436,
		schoolFullName: "Winmalee Public School",
	},
	{
		schoolCode: 4437,
		schoolFullName: "Hillvue Public School",
	},
	{
		schoolCode: 4438,
		schoolFullName: "Wollondilly Public School",
	},
	{
		schoolCode: 4440,
		schoolFullName: "Cherrybrook Public School",
	},
	{
		schoolCode: 4441,
		schoolFullName: "Crestwood Public School",
	},
	{
		schoolCode: 4442,
		schoolFullName: "Emu Heights Public School",
	},
	{
		schoolCode: 4444,
		schoolFullName: "Metella Road Public School",
	},
	{
		schoolCode: 4446,
		schoolFullName: "Dawson Public School",
	},
	{
		schoolCode: 4447,
		schoolFullName: "Hebersham Public School",
	},
	{
		schoolCode: 4449,
		schoolFullName: "Madang Avenue Public School",
	},
	{
		schoolCode: 4450,
		schoolFullName: "Berinba Public School",
	},
	{
		schoolCode: 4452,
		schoolFullName: "Hobartville Public School",
	},
	{
		schoolCode: 4453,
		schoolFullName: "Blackett Public School",
	},
	{
		schoolCode: 4454,
		schoolFullName: "Marra Creek Public School",
	},
	{
		schoolCode: 4456,
		schoolFullName: "Franklin Public School",
	},
	{
		schoolCode: 4457,
		schoolFullName: "Sturt Public School",
	},
	{
		schoolCode: 4458,
		schoolFullName: "Briar Road Public School",
	},
	{
		schoolCode: 4459,
		schoolFullName: "Woodberry Public School",
	},
	{
		schoolCode: 4460,
		schoolFullName: "Curran Public School",
	},
	{
		schoolCode: 4461,
		schoolFullName: "Guise Public School",
	},
	{
		schoolCode: 4462,
		schoolFullName: "Figtree Heights Public School",
	},
	{
		schoolCode: 4463,
		schoolFullName: "Flinders Public School",
	},
	{
		schoolCode: 4464,
		schoolFullName: "Sanctuary Point Public School",
	},
	{
		schoolCode: 4466,
		schoolFullName: "Mawarra Public School",
	},
	{
		schoolCode: 4467,
		schoolFullName: "King Park Public School",
	},
	{
		schoolCode: 4468,
		schoolFullName: "Glenroy Public School",
	},
	{
		schoolCode: 4469,
		schoolFullName: "Jewells Primary School",
	},
	{
		schoolCode: 4470,
		schoolFullName: "Willmot Public School",
	},
	{
		schoolCode: 4471,
		schoolFullName: "Winston Heights Public School",
	},
	{
		schoolCode: 4473,
		schoolFullName: "Werrington Public School",
	},
	{
		schoolCode: 4474,
		schoolFullName: "Widemere Public School",
	},
	{
		schoolCode: 4475,
		schoolFullName: "Leonay Public School",
	},
	{
		schoolCode: 4476,
		schoolFullName: "Shalvey Public School",
	},
	{
		schoolCode: 4478,
		schoolFullName: "Newling Public School",
	},
	{
		schoolCode: 4479,
		schoolFullName: "John Warby Public School",
	},
	{
		schoolCode: 4480,
		schoolFullName: "Bidwill Public School",
	},
	{
		schoolCode: 4481,
		schoolFullName: "St Andrews Public School",
	},
	{
		schoolCode: 4482,
		schoolFullName: "York Public School",
	},
	{
		schoolCode: 4483,
		schoolFullName: "Grose View Public School",
	},
	{
		schoolCode: 4484,
		schoolFullName: "Cambridge Gardens Public School",
	},
	{
		schoolCode: 4485,
		schoolFullName: "Culburra Public School",
	},
	{
		schoolCode: 4486,
		schoolFullName: "Singleton Heights Public School",
	},
	{
		schoolCode: 4488,
		schoolFullName: "Kareela Public School",
	},
	{
		schoolCode: 4489,
		schoolFullName: "Ruse Public School",
	},
	{
		schoolCode: 4490,
		schoolFullName: "Campbellfield Public School",
	},
	{
		schoolCode: 4491,
		schoolFullName: "Sackville Street Public School",
	},
	{
		schoolCode: 4492,
		schoolFullName: "Woodland Road Public School",
	},
	{
		schoolCode: 4493,
		schoolFullName: "Noumea Public School",
	},
	{
		schoolCode: 4494,
		schoolFullName: "Hampden Park Public School",
	},
	{
		schoolCode: 4496,
		schoolFullName: "Dulwich Hill Public School",
	},
	{
		schoolCode: 4498,
		schoolFullName: "Minnamurra Public School",
	},
	{
		schoolCode: 4499,
		schoolFullName: "Newbridge Heights Public School",
	},
	{
		schoolCode: 4500,
		schoolFullName: "Nareena Hills Public School",
	},
	{
		schoolCode: 4501,
		schoolFullName: "Barrack Heights Public School",
	},
	{
		schoolCode: 4502,
		schoolFullName: "Petersham Public School",
	},
	{
		schoolCode: 4503,
		schoolFullName: "Tyalla Public School",
	},
	{
		schoolCode: 4504,
		schoolFullName: "Crawford Public School",
	},
	{
		schoolCode: 4505,
		schoolFullName: "Ambarvale Public School",
	},
	{
		schoolCode: 4506,
		schoolFullName: "Toormina Public School",
	},
	{
		schoolCode: 4507,
		schoolFullName: "Bonnet Bay Public School",
	},
	{
		schoolCode: 4508,
		schoolFullName: "Valley View Public School",
	},
	{
		schoolCode: 4509,
		schoolFullName: "Sarah Redfern Public School",
	},
	{
		schoolCode: 4510,
		schoolFullName: "Claymore Public School",
	},
	{
		schoolCode: 4514,
		schoolFullName: "King Street Public School",
	},
	{
		schoolCode: 4515,
		schoolFullName: "Kambora Public School",
	},
	{
		schoolCode: 4516,
		schoolFullName: "Eastlakes Public School",
	},
	{
		schoolCode: 4517,
		schoolFullName: "Gorokan Public School",
	},
	{
		schoolCode: 4518,
		schoolFullName: "The Grange Public School",
	},
	{
		schoolCode: 4520,
		schoolFullName: "Bateau Bay Public School",
	},
	{
		schoolCode: 4521,
		schoolFullName: "Metford Public School",
	},
	{
		schoolCode: 4522,
		schoolFullName: "Wamberal Public School",
	},
	{
		schoolCode: 4523,
		schoolFullName: "Springdale Heights Public School",
	},
	{
		schoolCode: 4524,
		schoolFullName: "Hastings Public School",
	},
	{
		schoolCode: 4525,
		schoolFullName: "Braddock Public School",
	},
	{
		schoolCode: 4526,
		schoolFullName: "Matthew Pearce Public School",
	},
	{
		schoolCode: 4527,
		schoolFullName: "Clairgate Public School",
	},
	{
		schoolCode: 4528,
		schoolFullName: "Kings Langley Public School",
	},
	{
		schoolCode: 4529,
		schoolFullName: "Glenwood Public School",
	},
	{
		schoolCode: 4531,
		schoolFullName: "St Clair Public School",
	},
	{
		schoolCode: 4533,
		schoolFullName: "Tuncurry Public School",
	},
	{
		schoolCode: 4534,
		schoolFullName: "Orama Public School",
	},
	{
		schoolCode: 4535,
		schoolFullName: "Blairmount Public School",
	},
	{
		schoolCode: 4536,
		schoolFullName: "Bangor Public School",
	},
	{
		schoolCode: 4537,
		schoolFullName: "Rosemeadow Public School",
	},
	{
		schoolCode: 4538,
		schoolFullName: "Bowen Public School",
	},
	{
		schoolCode: 4539,
		schoolFullName: "Thomas Acres Public School",
	},
	{
		schoolCode: 4541,
		schoolFullName: "Werrington County Public School",
	},
	{
		schoolCode: 4542,
		schoolFullName: "Chittaway Bay Public School",
	},
	{
		schoolCode: 4543,
		schoolFullName: "Wilkins Public School",
	},
	{
		schoolCode: 4544,
		schoolFullName: "Jamisontown Public School",
	},
	{
		schoolCode: 4545,
		schoolFullName: "Bonnyrigg Public School",
	},
	{
		schoolCode: 4546,
		schoolFullName: "Katoomba Public School",
	},
	{
		schoolCode: 4547,
		schoolFullName: "Tanilba Bay Public School",
	},
	{
		schoolCode: 4548,
		schoolFullName: "Copacabana Public School",
	},
	{
		schoolCode: 4549,
		schoolFullName: "Orana Heights Public School",
	},
	{
		schoolCode: 4551,
		schoolFullName: "Plunkett Street Public School",
	},
	{
		schoolCode: 4552,
		schoolFullName: "Manning Gardens Public School",
	},
	{
		schoolCode: 4553,
		schoolFullName: "Wiley Park Public School",
	},
	{
		schoolCode: 4554,
		schoolFullName: "Prairievale Public School",
	},
	{
		schoolCode: 4555,
		schoolFullName: "Cudgegong Valley Public School",
	},
	{
		schoolCode: 4556,
		schoolFullName: "Wytaliba Public School",
	},
	{
		schoolCode: 4557,
		schoolFullName: "Sandy Beach Public School",
	},
	{
		schoolCode: 4558,
		schoolFullName: "Eschol Park Public School",
	},
	{
		schoolCode: 4559,
		schoolFullName: "Blackwell Public School",
	},
	{
		schoolCode: 4560,
		schoolFullName: "Samuel Terry Public School",
	},
	{
		schoolCode: 4561,
		schoolFullName: "Rutherford Public School",
	},
	{
		schoolCode: 4562,
		schoolFullName: "Sunshine Bay Public School",
	},
	{
		schoolCode: 4563,
		schoolFullName: "William Stimson Public School",
	},
	{
		schoolCode: 4564,
		schoolFullName: "Wirreanda Public School",
	},
	{
		schoolCode: 4565,
		schoolFullName: "Clare Public School",
	},
	{
		schoolCode: 4566,
		schoolFullName: "Brooke Avenue Public School",
	},
	{
		schoolCode: 4567,
		schoolFullName: "Governor Philip King Public School",
	},
	{
		schoolCode: 4568,
		schoolFullName: "Ellison Public School",
	},
	{
		schoolCode: 4569,
		schoolFullName: "Robert Townson Public School",
	},
	{
		schoolCode: 4571,
		schoolFullName: "Banks Public School",
	},
	{
		schoolCode: 4572,
		schoolFullName: "John Purchase Public School",
	},
	{
		schoolCode: 4573,
		schoolFullName: "Tharawal Public School",
	},
	{
		schoolCode: 4574,
		schoolFullName: "Samuel Gilbert Public School",
	},
	{
		schoolCode: 4575,
		schoolFullName: "Wingham Brush Public School",
	},
	{
		schoolCode: 4576,
		schoolFullName: "William Dean Public School",
	},
	{
		schoolCode: 4577,
		schoolFullName: "Minchinbury Public School",
	},
	{
		schoolCode: 4578,
		schoolFullName: "Teven-Tintenbar Public School",
	},
	{
		schoolCode: 4579,
		schoolFullName: "James Erskine Public School",
	},
	{
		schoolCode: 4580,
		schoolFullName: "Kariong Public School",
	},
	{
		schoolCode: 4581,
		schoolFullName: "Frank Partridge VC Public School",
	},
	{
		schoolCode: 4583,
		schoolFullName: "Edensor Park Public School",
	},
	{
		schoolCode: 4585,
		schoolFullName: "Bligh Park Public School",
	},
	{
		schoolCode: 4586,
		schoolFullName: "Sydney Distance Education Primary School",
	},
	{
		schoolCode: 4587,
		schoolFullName: "Dubbo Schoolof Distance Education",
	},
	{
		schoolCode: 4588,
		schoolFullName: "Northlakes Public School",
	},
	{
		schoolCode: 4589,
		schoolFullName: "Shoal Bay Public School",
	},
	{
		schoolCode: 4590,
		schoolFullName: "Hambledon Public School",
	},
	{
		schoolCode: 4591,
		schoolFullName: "Glenmore Park Public School",
	},
	{
		schoolCode: 4592,
		schoolFullName: "Vincentia Public School",
	},
	{
		schoolCode: 4593,
		schoolFullName: "Kearns Public School",
	},
	{
		schoolCode: 4594,
		schoolFullName: "Bonnyrigg Heights Public School",
	},
	{
		schoolCode: 4597,
		schoolFullName: "Mount Annan Public School",
	},
	{
		schoolCode: 4598,
		schoolFullName: "Hassall Grove Public School",
	},
	{
		schoolCode: 4599,
		schoolFullName: "Barnier Public School",
	},
	{
		schoolCode: 4600,
		schoolFullName: "Maryland Public School",
	},
	{
		schoolCode: 4601,
		schoolFullName: "Aldavilla Public School",
	},
	{
		schoolCode: 4602,
		schoolFullName: "William Bayldon Public School",
	},
	{
		schoolCode: 4603,
		schoolFullName: "St Helens Park Public School",
	},
	{
		schoolCode: 4604,
		schoolFullName: "Oakhill Drive Public School",
	},
	{
		schoolCode: 4605,
		schoolFullName: "Alfords Point Public School",
	},
	{
		schoolCode: 4606,
		schoolFullName: "Mullaway Public School",
	},
	{
		schoolCode: 4607,
		schoolFullName: "Centaur Public School",
	},
	{
		schoolCode: 4608,
		schoolFullName: "Glendenning Public School",
	},
	{
		schoolCode: 4609,
		schoolFullName: "Grahamstown Public School",
	},
	{
		schoolCode: 4610,
		schoolFullName: "Mount Terry Public School",
	},
	{
		schoolCode: 4611,
		schoolFullName: "Hallidays Point Public School",
	},
	{
		schoolCode: 4612,
		schoolFullName: "Broulee Public School",
	},
	{
		schoolCode: 4613,
		schoolFullName: "Hinchinbrook Public School",
	},
	{
		schoolCode: 4614,
		schoolFullName: "Windsor Park Public School",
	},
	{
		schoolCode: 4615,
		schoolFullName: "Caddies Creek Public School",
	},
	{
		schoolCode: 4616,
		schoolFullName: "Buninyong Public School",
	},
	{
		schoolCode: 4617,
		schoolFullName: "Tacking Point Public School",
	},
	{
		schoolCode: 4618,
		schoolFullName: "North Nowra Public School",
	},
	{
		schoolCode: 4619,
		schoolFullName: "Henry Fulton Public School",
	},
	{
		schoolCode: 4620,
		schoolFullName: "Glendore Public School",
	},
	{
		schoolCode: 4621,
		schoolFullName: "Narellan Vale Public School",
	},
	{
		schoolCode: 4622,
		schoolFullName: "Claremont Meadows Public School",
	},
	{
		schoolCode: 4623,
		schoolFullName: "Blue Haven Public School",
	},
	{
		schoolCode: 4624,
		schoolFullName: "Greenway Park Public School",
	},
	{
		schoolCode: 4625,
		schoolFullName: "Currans Hill Public School",
	},
	{
		schoolCode: 4626,
		schoolFullName: "Callala Public School",
	},
	{
		schoolCode: 4627,
		schoolFullName: "Newington Public School",
	},
	{
		schoolCode: 4628,
		schoolFullName: "Harrington Park Public School",
	},
	{
		schoolCode: 4630,
		schoolFullName: "Beaumont Hills Public School",
	},
	{
		schoolCode: 4631,
		schoolFullName: "Surveyors Creek Public School",
	},
	{
		schoolCode: 4632,
		schoolFullName: "Bogangar Public School",
	},
	{
		schoolCode: 4633,
		schoolFullName: "Jerrabomberra Public School",
	},
	{
		schoolCode: 4634,
		schoolFullName: "Dalmeny Public School",
	},
	{
		schoolCode: 4635,
		schoolFullName: "Cecil Hills Public School",
	},
	{
		schoolCode: 4637,
		schoolFullName: "Woongarrah Public School",
	},
	{
		schoolCode: 4638,
		schoolFullName: "Ironbark Ridge Public School",
	},
	{
		schoolCode: 4639,
		schoolFullName: "Kellyville Ridge Public School",
	},
	{
		schoolCode: 4640,
		schoolFullName: "Sherwood Ridge Public School",
	},
	{
		schoolCode: 4641,
		schoolFullName: "Shell Cove Public School",
	},
	{
		schoolCode: 4642,
		schoolFullName: "Ashtonfield Public School",
	},
	{
		schoolCode: 4643,
		schoolFullName: "North East PS of Distance Education",
	},
	{
		schoolCode: 4644,
		schoolFullName: "Bourke-Walgett Schoolof Distance Ed ",
	},
	{
		schoolCode: 4645,
		schoolFullName: "Ropes Crossing Public School",
	},
	{
		schoolCode: 4646,
		schoolFullName: "Elderslie Public School",
	},
	{
		schoolCode: 4647,
		schoolFullName: "Middleton Grange Public School",
	},
	{
		schoolCode: 4648,
		schoolFullName: "John Palmer Public School",
	},
	{
		schoolCode: 4649,
		schoolFullName: "Tullimbar Public School",
	},
	{
		schoolCode: 4650,
		schoolFullName: "Wilton Public School",
	},
	{
		schoolCode: 4651,
		schoolFullName: "Oran Park Public School",
	},
	{
		schoolCode: 4652,
		schoolFullName: "Marie Bashir Public School",
	},
	{
		schoolCode: 4653,
		schoolFullName: "Riverbank Public School",
	},
	{
		schoolCode: 4654,
		schoolFullName: "Lake Cathie Public School",
	},
	{
		schoolCode: 4655,
		schoolFullName: "Victoria Avenue Public School",
	},
	{
		schoolCode: 4656,
		schoolFullName: "Anzac Park Public School",
	},
	{
		schoolCode: 4657,
		schoolFullName: "Spring Farm Public School",
	},
	{
		schoolCode: 4658,
		schoolFullName: "Lindfield Learning Village",
	},
	{
		schoolCode: 4659,
		schoolFullName: "Southern Cross Schoolof Distance Ed ",
	},
	{
		schoolCode: 4660,
		schoolFullName: "Bayanami Public School",
	},
	{
		schoolCode: 4661,
		schoolFullName: "Finigan Schoolof Distance Education ",
	},
	{
		schoolCode: 4662,
		schoolFullName: "Wentworth Point Public School",
	},
	{
		schoolCode: 4663,
		schoolFullName: "Bella Vista Public School",
	},
	{
		schoolCode: 4664,
		schoolFullName: "North Kellyville Public School",
	},
	{
		schoolCode: 4667,
		schoolFullName: "Gledswood Hills Public School",
	},
	{
		schoolCode: 4668,
		schoolFullName: "Smalls Road Public School",
	},
	{
		schoolCode: 4669,
		schoolFullName: "Jordan Springs Public School",
	},
	{
		schoolCode: 4670,
		schoolFullName: "Galungara Public School",
	},
	{
		schoolCode: 4671,
		schoolFullName: "Barramurra Public School",
	},
	{
		schoolCode: 4672,
		schoolFullName: "Denham Court Public School",
	},
	{
		schoolCode: 4673,
		schoolFullName: "Northbourne Public School",
	},
	{
		schoolCode: 4674,
		schoolFullName: "Estella Public School",
	},
	{
		schoolCode: 5103,
		schoolFullName: "Arncliffe West Infants School",
	},
	{
		schoolCode: 5104,
		schoolFullName: "Australia Street Infants School",
	},
	{
		schoolCode: 5109,
		schoolFullName: "Canterbury South Public School",
	},
	{
		schoolCode: 5112,
		schoolFullName: "Taverners Hill Infants School",
	},
	{
		schoolCode: 5114,
		schoolFullName: "Ferncourt Public School",
	},
	{
		schoolCode: 5116,
		schoolFullName: "Glen Innes West Infants School",
	},
	{
		schoolCode: 5118,
		schoolFullName: "Harcourt Public School",
	},
	{
		schoolCode: 5120,
		schoolFullName: "Yeo Park Infants School",
	},
	{
		schoolCode: 5126,
		schoolFullName: "Merrylands East Public School",
	},
	{
		schoolCode: 5128,
		schoolFullName: "Nillo Infants School",
	},
	{
		schoolCode: 5132,
		schoolFullName: "Russell Lea Public School",
	},
	{
		schoolCode: 5133,
		schoolFullName: "Stanford Merthyr Infants School",
	},
	{
		schoolCode: 5141,
		schoolFullName: "Kyeemagh Public School",
	},
	{
		schoolCode: 5148,
		schoolFullName: "Bardwell Park Infants School",
	},
	{
		schoolCode: 5166,
		schoolFullName: "Casino West Public School",
	},
	{
		schoolCode: 5188,
		schoolFullName: "Sefton Infants School",
	},
	{
		schoolCode: 5191,
		schoolFullName: "Hornsby South Public School",
	},
	{
		schoolCode: 5195,
		schoolFullName: "Hurstville Grove Infants School",
	},
	{
		schoolCode: 5212,
		schoolFullName: "Beauty Point Public School",
	},
	{
		schoolCode: 5220,
		schoolFullName: "Lansvale Public School",
	},
	{
		schoolCode: 5236,
		schoolFullName: "Granville East Public School",
	},
	{
		schoolCode: 5238,
		schoolFullName: "Westlawn Public School",
	},
	{
		schoolCode: 5241,
		schoolFullName: "Mount Lewis Infants School",
	},
	{
		schoolCode: 5242,
		schoolFullName: "Mulyan Public School",
	},
	{
		schoolCode: 5246,
		schoolFullName: "Bankstown South Infants School",
	},
	{
		schoolCode: 5247,
		schoolFullName: "New Lambton Heights Infants School",
	},
	{
		schoolCode: 5251,
		schoolFullName: "Homebush West Public School",
	},
	{
		schoolCode: 5260,
		schoolFullName: "Cardiff North Public School",
	},
	{
		schoolCode: 5265,
		schoolFullName: "Bletchington Public School",
	},
	{
		schoolCode: 5272,
		schoolFullName: "Heathcote East Public School",
	},
	{
		schoolCode: 5302,
		schoolFullName: "Schoolof the Air Broken Hill",
	},
	{
		schoolCode: 5401,
		schoolFullName: "Glenfield Park School",
	},
	{
		schoolCode: 5405,
		schoolFullName: "Fisher Road School",
	},
	{
		schoolCode: 5407,
		schoolFullName: "Cromehurst School",
	},
	{
		schoolCode: 5411,
		schoolFullName: "Rowland Hassall School",
	},
	{
		schoolCode: 5423,
		schoolFullName: "John Brotchie Nursery School",
	},
	{
		schoolCode: 5428,
		schoolFullName: "Field of Mars Environmental Ed Centre",
	},
	{
		schoolCode: 5429,
		schoolFullName: "Camden Park Environmental Ed Centre",
	},
	{
		schoolCode: 5430,
		schoolFullName: "Georges River Environmental Ed Centre",
	},
	{
		schoolCode: 5453,
		schoolFullName: "Putland School",
	},
	{
		schoolCode: 5454,
		schoolFullName: "Dorchester School",
	},
	{
		schoolCode: 5456,
		schoolFullName: "Girrakool School",
	},
	{
		schoolCode: 5458,
		schoolFullName: "Wetlands Environmental Education Centre ",
	},
	{
		schoolCode: 5459,
		schoolFullName: "Tallowood School",
	},
	{
		schoolCode: 5460,
		schoolFullName: "Yudi Gunyi School",
	},
	{
		schoolCode: 5504,
		schoolFullName: "Royal Far West School",
	},
	{
		schoolCode: 5508,
		schoolFullName: "Beverley Park School",
	},
	{
		schoolCode: 5511,
		schoolFullName: "Newcastle Senior School",
	},
	{
		schoolCode: 5513,
		schoolFullName: "St George Hospital School",
	},
	{
		schoolCode: 5515,
		schoolFullName: "Stewart House School",
	},
	{
		schoolCode: 5516,
		schoolFullName: "Centennial Park School",
	},
	{
		schoolCode: 5527,
		schoolFullName: "St George School",
	},
	{
		schoolCode: 5529,
		schoolFullName: "Broderick Gillawarna School",
	},
	{
		schoolCode: 5538,
		schoolFullName: "Arndell School",
	},
	{
		schoolCode: 5540,
		schoolFullName: "Bridge Road School",
	},
	{
		schoolCode: 5543,
		schoolFullName: "Royal Prince Alfred Hospital School",
	},
	{
		schoolCode: 5544,
		schoolFullName: "Royal North Shore Hospital School",
	},
	{
		schoolCode: 5545,
		schoolFullName: "Sydney Childrens Hospital School",
	},
	{
		schoolCode: 5546,
		schoolFullName: "Sutherland Hospital School",
	},
	{
		schoolCode: 5548,
		schoolFullName: "Bankstown Hospital School",
	},
	{
		schoolCode: 5550,
		schoolFullName: "Willans Hill School",
	},
	{
		schoolCode: 5551,
		schoolFullName: "Illawarra Hospital School",
	},
	{
		schoolCode: 5556,
		schoolFullName: "Liverpool Hospital School",
	},
	{
		schoolCode: 5558,
		schoolFullName: "John Hunter Hospital School",
	},
	{
		schoolCode: 5559,
		schoolFullName: "Redbank School",
	},
	{
		schoolCode: 5562,
		schoolFullName: "Fowler Road School",
	},
	{
		schoolCode: 5563,
		schoolFullName: "Passfield Park School",
	},
	{
		schoolCode: 5568,
		schoolFullName: "Wangee Park School",
	},
	{
		schoolCode: 5569,
		schoolFullName: "Bullimbal School",
	},
	{
		schoolCode: 5573,
		schoolFullName: "Caldera School",
	},
	{
		schoolCode: 5574,
		schoolFullName: "Casuarina School",
	},
	{
		schoolCode: 5575,
		schoolFullName: "Cook School",
	},
	{
		schoolCode: 5576,
		schoolFullName: "Highlands School",
	},
	{
		schoolCode: 5577,
		schoolFullName: "Mian School",
	},
	{
		schoolCode: 5578,
		schoolFullName: "Naremburn School",
	},
	{
		schoolCode: 5579,
		schoolFullName: "The Beach School",
	},
	{
		schoolCode: 5580,
		schoolFullName: "Parry School",
	},
	{
		schoolCode: 5581,
		schoolFullName: "Karningul School",
	},
	{
		schoolCode: 5582,
		schoolFullName: "Wakefield School",
	},
	{
		schoolCode: 5583,
		schoolFullName: "Verona School",
	},
	{
		schoolCode: 5606,
		schoolFullName: "Cairnsfoot School",
	},
	{
		schoolCode: 5609,
		schoolFullName: "Woniora Road School",
	},
	{
		schoolCode: 5610,
		schoolFullName: "Carenne School",
	},
	{
		schoolCode: 5614,
		schoolFullName: "William Rose School",
	},
	{
		schoolCode: 5616,
		schoolFullName: "Minerva School",
	},
	{
		schoolCode: 5621,
		schoolFullName: "Kurrambee School",
	},
	{
		schoolCode: 5625,
		schoolFullName: "Anson Street School",
	},
	{
		schoolCode: 5627,
		schoolFullName: "Glenvale School",
	},
	{
		schoolCode: 5630,
		schoolFullName: "Mainsbridge School",
	},
	{
		schoolCode: 5631,
		schoolFullName: "Wairoa School",
	},
	{
		schoolCode: 5632,
		schoolFullName: "Lawrence Hargrave School",
	},
	{
		schoolCode: 5633,
		schoolFullName: "Holroyd School",
	},
	{
		schoolCode: 5634,
		schoolFullName: "The Crescent School",
	},
	{
		schoolCode: 5637,
		schoolFullName: "Coreen School",
	},
	{
		schoolCode: 5642,
		schoolFullName: "Halinda School",
	},
	{
		schoolCode: 5643,
		schoolFullName: "Niland School",
	},
	{
		schoolCode: 5644,
		schoolFullName: "Para Meadows School",
	},
	{
		schoolCode: 5647,
		schoolFullName: "Bates Drive School",
	},
	{
		schoolCode: 5650,
		schoolFullName: "Palm Avenue School",
	},
	{
		schoolCode: 5652,
		schoolFullName: "Caroline Chisholm School",
	},
	{
		schoolCode: 5653,
		schoolFullName: "Wewak Street School",
	},
	{
		schoolCode: 5654,
		schoolFullName: "Rivendell School",
	},
	{
		schoolCode: 5655,
		schoolFullName: "Clarke Road School",
	},
	{
		schoolCode: 5656,
		schoolFullName: "Arranounbai School",
	},
	{
		schoolCode: 5666,
		schoolFullName: "Peterborough School",
	},
	{
		schoolCode: 5667,
		schoolFullName: "Wooglemai Environmental Education Centre",
	},
	{
		schoolCode: 5670,
		schoolFullName: "Karonga School",
	},
	{
		schoolCode: 5674,
		schoolFullName: "Snowy Valleys School",
	},
	{
		schoolCode: 5675,
		schoolFullName: "Sir Eric Woodward Memorial School",
	},
	{
		schoolCode: 5679,
		schoolFullName: "Bournda Environmental Education Centre",
	},
	{
		schoolCode: 5680,
		schoolFullName: "Thalgarrah Environmental Ed Centre",
	},
	{
		schoolCode: 5681,
		schoolFullName: "Wambangalang Environmental Ed Centre ",
	},
	{
		schoolCode: 5683,
		schoolFullName: "Dorroughby Environmental Ed Centre",
	},
	{
		schoolCode: 5684,
		schoolFullName: "Tangara School",
	},
	{
		schoolCode: 5685,
		schoolFullName: "G S Kidd Memorial School",
	},
	{
		schoolCode: 5686,
		schoolFullName: "Lakeside School",
	},
	{
		schoolCode: 5687,
		schoolFullName: "Hunter River Community School",
	},
	{
		schoolCode: 5688,
		schoolFullName: "The Hills School",
	},
	{
		schoolCode: 5689,
		schoolFullName: "Wilson Park School",
	},
	{
		schoolCode: 5690,
		schoolFullName: "Longneck Lagoon Environmental Ed Centre ",
	},
	{
		schoolCode: 5691,
		schoolFullName: "Royal National Park Environmental Ed Ctr",
	},
	{
		schoolCode: 5694,
		schoolFullName: "Brewongle Environmental Education Centre",
	},
	{
		schoolCode: 5696,
		schoolFullName: "Sandon Public School",
	},
	{
		schoolCode: 5698,
		schoolFullName: "Havenlee School",
	},
	{
		schoolCode: 5699,
		schoolFullName: "Kalinda School",
	},
	{
		schoolCode: 5701,
		schoolFullName: "Newcastle Middle School",
	},
	{
		schoolCode: 5703,
		schoolFullName: "Carinya School",
	},
	{
		schoolCode: 5704,
		schoolFullName: "Newcastle Junior School",
	},
	{
		schoolCode: 5711,
		schoolFullName: "Shepherds Park School",
	},
	{
		schoolCode: 5712,
		schoolFullName: "Les Powell School",
	},
	{
		schoolCode: 5713,
		schoolFullName: "Lucas Gardens School",
	},
	{
		schoolCode: 5714,
		schoolFullName: "Gibberagong Environmental Ed Centre",
	},
	{
		schoolCode: 5721,
		schoolFullName: "Mary Brooksbank School",
	},
	{
		schoolCode: 5723,
		schoolFullName: "Five Islands School",
	},
	{
		schoolCode: 5730,
		schoolFullName: "Gralee School",
	},
	{
		schoolCode: 5731,
		schoolFullName: "Cascade Environmental Education Centre",
	},
	{
		schoolCode: 5732,
		schoolFullName: "Warrumbungle NP Environmental Ed Centre ",
	},
	{
		schoolCode: 5734,
		schoolFullName: "Riverina Environmental Education Centre ",
	},
	{
		schoolCode: 5735,
		schoolFullName: "Campbell House School",
	},
	{
		schoolCode: 5736,
		schoolFullName: "Holman Place School",
	},
	{
		schoolCode: 5737,
		schoolFullName: "Edgeware School",
	},
	{
		schoolCode: 5738,
		schoolFullName: "Kotara School",
	},
	{
		schoolCode: 5740,
		schoolFullName: "Rumbalara Environmental Education Centre",
	},
	{
		schoolCode: 5741,
		schoolFullName: "Kamay Botany Bay Environmental Ed Centre",
	},
	{
		schoolCode: 5743,
		schoolFullName: "Illawarra Environmental Education Centre",
	},
	{
		schoolCode: 5744,
		schoolFullName: "Kandeer School",
	},
	{
		schoolCode: 5745,
		schoolFullName: "Observatory Hill Environmental Ed Centre",
	},
	{
		schoolCode: 5748,
		schoolFullName: "Ajuga School",
	},
	{
		schoolCode: 5749,
		schoolFullName: "Budawang School",
	},
	{
		schoolCode: 5750,
		schoolFullName: "Red Hill Environmental Education Centre ",
	},
	{
		schoolCode: 5751,
		schoolFullName: "The Childrens Hospital School",
	},
	{
		schoolCode: 5752,
		schoolFullName: "Penrith Lakes Environmental Ed Centre",
	},
	{
		schoolCode: 5753,
		schoolFullName: "Lomandra School",
	},
	{
		schoolCode: 5754,
		schoolFullName: "Plumpton House School",
	},
	{
		schoolCode: 5755,
		schoolFullName: "Hopetown School",
	},
	{
		schoolCode: 5756,
		schoolFullName: "Induna School",
	},
	{
		schoolCode: 5757,
		schoolFullName: "Lincoln School",
	},
	{
		schoolCode: 5758,
		schoolFullName: "The Bidgee School",
	},
	{
		schoolCode: 5759,
		schoolFullName: "Canterbury Vale School",
	},
	{
		schoolCode: 5760,
		schoolFullName: "North Gosford Learning Centre",
	},
	{
		schoolCode: 5761,
		schoolFullName: "Penrith Valley Learning Centre ",
	},
	{
		schoolCode: 5762,
		schoolFullName: "Barwon Learning Centre",
	},
	{
		schoolCode: 5763,
		schoolFullName: "Coffs Harbour Learning Centre",
	},
	{
		schoolCode: 5764,
		schoolFullName: "Woodberry Learning Centre",
	},
	{
		schoolCode: 5765,
		schoolFullName: "The Ponds School",
	},
	{
		schoolCode: 5766,
		schoolFullName: "Yandelora Public School",
	},
	{
		schoolCode: 5767,
		schoolFullName: "George Bass School",
	},
	{
		schoolCode: 5768,
		schoolFullName: "Fernhill School",
	},
	{
		schoolCode: 5769,
		schoolFullName: "Yawarra Community School",
	},
	{
		schoolCode: 5770,
		schoolFullName: "Tirriwirri School",
	},
	{
		schoolCode: 5771,
		schoolFullName: "South Creek School",
	},
	{
		schoolCode: 7301,
		schoolFullName: "Canowindra Public School",
	},
	{
		schoolCode: 7302,
		schoolFullName: "Carlingford Public School",
	},
	{
		schoolCode: 7303,
		schoolFullName: "Gloucester Public School",
	},
	{
		schoolCode: 7305,
		schoolFullName: "Manilla Central School",
	},
	{
		schoolCode: 7307,
		schoolFullName: "Orange Public School",
	},
	{
		schoolCode: 7308,
		schoolFullName: "Plattsburg Public School",
	},
	{
		schoolCode: 7311,
		schoolFullName: "Scone Public School",
	},
	{
		schoolCode: 7314,
		schoolFullName: "Wingham Public School",
	},
	{
		schoolCode: 7316,
		schoolFullName: "Dorrigo Public School",
	},
	{
		schoolCode: 7401,
		schoolFullName: "Arncliffe Public School",
	},
	{
		schoolCode: 7403,
		schoolFullName: "Auburn Public School",
	},
	{
		schoolCode: 7404,
		schoolFullName: "Balmain Public School",
	},
	{
		schoolCode: 7405,
		schoolFullName: "Bankstown Public School",
	},
	{
		schoolCode: 7406,
		schoolFullName: "Bexley Public School",
	},
	{
		schoolCode: 7407,
		schoolFullName: "Bondi Public School",
	},
	{
		schoolCode: 7408,
		schoolFullName: "Bourke Street Public School",
	},
	{
		schoolCode: 7409,
		schoolFullName: "Chatswood Public School",
	},
	{
		schoolCode: 7411,
		schoolFullName: "Croydon Public School",
	},
	{
		schoolCode: 7412,
		schoolFullName: "Croydon Park Public School",
	},
	{
		schoolCode: 7413,
		schoolFullName: "Daceyville Public School",
	},
	{
		schoolCode: 7414,
		schoolFullName: "Darlinghurst Public School",
	},
	{
		schoolCode: 7416,
		schoolFullName: "Eastwood Public School",
	},
	{
		schoolCode: 7418,
		schoolFullName: "Gardeners Road Public School",
	},
	{
		schoolCode: 7419,
		schoolFullName: "Glebe Public School",
	},
	{
		schoolCode: 7420,
		schoolFullName: "Hamilton Public School",
	},
	{
		schoolCode: 7422,
		schoolFullName: "Kurri Kurri Public School",
	},
	{
		schoolCode: 7423,
		schoolFullName: "Leichhardt Public School",
	},
	{
		schoolCode: 7424,
		schoolFullName: "Liverpool Public School",
	},
	{
		schoolCode: 7427,
		schoolFullName: "Maroubra Junction Public School",
	},
	{
		schoolCode: 7430,
		schoolFullName: "Mosman Public School",
	},
	{
		schoolCode: 7431,
		schoolFullName: "Newtown Public School",
	},
	{
		schoolCode: 7432,
		schoolFullName: "Paddington Public School",
	},
	{
		schoolCode: 7435,
		schoolFullName: "Rozelle Public School",
	},
	{
		schoolCode: 7436,
		schoolFullName: "Stanmore Public School",
	},
	{
		schoolCode: 7442,
		schoolFullName: "Woollahra Public School",
	},
	{
		schoolCode: 7444,
		schoolFullName: "Rosehill Public School",
	},
	{
		schoolCode: 7445,
		schoolFullName: "Woy Woy Public School",
	},
	{
		schoolCode: 8101,
		schoolFullName: "Albury High School",
	},
	{
		schoolCode: 8103,
		schoolFullName: "Denison College of Sec Ed Bathurst High ",
	},
	{
		schoolCode: 8104,
		schoolFullName: "Bowral High School",
	},
	{
		schoolCode: 8105,
		schoolFullName: "Broken Hill High School",
	},
	{
		schoolCode: 8107,
		schoolFullName: "Canterbury Boys High School",
	},
	{
		schoolCode: 8108,
		schoolFullName: "Cessnock High School",
	},
	{
		schoolCode: 8109,
		schoolFullName: "Coffs Harbour High School",
	},
	{
		schoolCode: 8110,
		schoolFullName: "Dubbo College Senior",
	},
	{
		schoolCode: 8111,
		schoolFullName: "Farrer Memorial Agricultural High School",
	},
	{
		schoolCode: 8114,
		schoolFullName: "Glen Innes High School",
	},
	{
		schoolCode: 8115,
		schoolFullName: "Gosford High School",
	},
	{
		schoolCode: 8116,
		schoolFullName: "Goulburn High School",
	},
	{
		schoolCode: 8117,
		schoolFullName: "Grafton High School",
	},
	{
		schoolCode: 8119,
		schoolFullName: "Hay War Memorial High School",
	},
	{
		schoolCode: 8120,
		schoolFullName: "Hornsby Girls High School",
	},
	{
		schoolCode: 8121,
		schoolFullName: "Hurlstone Agricultural High School",
	},
	{
		schoolCode: 8122,
		schoolFullName: "Inverell High School",
	},
	{
		schoolCode: 8123,
		schoolFullName: "Kempsey High School",
	},
	{
		schoolCode: 8124,
		schoolFullName: "The Rivers Sec C Lismore High Campus ",
	},
	{
		schoolCode: 8125,
		schoolFullName: "Lithgow High School",
	},
	{
		schoolCode: 8126,
		schoolFullName: "Maitland High School",
	},
	{
		schoolCode: 8127,
		schoolFullName: "Maitland Grossmann High School",
	},
	{
		schoolCode: 8128,
		schoolFullName: "Mudgee High School",
	},
	{
		schoolCode: 8129,
		schoolFullName: "Murwillumbah High School",
	},
	{
		schoolCode: 8132,
		schoolFullName: "North Sydney Boys High School",
	},
	{
		schoolCode: 8133,
		schoolFullName: "North Sydney Girls High School",
	},
	{
		schoolCode: 8134,
		schoolFullName: "Orange High School",
	},
	{
		schoolCode: 8135,
		schoolFullName: "Parramatta High School",
	},
	{
		schoolCode: 8136,
		schoolFullName: "St George Girls High School",
	},
	{
		schoolCode: 8137,
		schoolFullName: "Sydney Boys High School",
	},
	{
		schoolCode: 8138,
		schoolFullName: "Sydney Girls High School",
	},
	{
		schoolCode: 8139,
		schoolFullName: "Tamworth High School",
	},
	{
		schoolCode: 8140,
		schoolFullName: "Taree High School",
	},
	{
		schoolCode: 8141,
		schoolFullName: "Sydney Technical High School",
	},
	{
		schoolCode: 8142,
		schoolFullName: "Wagga Wagga High School",
	},
	{
		schoolCode: 8143,
		schoolFullName: "Wollongong High Schoolof the Perf Arts ",
	},
	{
		schoolCode: 8144,
		schoolFullName: "Yanco Agricultural High School",
	},
	{
		schoolCode: 8146,
		schoolFullName: "Parkes High School",
	},
	{
		schoolCode: 8147,
		schoolFullName: "Conservatorium High School",
	},
	{
		schoolCode: 8148,
		schoolFullName: "Cowra High School",
	},
	{
		schoolCode: 8150,
		schoolFullName: "Katoomba High School",
	},
	{
		schoolCode: 8151,
		schoolFullName: "Homebush Boys High School",
	},
	{
		schoolCode: 8152,
		schoolFullName: "Burwood Girls High School",
	},
	{
		schoolCode: 8154,
		schoolFullName: "Casino High School",
	},
	{
		schoolCode: 8155,
		schoolFullName: "Young High School",
	},
	{
		schoolCode: 8156,
		schoolFullName: "Leeton High School",
	},
	{
		schoolCode: 8157,
		schoolFullName: "Narrandera High School",
	},
	{
		schoolCode: 8159,
		schoolFullName: "Willoughby Girls High School",
	},
	{
		schoolCode: 8161,
		schoolFullName: "Randwick Boys High School",
	},
	{
		schoolCode: 8162,
		schoolFullName: "Macksville High School",
	},
	{
		schoolCode: 8163,
		schoolFullName: "Penrith High School",
	},
	{
		schoolCode: 8164,
		schoolFullName: "Muswellbrook High School",
	},
	{
		schoolCode: 8165,
		schoolFullName: "Bega High School",
	},
	{
		schoolCode: 8166,
		schoolFullName: "Wyong Technology High School",
	},
	{
		schoolCode: 8168,
		schoolFullName: "Temora High School",
	},
	{
		schoolCode: 8169,
		schoolFullName: "Strathfield Girls High School",
	},
	{
		schoolCode: 8170,
		schoolFullName: "Narrabri High School",
	},
	{
		schoolCode: 8171,
		schoolFullName: "Kiama High School",
	},
	{
		schoolCode: 8173,
		schoolFullName: "Wauchope High School",
	},
	{
		schoolCode: 8174,
		schoolFullName: "Quirindi High School",
	},
	{
		schoolCode: 8176,
		schoolFullName: "Liverpool Girls High School",
	},
	{
		schoolCode: 8177,
		schoolFullName: "Kurri Kurri High School",
	},
	{
		schoolCode: 8178,
		schoolFullName: "Richmond High School",
	},
	{
		schoolCode: 8179,
		schoolFullName: "Junee High School",
	},
	{
		schoolCode: 8180,
		schoolFullName: "Campbelltown Performing Arts High School",
	},
	{
		schoolCode: 8184,
		schoolFullName: "Punchbowl Boys High School",
	},
	{
		schoolCode: 8186,
		schoolFullName: "Liverpool Boys High School",
	},
	{
		schoolCode: 8187,
		schoolFullName: "East Hills Girls Technology High School",
	},
	{
		schoolCode: 8188,
		schoolFullName: "Gunnedah High School",
	},
	{
		schoolCode: 8189,
		schoolFullName: "Kyogle High School",
	},
	{
		schoolCode: 8190,
		schoolFullName: "Cootamundra High School",
	},
	{
		schoolCode: 8191,
		schoolFullName: "Corrimal High School",
	},
	{
		schoolCode: 8193,
		schoolFullName: "Mullumbimby High School",
	},
	{
		schoolCode: 8194,
		schoolFullName: "Forbes High School",
	},
	{
		schoolCode: 8195,
		schoolFullName: "Ballina Coast High School",
	},
	{
		schoolCode: 8196,
		schoolFullName: "Monaro High School",
	},
	{
		schoolCode: 8197,
		schoolFullName: "Wellington High School",
	},
	{
		schoolCode: 8201,
		schoolFullName: "Nowra High School",
	},
	{
		schoolCode: 8202,
		schoolFullName: "Singleton High School",
	},
	{
		schoolCode: 8203,
		schoolFullName: "Belmont High School",
	},
	{
		schoolCode: 8204,
		schoolFullName: "Dapto High School",
	},
	{
		schoolCode: 8205,
		schoolFullName: "Illawarra Sports High School",
	},
	{
		schoolCode: 8206,
		schoolFullName: "East Hills Boys High School",
	},
	{
		schoolCode: 8207,
		schoolFullName: "Hunters Hill High School",
	},
	{
		schoolCode: 8208,
		schoolFullName: "Cheltenham Girls High School",
	},
	{
		schoolCode: 8209,
		schoolFullName: "Cabramatta High School",
	},
	{
		schoolCode: 8210,
		schoolFullName: "Lake Macquarie High School",
	},
	{
		schoolCode: 8211,
		schoolFullName: "Normanhurst Boys High School",
	},
	{
		schoolCode: 8213,
		schoolFullName: "Birrong Boys High School",
	},
	{
		schoolCode: 8214,
		schoolFullName: "The Rivers Sec C Richmnd Riv High Campus",
	},
	{
		schoolCode: 8215,
		schoolFullName: "Picton High School",
	},
	{
		schoolCode: 8216,
		schoolFullName: "Queanbeyan High School",
	},
	{
		schoolCode: 8217,
		schoolFullName: "Camden High School",
	},
	{
		schoolCode: 8218,
		schoolFullName: "Deniliquin High School",
	},
	{
		schoolCode: 8219,
		schoolFullName: "Hunter River High School",
	},
	{
		schoolCode: 8220,
		schoolFullName: "Randwick Girls High School",
	},
	{
		schoolCode: 8221,
		schoolFullName: "Kingsgrove North High School",
	},
	{
		schoolCode: 8222,
		schoolFullName: "Asquith Girls High School",
	},
	{
		schoolCode: 8223,
		schoolFullName: "Bass High School",
	},
	{
		schoolCode: 8224,
		schoolFullName: "Blacktown Girls High School",
	},
	{
		schoolCode: 8225,
		schoolFullName: "Marsden High School",
	},
	{
		schoolCode: 8227,
		schoolFullName: "Merrylands High School",
	},
	{
		schoolCode: 8229,
		schoolFullName: "Northmead Creative & Performing Arts HS ",
	},
	{
		schoolCode: 8230,
		schoolFullName: "Port Hacking High School",
	},
	{
		schoolCode: 8231,
		schoolFullName: "Seven Hills High School",
	},
	{
		schoolCode: 8232,
		schoolFullName: "Chatswood High School",
	},
	{
		schoolCode: 8233,
		schoolFullName: "Hunter Sports High School",
	},
	{
		schoolCode: 8235,
		schoolFullName: "James Ruse Agricultural High School",
	},
	{
		schoolCode: 8236,
		schoolFullName: "Blakehurst High School",
	},
	{
		schoolCode: 8240,
		schoolFullName: "Caringbah High School",
	},
	{
		schoolCode: 8241,
		schoolFullName: "Moorefield Girls High School",
	},
	{
		schoolCode: 8243,
		schoolFullName: "Riverside Girls High School",
	},
	{
		schoolCode: 8244,
		schoolFullName: "Blacktown Boys High School",
	},
	{
		schoolCode: 8245,
		schoolFullName: "Asquith Boys High School",
	},
	{
		schoolCode: 8246,
		schoolFullName: "Heathcote High School",
	},
	{
		schoolCode: 8247,
		schoolFullName: "Kingsgrove High School",
	},
	{
		schoolCode: 8249,
		schoolFullName: "Matraville Sports High School",
	},
	{
		schoolCode: 8250,
		schoolFullName: "Sir Joseph Banks High School",
	},
	{
		schoolCode: 8252,
		schoolFullName: "Strathfield South High School",
	},
	{
		schoolCode: 8253,
		schoolFullName: "Auburn Girls High School",
	},
	{
		schoolCode: 8254,
		schoolFullName: "Bankstown Girls High School",
	},
	{
		schoolCode: 8255,
		schoolFullName: "Beverly Hills Girls High School",
	},
	{
		schoolCode: 8258,
		schoolFullName: "Bellingen High School",
	},
	{
		schoolCode: 8259,
		schoolFullName: "Callaghan College Wallsend",
	},
	{
		schoolCode: 8260,
		schoolFullName: "Bonnyrigg High School",
	},
	{
		schoolCode: 8261,
		schoolFullName: "Cronulla High School",
	},
	{
		schoolCode: 8262,
		schoolFullName: "Ingleburn High School",
	},
	{
		schoolCode: 8263,
		schoolFullName: "J J Cahill Memorial High School",
	},
	{
		schoolCode: 8265,
		schoolFullName: "Sefton High School",
	},
	{
		schoolCode: 8266,
		schoolFullName: "James Fallon High School",
	},
	{
		schoolCode: 8268,
		schoolFullName: "Glenwood High School",
	},
	{
		schoolCode: 8269,
		schoolFullName: "Chester Hill High School",
	},
	{
		schoolCode: 8270,
		schoolFullName: "Wee Waa High School",
	},
	{
		schoolCode: 8271,
		schoolFullName: "Lucas Heights Community School",
	},
	{
		schoolCode: 8272,
		schoolFullName: "Coffs Harbour Senior College",
	},
	{
		schoolCode: 8273,
		schoolFullName: "Cecil Hills High School",
	},
	{
		schoolCode: 8274,
		schoolFullName: "Tuggerah Lakes Sec College Tumbi Umbi",
	},
	{
		schoolCode: 8276,
		schoolFullName: "Glenmore Park High School",
	},
	{
		schoolCode: 8277,
		schoolFullName: "Wyndham College ",
	},
	{
		schoolCode: 8278,
		schoolFullName: "Wadalba Community School",
	},
	{
		schoolCode: 8279,
		schoolFullName: "Lake Munmorah High School",
	},
	{
		schoolCode: 8280,
		schoolFullName: "Camden Haven High School",
	},
	{
		schoolCode: 8281,
		schoolFullName: "Great Lakes College Senior",
	},
	{
		schoolCode: 8282,
		schoolFullName: "Banora Point High School",
	},
	{
		schoolCode: 8284,
		schoolFullName: "Georges River College Oatley Senior",
	},
	{
		schoolCode: 8286,
		schoolFullName: "Mount Annan High School",
	},
	{
		schoolCode: 8288,
		schoolFullName: "Central Sydney Intensive Eng High School",
	},
	{
		schoolCode: 8289,
		schoolFullName: "Great Lakes College Tuncurry",
	},
	{
		schoolCode: 8290,
		schoolFullName: "John Edmondson High School",
	},
	{
		schoolCode: 8291,
		schoolFullName: "Rouse Hill High School",
	},
	{
		schoolCode: 8292,
		schoolFullName: "Kariong Mountains High School",
	},
	{
		schoolCode: 8308,
		schoolFullName: "Corowa High School",
	},
	{
		schoolCode: 8314,
		schoolFullName: "The Henry Lawson High School",
	},
	{
		schoolCode: 8318,
		schoolFullName: "Kandos High School",
	},
	{
		schoolCode: 8320,
		schoolFullName: "Kogarah High School",
	},
	{
		schoolCode: 8325,
		schoolFullName: "Moree Secondary College Albert St ",
	},
	{
		schoolCode: 8326,
		schoolFullName: "Mosman High School",
	},
	{
		schoolCode: 8328,
		schoolFullName: "Murrumburrah High School",
	},
	{
		schoolCode: 8334,
		schoolFullName: "Yass High School",
	},
	{
		schoolCode: 8343,
		schoolFullName: "Tumut High School",
	},
	{
		schoolCode: 8346,
		schoolFullName: "Condobolin High School",
	},
	{
		schoolCode: 8347,
		schoolFullName: "Coonabarabran High School",
	},
	{
		schoolCode: 8349,
		schoolFullName: "Gilgandra High School",
	},
	{
		schoolCode: 8353,
		schoolFullName: "Nyngan High School",
	},
	{
		schoolCode: 8355,
		schoolFullName: "Bourke High School",
	},
	{
		schoolCode: 8356,
		schoolFullName: "Cobar High School",
	},
	{
		schoolCode: 8357,
		schoolFullName: "Blayney High School",
	},
	{
		schoolCode: 8358,
		schoolFullName: "Tenterfield High School",
	},
	{
		schoolCode: 8360,
		schoolFullName: "Finley High School",
	},
	{
		schoolCode: 8362,
		schoolFullName: "Maclean High School",
	},
	{
		schoolCode: 8364,
		schoolFullName: "Hastings Sec College Port Macquarie",
	},
	{
		schoolCode: 8368,
		schoolFullName: "Moruya High School",
	},
	{
		schoolCode: 8370,
		schoolFullName: "Tweed River High School",
	},
	{
		schoolCode: 8371,
		schoolFullName: "Gloucester High School",
	},
	{
		schoolCode: 8372,
		schoolFullName: "The Forest High School",
	},
	{
		schoolCode: 8373,
		schoolFullName: "Five Islands Secondary College ",
	},
	{
		schoolCode: 8374,
		schoolFullName: "Brisbane Water Sec College Woy Woy",
	},
	{
		schoolCode: 8377,
		schoolFullName: "Picnic Point High School",
	},
	{
		schoolCode: 8379,
		schoolFullName: "Pittwater High School",
	},
	{
		schoolCode: 8381,
		schoolFullName: "Bankstown Senior College ",
	},
	{
		schoolCode: 8382,
		schoolFullName: "Castle Hill High School",
	},
	{
		schoolCode: 8383,
		schoolFullName: "Nepean Creative and Performing Arts HS",
	},
	{
		schoolCode: 8384,
		schoolFullName: "Westfields Sports High School",
	},
	{
		schoolCode: 8385,
		schoolFullName: "Greystanes High School",
	},
	{
		schoolCode: 8386,
		schoolFullName: "Gymea Technology High School",
	},
	{
		schoolCode: 8388,
		schoolFullName: "Moss Vale High School",
	},
	{
		schoolCode: 8389,
		schoolFullName: "Swansea High School",
	},
	{
		schoolCode: 8390,
		schoolFullName: "Whitebridge High School",
	},
	{
		schoolCode: 8391,
		schoolFullName: "Wingham High School",
	},
	{
		schoolCode: 8392,
		schoolFullName: "West Wyalong High School",
	},
	{
		schoolCode: 8393,
		schoolFullName: "Canley Vale High School",
	},
	{
		schoolCode: 8394,
		schoolFullName: "Miller High School",
	},
	{
		schoolCode: 8395,
		schoolFullName: "Pendle Hill High School",
	},
	{
		schoolCode: 8396,
		schoolFullName: "Windsor High School",
	},
	{
		schoolCode: 8397,
		schoolFullName: "Ashcroft High School",
	},
	{
		schoolCode: 8398,
		schoolFullName: "Condell Park High School",
	},
	{
		schoolCode: 8399,
		schoolFullName: "Doonside High School",
	},
	{
		schoolCode: 8400,
		schoolFullName: "St Ives High School",
	},
	{
		schoolCode: 8401,
		schoolFullName: "Lurnea High School",
	},
	{
		schoolCode: 8403,
		schoolFullName: "Mitchell High School",
	},
	{
		schoolCode: 8404,
		schoolFullName: "Endeavour Sports High School",
	},
	{
		schoolCode: 8405,
		schoolFullName: "Erina High School",
	},
	{
		schoolCode: 8406,
		schoolFullName: "South Grafton High School",
	},
	{
		schoolCode: 8407,
		schoolFullName: "Mount Austin High School",
	},
	{
		schoolCode: 8408,
		schoolFullName: "Georges River College Peakhurst",
	},
	{
		schoolCode: 8409,
		schoolFullName: "Scone High School",
	},
	{
		schoolCode: 8410,
		schoolFullName: "Woonona High School",
	},
	{
		schoolCode: 8411,
		schoolFullName: "West Wallsend High School",
	},
	{
		schoolCode: 8412,
		schoolFullName: "The Hills Sports High School",
	},
	{
		schoolCode: 8413,
		schoolFullName: "Morisset High School",
	},
	{
		schoolCode: 8414,
		schoolFullName: "Chatham High School",
	},
	{
		schoolCode: 8415,
		schoolFullName: "Ryde Secondary College",
	},
	{
		schoolCode: 8416,
		schoolFullName: "Ku-ring-gai High School",
	},
	{
		schoolCode: 8417,
		schoolFullName: "Dubbo College South",
	},
	{
		schoolCode: 8418,
		schoolFullName: "Warilla High School",
	},
	{
		schoolCode: 8420,
		schoolFullName: "Evans High School",
	},
	{
		schoolCode: 8422,
		schoolFullName: "Killarney Heights High School",
	},
	{
		schoolCode: 8423,
		schoolFullName: "Kotara High School",
	},
	{
		schoolCode: 8424,
		schoolFullName: "Holroyd High School",
	},
	{
		schoolCode: 8432,
		schoolFullName: "Engadine High School",
	},
	{
		schoolCode: 8433,
		schoolFullName: "Woolooware High School",
	},
	{
		schoolCode: 8435,
		schoolFullName: "Turramurra High School",
	},
	{
		schoolCode: 8436,
		schoolFullName: "Granville South Creative & Perf Arts HS ",
	},
	{
		schoolCode: 8437,
		schoolFullName: "Kirrawee High School",
	},
	{
		schoolCode: 8438,
		schoolFullName: "Pennant Hills High School",
	},
	{
		schoolCode: 8439,
		schoolFullName: "Francis Greenway High School",
	},
	{
		schoolCode: 8440,
		schoolFullName: "Warners Bay High School",
	},
	{
		schoolCode: 8442,
		schoolFullName: "Gundagai High School",
	},
	{
		schoolCode: 8443,
		schoolFullName: "Colyton High School",
	},
	{
		schoolCode: 8444,
		schoolFullName: "Canobolas Rural Technology High School",
	},
	{
		schoolCode: 8446,
		schoolFullName: "Springwood High School",
	},
	{
		schoolCode: 8447,
		schoolFullName: "Carlingford High School",
	},
	{
		schoolCode: 8448,
		schoolFullName: "Bomaderry High School",
	},
	{
		schoolCode: 8449,
		schoolFullName: "Figtree High School",
	},
	{
		schoolCode: 8450,
		schoolFullName: "Tumbarumba High School",
	},
	{
		schoolCode: 8451,
		schoolFullName: "Coonamble High School",
	},
	{
		schoolCode: 8452,
		schoolFullName: "Crookwell High School",
	},
	{
		schoolCode: 8453,
		schoolFullName: "Dorrigo High School",
	},
	{
		schoolCode: 8454,
		schoolFullName: "Barrenjoey High School",
	},
	{
		schoolCode: 8455,
		schoolFullName: "Oxley High School",
	},
	{
		schoolCode: 8456,
		schoolFullName: "Fairvale High School",
	},
	{
		schoolCode: 8457,
		schoolFullName: "Killara High School",
	},
	{
		schoolCode: 8458,
		schoolFullName: "Kingswood High School",
	},
	{
		schoolCode: 8459,
		schoolFullName: "Chifley College Mount Druitt",
	},
	{
		schoolCode: 8460,
		schoolFullName: "James Busby High School",
	},
	{
		schoolCode: 8461,
		schoolFullName: "Barham High School",
	},
	{
		schoolCode: 8462,
		schoolFullName: "Narromine High School",
	},
	{
		schoolCode: 8463,
		schoolFullName: "Sylvania High School",
	},
	{
		schoolCode: 8464,
		schoolFullName: "Baulkham Hills High School",
	},
	{
		schoolCode: 8465,
		schoolFullName: "Tuggerah Lakes Sec College The Entrance ",
	},
	{
		schoolCode: 8466,
		schoolFullName: "Glendale High School",
	},
	{
		schoolCode: 8467,
		schoolFullName: "Henry Kendall High School",
	},
	{
		schoolCode: 8468,
		schoolFullName: "Mulwaree High School",
	},
	{
		schoolCode: 8469,
		schoolFullName: "Orara High School",
	},
	{
		schoolCode: 8471,
		schoolFullName: "Moree Secondary College Carol Ave ",
	},
	{
		schoolCode: 8472,
		schoolFullName: "Dungog High School",
	},
	{
		schoolCode: 8473,
		schoolFullName: "Chifley College Senior",
	},
	{
		schoolCode: 8474,
		schoolFullName: "Lake Illawarra High School",
	},
	{
		schoolCode: 8475,
		schoolFullName: "Walgett Community College - High School",
	},
	{
		schoolCode: 8476,
		schoolFullName: "Casula High School",
	},
	{
		schoolCode: 8477,
		schoolFullName: "Kellyville High School",
	},
	{
		schoolCode: 8479,
		schoolFullName: "Davidson High School",
	},
	{
		schoolCode: 8480,
		schoolFullName: "Warrawong High School",
	},
	{
		schoolCode: 8481,
		schoolFullName: "Galston High School",
	},
	{
		schoolCode: 8482,
		schoolFullName: "Lambton High School",
	},
	{
		schoolCode: 8483,
		schoolFullName: "Kooringal High School",
	},
	{
		schoolCode: 8484,
		schoolFullName: "Sydney Secondary College Balmain",
	},
	{
		schoolCode: 8485,
		schoolFullName: "Hoxton Park High School",
	},
	{
		schoolCode: 8486,
		schoolFullName: "Bombala High School",
	},
	{
		schoolCode: 8487,
		schoolFullName: "Eden Marine High School",
	},
	{
		schoolCode: 8488,
		schoolFullName: "Chifley College Dunheved ",
	},
	{
		schoolCode: 8489,
		schoolFullName: "Kanahooka High School",
	},
	{
		schoolCode: 8490,
		schoolFullName: "Macintyre High School",
	},
	{
		schoolCode: 8491,
		schoolFullName: "Willyama High School",
	},
	{
		schoolCode: 8493,
		schoolFullName: "Airds High School",
	},
	{
		schoolCode: 8494,
		schoolFullName: "Coomealla High School",
	},
	{
		schoolCode: 8495,
		schoolFullName: "Ulladulla High School",
	},
	{
		schoolCode: 8496,
		schoolFullName: "Chifley College Shalvey",
	},
	{
		schoolCode: 8497,
		schoolFullName: "James Meehan High School",
	},
	{
		schoolCode: 8498,
		schoolFullName: "Muirfield High School",
	},
	{
		schoolCode: 8499,
		schoolFullName: "Model Farms High School",
	},
	{
		schoolCode: 8500,
		schoolFullName: "Girraween High School",
	},
	{
		schoolCode: 8501,
		schoolFullName: "Moorebank High School",
	},
	{
		schoolCode: 8502,
		schoolFullName: "Tomaree High School",
	},
	{
		schoolCode: 8503,
		schoolFullName: "Billabong High School",
	},
	{
		schoolCode: 8504,
		schoolFullName: "Fort Street High School",
	},
	{
		schoolCode: 8505,
		schoolFullName: "Murray High School",
	},
	{
		schoolCode: 8506,
		schoolFullName: "Tempe High School",
	},
	{
		schoolCode: 8507,
		schoolFullName: "Sydney Secondary College Leichhardt",
	},
	{
		schoolCode: 8508,
		schoolFullName: "Callaghan College Waratah Technology ",
	},
	{
		schoolCode: 8509,
		schoolFullName: "Newcastle High School",
	},
	{
		schoolCode: 8510,
		schoolFullName: "Merewether High School",
	},
	{
		schoolCode: 8511,
		schoolFullName: "Nth Beaches Secondary College Cromer ",
	},
	{
		schoolCode: 8512,
		schoolFullName: "Narrabeen Sports High School",
	},
	{
		schoolCode: 8513,
		schoolFullName: "Brisbane Water Secondary College Umina",
	},
	{
		schoolCode: 8514,
		schoolFullName: "Plumpton High School",
	},
	{
		schoolCode: 8515,
		schoolFullName: "Gorokan High School",
	},
	{
		schoolCode: 8516,
		schoolFullName: "Cambridge Park High School",
	},
	{
		schoolCode: 8517,
		schoolFullName: "Denison College of Sec Ed Kelso High ",
	},
	{
		schoolCode: 8518,
		schoolFullName: "Canowindra High School",
	},
	{
		schoolCode: 8519,
		schoolFullName: "Warialda High School",
	},
	{
		schoolCode: 8520,
		schoolFullName: "The Rivers Sec C Kadina High Campus",
	},
	{
		schoolCode: 8521,
		schoolFullName: "Elderslie High School",
	},
	{
		schoolCode: 8522,
		schoolFullName: "Blaxland High School",
	},
	{
		schoolCode: 8524,
		schoolFullName: "Karabar High School",
	},
	{
		schoolCode: 8525,
		schoolFullName: "Peel High School",
	},
	{
		schoolCode: 8526,
		schoolFullName: "Colo High School",
	},
	{
		schoolCode: 8527,
		schoolFullName: "Toormina High School",
	},
	{
		schoolCode: 8528,
		schoolFullName: "Chifley College Bidwill",
	},
	{
		schoolCode: 8530,
		schoolFullName: "Newtown High Schoolof Performing Arts",
	},
	{
		schoolCode: 8531,
		schoolFullName: "St Johns Park High School",
	},
	{
		schoolCode: 8532,
		schoolFullName: "Leumeah High School",
	},
	{
		schoolCode: 8533,
		schoolFullName: "Terrigal High School",
	},
	{
		schoolCode: 8534,
		schoolFullName: "Great Lakes College Forster ",
	},
	{
		schoolCode: 8535,
		schoolFullName: "Concord High School",
	},
	{
		schoolCode: 8536,
		schoolFullName: "Narooma High School",
	},
	{
		schoolCode: 8539,
		schoolFullName: "Sydney Sec College Blackwattle Bay",
	},
	{
		schoolCode: 8540,
		schoolFullName: "Marrickville High School",
	},
	{
		schoolCode: 8541,
		schoolFullName: "Keira High School",
	},
	{
		schoolCode: 8542,
		schoolFullName: "Smiths Hill High School",
	},
	{
		schoolCode: 8543,
		schoolFullName: "Dubbo College Delroy",
	},
	{
		schoolCode: 8544,
		schoolFullName: "Lisarow High School",
	},
	{
		schoolCode: 8545,
		schoolFullName: "South Sydney High School",
	},
	{
		schoolCode: 8546,
		schoolFullName: "Gulgong High School",
	},
	{
		schoolCode: 8547,
		schoolFullName: "Northlakes High School",
	},
	{
		schoolCode: 8548,
		schoolFullName: "Woolgoolga High School",
	},
	{
		schoolCode: 8550,
		schoolFullName: "Fairfield High School",
	},
	{
		schoolCode: 8551,
		schoolFullName: "Sarah Redfern High School",
	},
	{
		schoolCode: 8552,
		schoolFullName: "Crestwood High School",
	},
	{
		schoolCode: 8553,
		schoolFullName: "Macquarie Fields High School",
	},
	{
		schoolCode: 8554,
		schoolFullName: "Hastings Secondary College Westport",
	},
	{
		schoolCode: 8555,
		schoolFullName: "Rose Bay Secondary College",
	},
	{
		schoolCode: 8556,
		schoolFullName: "Alexandria Park Community School",
	},
	{
		schoolCode: 8559,
		schoolFullName: "Jamison High School",
	},
	{
		schoolCode: 8560,
		schoolFullName: "Prairiewood High School",
	},
	{
		schoolCode: 8561,
		schoolFullName: "Shoalhaven High School",
	},
	{
		schoolCode: 8562,
		schoolFullName: "Irrawang High School",
	},
	{
		schoolCode: 8563,
		schoolFullName: "Tuggerah Lakes Sec Coll Berkeley Vale",
	},
	{
		schoolCode: 8564,
		schoolFullName: "Holsworthy High School",
	},
	{
		schoolCode: 8565,
		schoolFullName: "Hawkesbury High School",
	},
	{
		schoolCode: 8566,
		schoolFullName: "Melville High School",
	},
	{
		schoolCode: 8567,
		schoolFullName: "Nth Beaches Secondary College Manly",
	},
	{
		schoolCode: 8568,
		schoolFullName: "Nth Beaches Sec Coll Freshwater Senior",
	},
	{
		schoolCode: 8569,
		schoolFullName: "Eagle Vale High School",
	},
	{
		schoolCode: 8570,
		schoolFullName: "Ambarvale High School",
	},
	{
		schoolCode: 8571,
		schoolFullName: "St Clair High School",
	},
	{
		schoolCode: 8572,
		schoolFullName: "Rutherford High School",
	},
	{
		schoolCode: 8573,
		schoolFullName: "Winmalee High School",
	},
	{
		schoolCode: 8574,
		schoolFullName: "Mount View High School",
	},
	{
		schoolCode: 8575,
		schoolFullName: "Robert Townson High School",
	},
	{
		schoolCode: 8577,
		schoolFullName: "Byron Bay High School",
	},
	{
		schoolCode: 8578,
		schoolFullName: "Alstonville High School",
	},
	{
		schoolCode: 8579,
		schoolFullName: "Kingscliff High School",
	},
	{
		schoolCode: 8580,
		schoolFullName: "Cranebrook High School",
	},
	{
		schoolCode: 8581,
		schoolFullName: "Batemans Bay High School",
	},
	{
		schoolCode: 8582,
		schoolFullName: "Bossley Park High School",
	},
	{
		schoolCode: 8583,
		schoolFullName: "Menai High School",
	},
	{
		schoolCode: 8584,
		schoolFullName: "Elizabeth Macarthur High School",
	},
	{
		schoolCode: 8585,
		schoolFullName: "Kincumber High School",
	},
	{
		schoolCode: 8586,
		schoolFullName: "Albion Park High School",
	},
	{
		schoolCode: 8587,
		schoolFullName: "Sydney Distance Education High School",
	},
	{
		schoolCode: 8588,
		schoolFullName: "NSW Schoolof Languages",
	},
	{
		schoolCode: 8589,
		schoolFullName: "Thomas Reddall High School",
	},
	{
		schoolCode: 8590,
		schoolFullName: "Erskine Park High School",
	},
	{
		schoolCode: 8591,
		schoolFullName: "Narara Valley High School",
	},
	{
		schoolCode: 8592,
		schoolFullName: "Cherrybrook Technology High School",
	},
	{
		schoolCode: 8593,
		schoolFullName: "Vincentia High School",
	},
	{
		schoolCode: 8594,
		schoolFullName: "Quakers Hill High School",
	},
	{
		schoolCode: 8595,
		schoolFullName: "Nambucca Heads High School",
	},
	{
		schoolCode: 8596,
		schoolFullName: "Wollumbin High School",
	},
	{
		schoolCode: 8598,
		schoolFullName: "Oberon High School",
	},
	{
		schoolCode: 8804,
		schoolFullName: "Belmore Boys High School",
	},
	{
		schoolCode: 8806,
		schoolFullName: "Canterbury Girls High School",
	},
	{
		schoolCode: 8811,
		schoolFullName: "Granville Boys High School",
	},
	{
		schoolCode: 8813,
		schoolFullName: "Georges River College Hurstville Boys",
	},
	{
		schoolCode: 8819,
		schoolFullName: "Hunter Schoolof Performing Arts",
	},
	{
		schoolCode: 8822,
		schoolFullName: "Macarthur Girls High School",
	},
	{
		schoolCode: 8831,
		schoolFullName: "Nth Beaches Sec College Mackellar Girls ",
	},
	{
		schoolCode: 8832,
		schoolFullName: "Ashfield Boys High School",
	},
	{
		schoolCode: 8833,
		schoolFullName: "Dulwich HS of Visual Arts and Design ",
	},
	{
		schoolCode: 8851,
		schoolFullName: "Nth Beaches Sec College Balgowlah Boys",
	},
	{
		schoolCode: 8852,
		schoolFullName: "The Jannali High School",
	},
	{
		schoolCode: 8854,
		schoolFullName: "Birrong Girls High School",
	},
	{
		schoolCode: 8857,
		schoolFullName: "Georges River College Penshurst Girls",
	},
	{
		schoolCode: 8870,
		schoolFullName: "St Marys Senior High School",
	},
	{
		schoolCode: 8878,
		schoolFullName: "Bulli High School",
	},
	{
		schoolCode: 8880,
		schoolFullName: "Callaghan College Jesmond",
	},
	{
		schoolCode: 8882,
		schoolFullName: "James Cook Boys High School",
	},
	{
		schoolCode: 8884,
		schoolFullName: "Epping Boys High School",
	},
	{
		schoolCode: 8888,
		schoolFullName: "Wiley Park Girls High School",
	},
	{
		schoolCode: 8892,
		schoolFullName: "Arthur Phillip High School",
	},
	{
		schoolCode: 8903,
		schoolFullName: "Cardiff High School",
	},
	{
		schoolCode: 8905,
		schoolFullName: "Cumberland High School",
	},
	{
		schoolCode: 8906,
		schoolFullName: "Oak Flats High School",
	},
	{
		schoolCode: 8907,
		schoolFullName: "Riverstone High School",
	},
	{
		schoolCode: 8908,
		schoolFullName: "Rooty Hill High School",
	},
	{
		schoolCode: 8909,
		schoolFullName: "Toronto High School",
	},
	{
		schoolCode: 8910,
		schoolFullName: "Cammeraygal High School",
	},
	{
		schoolCode: 8911,
		schoolFullName: "The Ponds High School",
	},
	{
		schoolCode: 8912,
		schoolFullName: "Aurora College",
	},
	{
		schoolCode: 8913,
		schoolFullName: "Inner Sydney High School",
	},
	{
		schoolCode: 8914,
		schoolFullName: "Centre of Excellence in Agricultural Edu",
	},
	{
		schoolCode: 8915,
		schoolFullName: "Armidale Secondary College",
	},
	{
		schoolCode: 8916,
		schoolFullName: "Oran Park High School",
	},
	{
		schoolCode: 8917,
		schoolFullName: "Murrumbidgee Regional High School",
	},
	{
		schoolCode: 9999,
		schoolFullName: "Imaginary Test School",
	},
]
export default { data: { schools: schoolCodes } }
