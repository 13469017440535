<template>
	<div>
		<Banner
			heading="Settings"
			subtitle=""
			icon="settings"
			content-class="pl-10"
			default-background1
		/>
		<v-container
			:class="$vuetify.breakpoint.xsOnly ? 'px-0 mt-5 mobile' : 'px-10 mt-5'"
		>
			<div class="d-flex justify-right pb-6 mt-n2">
				<AdsButton button-text="Save" icon="save" @click="saveSettings" />
			</div>
			<v-card class="mb-8">
				<v-card-title class="header-title">
					<div>
						<b class="px-3"> Service Details </b>
					</div>
					<v-fade-transition>
						<div class="px-3 show-status" v-show="showStatus">
							<v-icon class="green-icon" v-if="settingsStatus == 'Saved'"
								>check_circle_outline</v-icon
							>
							<v-icon class="orange-icon" v-if="settingsStatus == 'Unsaved'"
								>warning_amber</v-icon
							>
							<v-icon
								class="grey-icon spin-me"
								v-if="settingsStatus == 'Saving'"
								>sync</v-icon
							>
							{{ settingsStatus }}
						</div>
					</v-fade-transition>
				</v-card-title>
				<v-card-text v-if="!loading" @input="editFields">
					<div class="px-4 py-3 mt-6">
						<v-row no-gutters>
							<AdsTextField label="Service Name" v-model="service.name" />
						</v-row>
						<v-row no-gutters>
							<v-textarea
								class="schoolmotto"
								outlined
								placeholder=""
								rows="3"
								:label="'School motto / vision statement'"
								:counter="500"
								v-model="service.schoolmotto"
							/>
						</v-row>
						<v-row no-gutters>
							<AdsTextField
								label="Principal, Nominated Supervisor, Educational leader"
								v-model="service.principal"
							/>
						</v-row>

						<v-row>
							<v-col class="col-6">
								<AdsTextField
									label="Service approval number"
									v-model="service.approvalNumber"
								/>
							</v-col>
							<v-col class="col-6">
								<AdsTextField
									label="Approved provider"
									v-model="service.providerName"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="col-6">
								<AdsTextField
									label="Service contact name"
									v-model="service.contactName"
								/>
							</v-col>
							<v-col class="col-6">
								<AdsTextField
									label="Approved provider number"
									v-model="service.providerNumber"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="col-6">
								<AdsTextField
									label="Service contact number"
									v-model="service.contactNumber"
								/>
							</v-col>
							<v-col class="col-6">
								<AdsTextField
									label="Approved provider contact"
									v-model="service.providerContact"
								/>
							</v-col>
						</v-row>

						<v-row no-gutters>
							<AdsTextField label="Educators" v-model="service.educators" />
						</v-row>
					</div>
				</v-card-text>
				<v-card class="mb-8" v-else>
					<v-skeleton-loader class="mt-5" type="article" />
					<v-skeleton-loader class="mt-5" type="article" />
					<v-skeleton-loader class="mt-5" type="article" />
				</v-card>
			</v-card>

			<!--v-card v-if="!loading">
				<v-card-title class="header-title"> Notifications </v-card-title>
				<v-card-text class="header-title py-0">
					<v-row class="notif-row px-2">
						<v-col class="col-9">
							Receive app notifications for all activity
						</v-col>
						<v-col class="col-3"> <v-switch /> </v-col>
					</v-row>
				</v-card-text>
				<v-card-text class="py-0">
					<v-row class="notif-row px-2">
						<v-col class="col-9"> Receive notifications via email </v-col>
						<v-col class="col-3"> <v-switch /> </v-col>
					</v-row>
				</v-card-text>
			</v-card-->
		</v-container>
	</div>
</template>

<script>
import { Banner, AdsTextField, AdsButton } from "@nswdoe/doe-ui-core"
import markdownData from "../api/data/helpData"
import { mapGetters } from "vuex"

const Settings = {
	name: "Settings",
	computed: {
		...mapGetters({
			userProfile: "profile",
			settings: "settings",
			loading: "settingsAreLoading",
			settingsStatus: "settingsStatus",
		}),
		service: {
			get() {
				return this.settingsData.service ? this.settingsData.service : {}
			},
			set(v) {
				this.settingsData.service = v
			},
		},
	},
	data() {
		return {
			myImage: localStorage.getItem("image"),
			items: markdownData,
			tabItems: [
				{ tab: "FAQ's", id: "1" },
				{ tab: "USER GUIDES", id: "2" },
			],
			showStatus: false,
			settingsData: {},
		}
	},
	components: {
		Banner,
		AdsTextField,
		AdsButton,
	},
	watch: {
		settingsStatus(newVal) {
			if (newVal == "Saved") {
				setTimeout(() => {
					this.showStatus = false
				}, 1000)
			} else {
				this.showStatus = true
			}
		},
		loading(newVal) {
			if (!newVal) {
				this.settingsData = this.settings
			}
		},
	},
	created() {
		this.showStatus = this.settingsStatus != "Saved"
		this.settingsData = this.settings
	},
	methods: {
		editFields() {
			this.$store.commit("SET_SETTINGS_STATUS", "Unsaved")
		},
		saveSettings() {
			this.$store.commit("SET_SETTINGS", this.settingsData)
			this.$store.dispatch("saveSettings")
		},
	},
}

export default Settings
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";
@import "../scss/invert-banner.scss";

.spin-me {
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
.justify-right {
	justify-content: right;
}
.vertically-center-me {
	display: flex;
	align-items: center;
}
.orange-icon,
.grey-icon,
.green-icon {
	margin-right: 5px;
}
.green-icon {
	color: $ads-success-green;
}
.orange-icon {
	color: $ads-warning-orange;
}
.grey-icon {
	color: $ads-dark-60;
}

::v-deep .v-input__control .v-input__slot .v-text-field__slot label.v-label {
	min-height: 1.1em;
}

.show-status {
	font-size: 16px;
}
.notif-row {
	align-items: center;
	.col-3 {
		display: flex;
		justify-content: right;
	}
}
.header-title {
	border-bottom: 1px solid $ads-dark-80;
	display: flex;
	justify-content: space-between;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
	color: $ads-dark;
}
::v-deep label.v-label.theme--light {
	color: $ads-dark;
}
h3 {
	color: $ads-navy;
}
::v-deep .v-text-field__slot .v-label.v-label--active.theme--light {
	font-size: 20px;
	font-weight: 600;
	color: $ads-navy;
	padding-left: 2px;
	padding-right: 2px;
}
::v-deep .v-input--is-dirty,
::v-deep .v-input--is-focused {
	.v-input__control .v-input__slot legend {
		min-width: 106px;
	}
}
.schoolmotto ::v-deep {
	.v-input__control .v-input__slot legend {
		width: 14.7em !important;
	}
	/* replicate .v-label--active permanently */
	.v-input__control .v-input__slot .v-text-field__slot label.v-label {
		@extend .v-label--active;
		transform: translateY(-24px) scale(0.75);
	}
}

::v-deep .v-counter {
	padding-top: 2px;
}
</style>
