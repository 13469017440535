<template>
	<v-card @input="dirty">
		<v-card-title v-if="isDialog" class="d-flex justify-space-between">
			<h3 class="d-flex">
				<FeatureIcon
					class="mr-3"
					size="48"
					color="navy"
					background-color="light blue"
					icon="mdi-pencil"
				/>
				<span class="pt-3"> Edit </span>
			</h3>

			<div class="ml-3">
				<v-btn icon aria-label="close" @click="handleBack">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-card-text v-if="loading">
			<v-skeleton-loader type="article" />
		</v-card-text>
		<v-card-text :class="{ 'px-6': !isDialog, 'px-6 py-3': isDialog }" v-else>
			<v-row>
				<v-col cols="12">
					<span class="body-1 font-weight-regular">{{ title }}</span>
				</v-col>
			</v-row>
			<div class="mb-3 mt-n2">
				<v-switch :label="'No response at this time'" v-model="wontRespond" />
			</div>
			<v-expand-transition>
				<div v-if="!strength.wontRespond">
					<v-textarea
						outlined
						placeholder=""
						rows="3"
						auto-grow
						:id="strength.id"
						v-model="strength.text"
						:label="'Enter Details'"
						:counter="500"
						:rules="[rules.under500]"
						@input="checkCanSaveToQip"
					/>
					<h3>Exceeding themes addressed in your example</h3>
					<v-row>
						<v-checkbox 
							class="px-4" 
							label="Embed" 
							v-model="strength.embed"
							@click="dirty"
						/>
						<v-checkbox
							class="px-4"
							label="Critically reflect"
							v-model="strength.reflect"
							@click="dirty"
						/>
						<v-checkbox
							class="px-4"
							label="Meaningful engagement"
							v-model="strength.engagement"
							@click="dirty"
						/>
					</v-row>
					<v-row v-for="(piece, i) of evidence" :key="i">
						<v-col cols="12" md="6">
							<AdsFileInput
								label="Attach Evidence"
								hint="Attach evidence to support your example"
								:persistent-hint="true"
								:file.sync="piece.evidence"
								:multiple="false"
								:rules="[rules.singleFile, rules.fileSize]"
								ref="evidence"
								@change="checkCanSaveToQip(i, piece)"
							/>
							<a class="px-5" v-if="piece.id" @click="viewEvidence(piece.id)">{{
								piece.evidence[0].name
							}}</a>
						</v-col>
						<v-col cols="12" md="6">
							<AdsTextField label="Comments" v-model="piece.comments" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="subtitle-1 d-flex justify-space-between">
							<div
								class="add-another-btn"
								@click="addMoreEvidence()"
								:disabled="evidence.length >= 2"
							>
								<v-icon class="mr-2" v-show="evidence.length < 2"
									>add_circle_outline</v-icon
								>
								<a :align="'center'" v-if="evidence.length < 2">Add another</a>
								<a :align="'center'" v-if="evidence.length >= 2"
									>Two pieces of evidence may be added per statement</a
								>
							</div>
							<SaveStatus :status="status" />
						</v-col>
						<v-col cols="12" class="d-flex justify-right">
							<div v-if="isDialog">
								<AdsButton
									class="px-3 mx-2"
									tertiary
									button-text="Clear all fields"
									@click="clearFields()"
								/>
								<AdsButton
									class="px-3 mx-2"
									secondary
									button-text="Cancel"
									@click="handleBack()"
								/>
								<AdsButton
									class="px-3 mx-2"
									button-text="Update"
									:disabled="!saveButtonEnabled"
									@click="sendToQip()"
								/>
							</div>
							<div v-else class="sendtoqip-btn">
								<AdsButton
									class="px-3 mx-2"
									tertiary
									button-text="Clear all fields"
									@click="clearFields()"
								/>
								<AdsButton
									:icon="'send'"
									button-text="Save to QIP"
									secondary
									@click="sendToQip()"
									:disabled="!saveButtonEnabled"
								/>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-expand-transition>
		</v-card-text>
	</v-card>
</template>

<script>
import api from "../api"
import AdsFileInput from "./AdsFileInput.vue"
import { AdsButton, AdsTextField, FeatureIcon } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
// import { debounce } from "../utils/debounce"
import _ from "lodash"
import SaveStatus from "./SaveStatus.vue"

const RecordStrength = {
	name: "RecordStrength",
	components: { AdsFileInput, AdsButton, AdsTextField, FeatureIcon, SaveStatus },
	props: {
		title: {
			type: String,
			default: "",
		},
		strength: {
			type: Object,
			default() {
				return {}
			},
		},
		mode: {
			type: String,
			default() {
				return "add" // (add || dialog)
			},
		},
		loading: {
			type: Boolean,
			default() {
				return false
			},
		},
	},
	data() {
		return {
			rules: {
				required: (value) => !!value || "Please fill out this field.",
				under500: (value) =>
					(typeof value === "string" && value.length <= 500) ||
					"Must be less than 500 characters.",
				singleFile: (value) =>
					!value ||
					value.length <= 1 ||
					"You can only upload one file at a time.",
				fileSize: (value) =>
					!value ||
					value.length == 0 ||
					value[0].size <= this.maxSize ||
					`File must be less than ${Math.round(this.maxSize / 1000000)}MB.`,
			},
			maxSize: 5 * 1024 * 1024,
			status: 0,
			saveButtonEnabled: false,
			debounceDirtyTimeout: null,
		}
	},
	watch: {
		strength: {
			handler: _.debounce(async function () {
						this.dirty()
						await this.sendToQip();
					}, 2000),
			deep: true,
			immediate: false,
		},
		status: {
			// force saving
			handler() {
				if (this.status === 1) {
					const timeoutId = setTimeout(() => {
						if (this.status === 1) {
							console.log("force saving...")
							this.sendToQip();
						} else {
							clearTimeout(timeoutId)
						}
					}, 4000)
				}
			}
		}
	},
	computed: {
		...mapGetters(["centreId"]),
		assessmentId() {
			return this.$store.getters.selectedAssessment.assessment_id
		},
		evidence: {
			get() {
				if (!this.strength.evidence) return []
				if (this.strength.evidence.length <= 0) this.strength.evidence = [{}]
				return this.strength.evidence
			},
			set(val) {
				this.strength.evidence = val
			},
		},
		isDialog: {
			get() {
				return this.mode === "dialog"
			},
			set(val) {
				this.mode = val ? "dialog" : "add"
			},
		},
		wontRespond: {
			get() {
				return this.strength.wontRespond
			},
			set(val) {
				this.strength.wontRespond = !!val
			},
		},
	},
	methods: {
		viewEvidence(id) {
			let routeData = this.$router.resolve({
				name: "View Evidence",
				params: { id },
			})
			window.open(routeData.href, "_blank")
		},
		dirty() {
			this.status = 1
			// if (this.clearStatusTimeout != undefined) {
			// 	clearTimeout(this.clearStatusTimeout)
			// }
		},
		handleBack() {
			this.$emit("handleBack")
		},
		clearFields() {
			let tStr = JSON.stringify(this.strength)
			this.evidence = []
			this.strength.embed = false
			this.strength.engagement = false
			this.strength.reflect = false
			this.strength.evidenceAttached = false
			this.strength.exceedingTheme = []
			this.strength.summary = ""
			this.strength.text = ""
			if (tStr == JSON.stringify(this.strength)) {
				this.dirty()
			}
		},
		deleteOldEvidence(i) {
			if (this.evidence[i]) {
				this.evidence[i].old_id = this.evidence[i].id
				this.evidence[i].id = ""
			}
		},
		addMoreEvidence() {
			if (this.evidence.length >= 2) return
			this.evidence.push({})
		},
		async sendToQip(shouldCloseAfter = true) {
			let evidence = []
			this.status = 2
			for (let ev of this.evidence) {
				if (ev.evidence && ev.evidence.length == 1) {
					evidence.push({
						old_id: ev.old_id,
						id: ev.id,
						file: ev.evidence[0],
						centre_id: this.centreId,
						comment: ev.comments,
					})
				}
			}

			for (let i in evidence) {
				let ev = evidence[i]
				if (ev.id && ev.id.length) continue

				// if methods.removeOldEvidence gave this an old_id
				if (ev.old_id && ev.old_id.length) {
					await api.strengths.deleteOldEvidence(ev.old_id)
				}

				try {
					let data = await api.strengths.registerEvidence(ev)
					//if (!data) throw Error("Could not register evidence e270")
					ev.id = data.data.id
					this.evidence[i].id = data.data.id
					this.evidence[i].old_id = ""
				} catch (reason) {
					this.evidence.splice(i, 1)
					console.error(reason)
					this.$store.dispatch("displayApiError", [
						"evidence",
						"upload",
						reason.reason || reason.data?.reason || reason.status || "500",
					])
				}
			}
			let strengthCopy = this.strength
			for (let j in strengthCopy.evidence) {
				let ev = strengthCopy.evidence[j]
				for (let i in ev.evidence) {
					let file = ev.evidence[i]
					strengthCopy.evidence[j].evidence[i] = {
						name: file.name,
						size: file.size,
						type: file.type,
						lastModified: file.lastModified,
					}
				}
			}

			const data = await api.strengths.updateStrength(this.assessmentId, this.strength.id, strengthCopy);

			if (data) {
				this.$store.commit("setProgress", [
					data.data.IJKL.split(" ").slice(0, 3),
					data.data.strengthProgress,
					data.data.elementProgress,
				])
				this.$store.commit("updateStrengthData", [
					data.data.IJKL.split(" "),
					data.data.strengthData,
				])
				// Sucess status icon
				this.status = 3;
				console.log(`Strength ${data.data.strengthData.id} has been saved`);
				setTimeout(() => {
					// Remove status icon
					this.status = 0;
				}, 1000);
				setTimeout(() => {
					if (shouldCloseAfter) this.handleBack()
				}, 600);
			}
			
			// await api.strengths
			// 	.updateStrength(this.assessmentId, this.strength.id, strengthCopy)
			// 	.then((data) => {
			// 		if (data) {
			// 			console.log("IJKL..." + data.data.IJKL)
			// 			this.$store.commit("setProgress", [
			// 				data.data.IJKL.split(" ").slice(0, 3),
			// 				data.data.strengthProgress,
			// 				data.data.elementProgress,
			// 			])
			// 			this.$store.commit("updateStrengthData", [
			// 				data.data.IJKL.split(" "),
			// 				data.data.strengthData,
			// 			])
			// 			console.log('here status ' + this.status)
			// 			this.status = 3
			// 			this.clearStatusTimeout = setTimeout(() => {
			// 				console.log("status reset to 0")
			// 				this.status = 0
			// 			}, 1000)
			// 			setTimeout(() => {
			// 				console.log("should close after..." + this.shouldCloseAfter)
			// 				console.log('here status ' + this.status)
			// 				if (shouldCloseAfter) this.handleBack()
			// 			}, 600)
			// 		}
			// 	})
			// 	.catch((reason) => {
			// 		console.error(reason)
			// 		this.$store.dispatch("displayApiError", [
			// 			"evidence",
			// 			"upload",
			// 			reason.reason || reason.data?.reason || reason.status,
			// 		])
			// 	})
		},
		checkCanSaveToQip(...e) {
			this.deleteOldEvidence(...e)

			this.saveButtonEnabled =
				this.assessmentId &&
				typeof this.strength.text === "string" &&
				this.strength.text.length <= 500
					? true
					: false

			if (!this.$refs.evidence || !this.$refs.evidence.length > 0) {
				this.saveButtonEnabled = false
			}

			for (let i in this.$refs.evidence) {
				if (
					!this.$refs.evidence[i]?.$refs.input?.validate() ||
					this.$refs.evidence[i]?.$refs.input?.errorBucket?.length > 0
				) {
					this.saveButtonEnabled = false
				}
			}
			return this.saveButtonEnabled
		},
		trySave() {
			this.checkCanSaveToQip()
			if (this.saveButtonEnabled) this.sendToQip(false)
		},
	},
	created() {
		this.checkCanSaveToQip()
		// this.debouncedSave = debounce(this.trySave, 1500)
	},
}
export default RecordStrength
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";
.justify-right {
	justify-content: right;
}
.spin-me {
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
.vertically-center-me {
	display: flex;
	align-items: center;
}

.save-status-wrapper {
	min-height: 24px;
}
.add-another-btn {
	display: flex;
	align-items: center;
	height: 100%;
	font-weight: 600;
}
.theme--light .add-another-btn a {
	color: #041e42;
}
.theme--light .add-another-btn[disabled],
.theme--light .add-another-btn[disabled] a {
	cursor: unset;
}
.sendtoqip-btn {
	display: flex;
	justify-content: right;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
	color: $ads-dark;
}
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	min-height: 1.1em;
}
:deep(label.v-label.theme--light) {
	color: $ads-dark;
}
h3 {
	color: $ads-navy;
}

:deep(.v-input__control .v-input__slot legend) {
	width: 6.3em !important;
}
/* replicate .v-label--active permanently */
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	@extend .v-label--active;
	transform: translateY(-24px) scale(0.75);
	font-size: 1.25rem !important;
	padding-top: 2px;
}

:deep(.v-text-field__slot .v-label.v-label--active.theme--light) {
	font-size: 20px;
	font-weight: 500;
	color: $ads-navy;
	padding-left: 2px;
	padding-right: 2px;
}
:deep(.v-input--is-dirty),
:deep(.v-input--is-focused) {
	.v-input__control .v-input__slot legend {
		min-width: 96px;
	}
}

.v-application .error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(div.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(label.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(button.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(i.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(.v-counter) {
	padding-top: 2px;
}
</style>
