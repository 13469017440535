<template>
	<v-card tile>
		<v-card-title
			class="d-flex align-center justify-space-between pa-2 px-5"
			:class="{ 'pl-3': $vuetify.breakpoint.xsOnly }"
		>
			<div class="d-flex align-center pt-3">
				<h3>
					<div class="d-flex align-center">
						<span class="pt-1"> Generate your Quality Improvement Plan </span>
					</div>
				</h3>
			</div>
			<div class="ml-3">
				<v-btn icon aria-label="close" @click="handleBack">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

		<v-card-text class="mt-5 px-8 normal-text">
			<div>
				<b> Include </b>
				<v-checkbox
					v-for="item in itemsToInclude"
					:key="item[0]"
					:label="item[1]"
					v-model="include[item[0]]"
				/>
			</div>
			<v-row class="bottom-row mt-5">
				<v-col cols="12">
					<AdsButton class="mx-2" @click="handleBack" :secondary="true"
						>Cancel</AdsButton
					>
					<AdsButton class="mx-2" @click="exportToPdf">Generate DOCX</AdsButton>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { AdsButton } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
import api from "../api"

export default {
	name: "GenerateQIP",
	data() {
		return {
			include: {
				service: true,
				compliance: true,
			},
		}
	},
	computed: {
		...mapGetters({
			qualityAreasAreLoading: "qualityAreasAreLoading",
			qualityAreas: "qualityAreas",
			userProfile: "profile",
			goals: "goals",
			goalsAreLoading: "goalsAreLoading",
			assessmentIsLoading: "assessmentIsLoading",
			assessment: "selectedAssessment",
		}),
		loading() {
			return this.qualityAreasAreLoading || this.assessmentIsLoading
		},
		itemsToInclude() {
			if (this.loading) return false
			let items = [
				["service", "Service philosophy"],
				["compliance", "Compliance (includes all quality areas)"],
			]
			for (let qualityArea of Object.keys(this.qualityAreas)) {
				let tname = this.qualityAreas[qualityArea].name
				tname =
					tname.split(" ")[0] +
					" " +
					tname
						.split(" ")
						.slice(1)
						.map((a) => a.toLowerCase())
						.join(" ")
				items.push([
					qualityArea,
					this.qualityAreas[qualityArea].number + " - " + tname,
				])
			}
			return items
		},
	},
	created() {
		if (!this.loading) {
			for (let qualityArea of Object.keys(this.qualityAreas)) {
				if (!this.include[qualityArea]) this.include[qualityArea] = true
			}
		}
	},
	watch: {
		loading(newVal) {
			if (!newVal) {
				for (let qualityArea of Object.keys(this.qualityAreas)) {
					if (!this.include[qualityArea]) this.include[qualityArea] = false
				}
			}
		},
	},
	methods: {
		handleBack() {
			this.$emit("handleBack")
		},
		exportToPdf() {
			this.$store.commit("SET_IS_LOADING", true)
			api.assessments
				.renderQip(this.assessment.assessment_id, this.include)
				.then((data) => {
					let buf = Buffer.from(data.filedata, "base64")
					this.downloadBlob(
						new Blob([buf]),
						`qip_${new Date()
							.toLocaleDateString("en-au")
							.split("/")
							.join("_")}.docx`,
					)
					this.$store.commit("SET_IS_LOADING", false)
				})
				.catch((reason) => {
					console.error(reason)
					this.$store.dispatch("displayApiError", [
						"QIP",
						"render",
						reason.reason || reason.data?.reason || reason.status,
					])
				})
		},
		downloadBlob(blob, name = "qip.docx") {
			// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
			const blobUrl = URL.createObjectURL(blob)

			// Create a link element
			const link = document.createElement("a")

			// Set link's href to point to the Blob URL
			link.href = blobUrl
			link.download = name

			// Append link to the body
			document.body.appendChild(link)

			// Dispatch click event on the link
			// This is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					view: window,
				}),
			)

			// Remove link from body
			document.body.removeChild(link)
		},
	},
	components: {
		AdsButton,
	},
}
</script>

<style scoped lang="scss">
.bottom-row {
	justify-content: right;
	.col {
		display: flex;
		justify-content: right;
	}
}
.theme--light .v-card > .v-card__text {
	color: $ads-dark;
	font-size: 16px;
}
::v-deep.theme--light .v-input__slot .v-label {
	color: $ads-dark;
}
</style>
