<template>
	<v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
		<template v-if="$route.name !== 'TimedOut'">
			<NavigationDrawer :items="items" />

			<AppBar
				color="primary"
				app-name="my-nsw-gov"
				title="Self-Assessment App"
				:search-filter-mutator="true"
				:show-full-screen="true"
				:show-nav-icon="true"
			>
				<template #appIcon>
					<AppIcon
						v-if="$vuetify.breakpoint.smAndUp"
						app-name="my-nsw-gov"
						:transparent="true"
						size="50px"
						class="mt-2"
					/>
				</template>

				<template #title>
					<v-toolbar-title>
						<b v-if="$vuetify.breakpoint.smAndUp">
							Self-Assessment and Planning (SAaP)
						</b>
						<b v-else> SAaP </b>
					</v-toolbar-title>
				</template>

				<!-- <template v-slot:dataSwitcher>
				<DataSwitcher
					v-if="$vuetify.breakpoint.smAndUp"
					:items="$store.getters.locationsName"
					item-text="schoolFullName"
					item-value="schoolCode"
					label="Search for a school"
					prepend-inner-icon=""
					@change="schoolChanged"
					v-model="selectedLocation"
					class="school-selector"
				/>
			</template> -->

				<template #profile>
					<Profile
						:given-name="userProfile.firstName"
						:surname="userProfile.lastName"
						:update-profile="false"
						:change-password="false"
						:update-secret-question="false"
						:portal-settings="false"
						:logout="true"
						:logout-handler="logout"
					/>
				</template>
			</AppBar>
		</template>
		<v-main class="darker-background">
			<!-- <DataSwitcher
				v-if="$vuetify.breakpoint.xsOnly"
				:items="$store.getters.locationsName"
				item-text="schoolFullName"
				item-value="schoolCode"
				label="Search for a school"
				prepend-inner-icon=""
				@change="schoolChanged"
				v-model="selectedLocation"
				class="school-selector"
			/> -->
			<v-container fluid style="padding: 0px">
				<v-fade-transition mode="out-in">
					<router-view />
				</v-fade-transition>
			</v-container>
		</v-main>

		<!-- app wide components -->
		<BlockUI v-if="$store.getters.isLoading" />
		<Snackbar
			v-model="snackbarProps.show"
			:type="snackbarProps.type"
			:icon="snackbarProps.icon"
			:text="snackbarProps.text"
			:allow-dismiss="snackbarProps.allowDismiss"
			:timeout="snackbarProps.timeout"
			:html-text="snackbarProps.htmlText"
		/>
		<Snackbar
			v-model="$store.state.appLevelError.errorStatus"
			type="warning"
			:text="$store.state.appLevelError.errorMessage.toString()"
			:allow-dismiss="false"
		/>

		<Dialog
			:openDialog="openDialog"
			title="Session timeout"
			maxWidth="520px"
			icon="av_timer"
			:displayCloseBtn="false"
			:persistent="true"
			:message="dialogMessage"
			:html="true"
			:actions="actions"
			@close="openDialog = false"
			:return-handler="handleDialogReturn"
		/>
	</v-app>
</template>

<script>
import {
	AppBar,
	NavigationDrawer,
	Profile,
	MyEssentials,
	DataSwitcher,
	AppIcon,
	BlockUI,
	Dialog,
} from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
import Snackbar from "./components/Snackbar"
import { refreshAuthToken } from "./utils/auth"

const App = {
	name: "App",
	components: {
		BlockUI,
		AppBar,
		NavigationDrawer,
		Profile,
		MyEssentials,
		DataSwitcher,
		AppIcon,
		Snackbar,
		Dialog,
	},
	computed: {
		...mapGetters({
			userProfile: "profile",
			assessmentIsLoading: "assessmentIsLoading",
			qualityAreasAreLoading: "qualityAreasAreLoading",
			qualityAreaStructureIsLoading: "qualityAreaStructureIsLoading",
			hasSelectedQualityArea: "hasSelectedQualityArea",
		}),
		selectedLocation: {
			get() {
				return this.$store.state.centreModule.selectedLocation
			},
			set(location) {
				this.$store.commit("SELECT_SELECTED_SCHOOL", location)
			},
		},
		snackbarProps: function () {
			return this.$store.state.snackbar
		},
		allowedRoles() {
			let roles = [
				"DET.CASUAL",
				"DET.STAFFSECONDMENT",
				"SCHOOL.NONTEACHER",
				"SCHOOL.CASUALTEACHER",
				"SCHOOL.EXCHANGEPRINCIPAL",
				"SCHOOL.EXCHANGESUBEXECUTIVE",
				"SCHOOL.EXCHANGETEACHER",
				"SCHOOL.SUBEXECUTIVE",
				"SCHOOL.PRINCIPAL",
				"SCHOOL.PRINCIPAL.DEPUTY.PS",
				"SCHOOL.TEACHER",
				"SCHOOL.STAFF.LEAVE",
				"DET.ACE",
				"DET.CONTRACTOR",
			]
			return roles
		},
		oauthDataLoaded() {
			return this.$OAuth.data.loaded
		},
		formattedCountdownTime() {
			if (this.timeUntilExpiry <= 0) {
				return "Expired"
			}
			const minutes = Math.floor(this.timeUntilExpiry / 60)
			const seconds = this.timeUntilExpiry % 60
			return `${minutes} minutes and ${seconds} seconds`
		},
		dialogMessage() {
			return `Your session is about to timeout in ${this.formattedCountdownTime}.<br><br>Do you want to continue working?`
		},
	},
	created: async function () {
		// REMOVE POST DEVELOPMENT {
		/*if (!sessionStorage.getItem("Profile"))
			sessionStorage.setItem(
				"Profile",
				'{"at_hash":"9eljW4O59Q42FidgVT7nzw","sub":"finhs.principal01","auditTrackingId":"a885f168-d4de-4068-add2-389f82c21107-329682","roles":["NONGOV.PRINCIPAL"],"amr":["detnsw"],"iss":"https://sso.test.det.nsw.edu.au:443/sso/oauth2","tokenName":"id_token","acl":["Year k 2323"],"det_user_guid":"18d09b7d-f19f-42dd-9b8d-535468c7a4ac","acr":"0","azp":"WebPortal","auth_time":1616725431,"sn":"HS","exp":1616729032,"iat":1616725432,"email":"FINHS.Principal01@tst.det.nsw.edu.au","passwordExpiration":"20210309103348Z","givenName":"Fin","nonce":"47,38,247,250,135,155,66,212,21,31,93,206,93,230,208,40,53,181,105,146,247,99,58,5,24,2,123,73,171,236,208,217","aud":"WebPortal","c_hash":"BQojhQlv3cxJDY0i0r4eMA","org.forgerock.openidconnect.ops":"tUBlglJjK_S4ahm19URtzk56mHU","locations":"8466","realm":"/","tokenType":"JWTToken","username":"finhs.principal01"}',
			)*/
		// } REMOVE POST DEVELOPMENT
		this.$store.commit("showSpinner")
		this.tryParseRoute()

		if (this.oauthDataLoaded) {
			this.setUserProfile()
		}
	},
	data: function () {
		return {
			// sample data for NavigationDrawer component
			items: [
				{
					title: "Quality Areas",
					icon: "assignment",
					iconOutlined: true,
					route: "/assessment",
					// items: [{
					// 	title: "Educational Program & Practice",
					// 	route: "/assessment/epp",
					// },
					// {
					// 	title: "Children's Health & Safety",
					// 	route: "/assessment/chs",
					// },
					// {
					// 	title: "Physical Environment",
					// 	route: "/assessment/physenv",
					// },
					// {
					// 	title: "Staffing Arrangements",
					// 	route: "/assessment/staffarr",
					// },
					// {
					// 	title: "Relationships with Children",
					// 	route: "/assessment/rwc",
					// },
					// {
					// 	title: "Collaborative Partnerships with Families",
					// 	route: "/assessment/cpwfac",
					// },
					// {
					// 	title: "Governance & Leadership",
					// 	route: "/assessment/gal",
					// }]
				},
				{
					title: "Help and support",
					icon: "help_outline",
					iconOutlined: true,
					route: "/help",
				},
				{
					title: "Settings",
					icon: "settings",
					iconOutlined: true,
					route: "/settings",
				},
			],
			showExpiryDialog: false,
			actions: [
				{ name: "Log out", color: "#041E42", size: "large", outlined: true },
				{ name: "Continue", color: "#041E42", size: "large", outlined: false },
			],
			openDialog: false,
			timeUntilExpiry: 0,
			continuedFromDialog: false
		}
	},
	mounted() {
		if (this.userProfile) {
			this.updateCountdownTime()
		}

		this.updateCountdownTime() // Initial update

		setInterval(() => {
			if (!this.continuedFromDialog) {
				this.updateCountdownTime();
			}
		}, 1000);
	},
	watch: {
		"this.userProfile": {
			immediate: true,
			handler(exp) {
				if (exp) {
					this.updateCountdownTime()
				}
			},
		},
		qualityAreasAreLoading: {
			immediate: true,
			handler(newVal) {
				if (newVal === false && this.$route.params.qualityArea)
					this.loadQualityAreaStructure()
			},
		},
		hasSelectedQualityArea: {
			immediate: true,
			handler(newVal) {
				//console.log("selectQualityArea: ", newVal)
				if (newVal === true && this.$route.params.standard) {
					this.$store.commit("selectStandard", this.$route.params.standard)
				}
			},
		},
		oauthDataLoaded: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					this.setUserProfile()
				}
			},
		},
		$route() {
			this.tryParseRoute()
		},
	},
	methods: {
		logout() {
			if (localStorage) {
				localStorage.removeItem("auth_token")
				localStorage.removeItem("access_token")
				localStorage.removeItem("token_type")
				localStorage.removeItem("id_token")
			}
			this.$OAuth.logout()
		},
		async setUserProfile() {
			if (this.$OAuth.data.profile) {
				sessionStorage.setItem(
					"Profile",
					JSON.stringify(this.$OAuth.data.profile),
				)
			}

			let profile =
				this.$OAuth.data.profile ||
				JSON.parse(sessionStorage.getItem("Profile"))

			if (profile !== null && profile.email) {
				// console.log('This is the token expiry: ', profile.exp)
				// profile.exp = Math.floor((profile.exp * 1000 - 55 * 60 * 1000) / 1000); //set to expire in 6 mins				

				// note, this check should be done by oauth, but in case there's ever a config error on their end that could lead to security issues, this will help us.
				let roles = profile.roles || profile["det-roles"]
				if (!profile.username) {
					if (profile.email) profile.username = profile.email.split("@")[0] // thx doe for ur consistent naming conventions
					if (profile["det-username"])
						profile.username = profile["det-username"]
				}
				if (Array.isArray(profile.roles)) {
					roles = profile.roles.join(" ")
				}
				let isAllowed = this.allowedRoles.filter(
					(allowedRole) => roles.indexOf(allowedRole) != -1,
				).length
				if (!isAllowed) {
					if (process.env.NODE_ENV != "development") {
						// eslint-disable-next-line no-console
						console.warn("SCHOOL ROLE NOT ALLOWED! APP.VUE:265")
						this.$router.push({
							name: "Error 401",
							params: { ERRORCODE: "USER-401" },
						})
					}
				} else if (profile.email) {
					this.$store.dispatch("setProfile", profile)
				}
			} else {
				console.log("uh oh")
				if (process.env.NODE_ENV != "development") {
					// eslint-disable-next-line no-console
					console.warn("BROKEN USER PROFILE! APP.VUE:277")
					this.$router.push({
						name: "Error 401",
						params: { ERRORCODE: "USER-401" },
					})
				}
			}

			console.log("profile", profile)

			this.$store.dispatch("loadPreviouslySeenGuides")
			await this.$store.dispatch("getLocationsNames")
			await this.$store.dispatch("fetchQualityAreas")
			try {
				await this.$store.dispatch("fetchCentre")
				await this.$store.dispatch("fetchAssessment")
			} catch (e) {
				if (e.status == 401 || e.status == 403) {
					// eslint-disable-next-line no-console
					console.warn("REROUTING APP.VUE:297", e)
					this.$router.push({
						name: "Error 401",
						params: { ERRORCODE: "CENTRE-401" },
					})
				} else if (e.status == 404) {
					this.$router.push({
						name: "Error 401",
						params: { ERRORCODE: "CENTRE-404" },
					})
				}
			}
			this.$store.commit("hideSpinner")

			this.tryParseRoute()
		},
		schoolChanged() {
			//most likely, this function will be deprecated, deleted, or repurposed as "centerSelected"
			console.log("School Changed!")
		},
		loadQualityAreaStructure() {
			let qualityAreaShort = this.$route.params.qualityArea
			if (qualityAreaShort) {
				this.$store.commit("selectQualityArea", qualityAreaShort)
				if (!this.$store.getters.selectedQualityAreaStructure) {
					this.$store.dispatch("fetchQualityAreaStructure")
				}
			}
		},
		selectRouteStandard() {
			if (this.$store.getters.hasSelectedQualityArea)
				this.$store.commit("selectStandard", this.$route.params.standard)
		},
		tryParseRoute() {
			if (
				this.$route.params.qualityArea &&
				this.qualityAreasAreLoading === false
			) {
				this.loadQualityAreaStructure()
			}
			if (this.$route.params.standard) {
				this.selectRouteStandard()
			}
		},
		handleDialogReturn(result) {
			// result = action.name e.g. "Ok" or "Cancel"
			this.openDialog = false
			console.log("dialog result", result)

			if (result === "Continue") {
				this.continuedFromDialog = true
				console.log('setting the continuedFromDialog property to true')
				this.handleTokenRefresh()
			} else if (result == "Log out") this.logout()

		},
		async handleTokenRefresh() {
			try {
				this.openDialog = false
				await refreshAuthToken()

				this.setUserProfile()
				this.openDialog = false
				this.continuedFromDialog = false;

				// console.log('New Auth Details:', refreshedAuth);
			} catch (error) {
				console.error("Error refreshing token:", error)
			}
		},
		updateCountdownTime() {

			if (this.$route.name != "TimedOut") {
				const currentTime = Math.floor(Date.now() / 1000)
				this.timeUntilExpiry = this.userProfile.exp - currentTime

				// console.log('timeUntilExpiry', this.timeUntilExpiry);

				if (this.timeUntilExpiry <= 0) {
					//redirect to logout/error page
					this.openDialog = false

					console.log("Token is expired.")


					this.$router.push("/errortimedout")

					if (localStorage) {
						localStorage.removeItem("auth_token")
						localStorage.removeItem("access_token")
						localStorage.removeItem("token_type")
						localStorage.removeItem("id_token")
					}
				} else if (this.timeUntilExpiry <= 5 * 60) {
					// this.openDialog == false;
					console.log('continuedFromDialog', this.continuedFromDialog)
					if(!this.continuedFromDialog)
						this.openDialog = true
				} else {

					this.openDialog = false
					// console.log(`token is still valid for another ${this.timeUntilExpiry / 60} minutes`)
				}
			}
		},
	},
}

export default App
</script>

<style lang="scss">
@import "./scss/ads.scss";

.mobile .school-selector.v-select--is-menu-active .v-input__slot,
.mobile .school-selector.primary--text .v-input__slot {
	background-color: $ads-blue-2 !important;
	opacity: 1;
}
.theme--light a:not(.v-btn, .v-tab) {
	color: $ads-navy;
}
.mobile .school-selector {
	background-color: $ads-blue-1;
	border-radius: 0px;
	color: $ads-light-10 !important;
	caret-color: $ads-light-10 !important;
	[aria-expanded="true"] {
		background-color: $ads-blue-3;
	}
	.v-select__slot input {
		color: $ads-light-10 !important;
	}
}
.v-application .error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

::v-deep div.error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}
::v-deep label.error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}
::v-deep button.error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}
::v-deep i.error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}
::v-deep .v-counter {
	padding-top: 2px !important;
}

.theme--dark {
	.v-expansion-panels .v-expansion-panel .subtitle-1,
	.panel--icon .v-icon {
		color: red !important;
	}
	::v-deep .v-icon {
		color: $ads-navy-dark !important;
	}
}
</style>

<style lang="scss" scoped>
@import "./scss/ads.scss";

.container {
	padding: 12px 30px;

	.container {
		padding: 0;
	}
}

.mobile {
	.container {
		padding: 12px 4px;
	}
}

::v-deep .school-selector .v-select__slot {
	min-width: 16em !important;
}

.darker-background {
	background-color: $ads-light-10;
	padding: 0px;
}
</style>
