<template>
	<v-container :class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'">
		<v-row class="my-6" :no-gutters="true">
			<div style="display: flex; align-items: center" @click="goBack()">
				<v-icon class="mr-2">arrow_back</v-icon>
				<a :align="'center'" style="color: #041e42">Back</a>
			</div>
		</v-row>
		<Alert
			class="embeddedalert"
			show-alert
			type="info"
			text="Quality Improvement Plan"
			subtext="Below you will find the strengths and goals that you have submitted to form your quality improvement plan. 
			Use the filters to display the data as desired."
			in-page
		/>

		<TabNavigation
			:items="[
				{ id: '1', tab: 'STRENGTHS' },
				{ id: '2', tab: 'GOALS' },
			]"
			:center-active="true"
			class="custom-tab"
		>
			<template #content1>
				<AdsDataTable
					:headers="strengthHeaders"
					:loading="loading"
					:items="collatedStrengths"
				>
					<template #loading>
						<v-skeleton-loader
							:types="{ trow: 'table-cell@6' }"
							class="mt-3"
							:type="'trow@3'"
						></v-skeleton-loader>
					</template>
					<template #[`item.exceedingTheme`]="{ item }">
						{{ item.exceedingTheme[0] ? item.exceedingTheme[0] : "-" }}
						<b
							v-if="item.exceedingTheme.length > 1"
							class="secondary-text ml-2"
						>
							+{{ item.exceedingTheme.length - 1 }}
						</b>
					</template>
					<template #[`item.evidenceAttached`]="{ item }">
						<v-icon class="" v-if="item.evidenceAttached" small>done</v-icon>
						<div class="secondary-text" v-else>-</div>
					</template>
					<template #[`item.updatedString`]="{ item }">
						<span class="secondary-text">
							<template v-if="item.updatedString">
								Last updated {{ item.updatedString }}
							</template>
							<template v-else> Last updated -- </template>
						</span>
					</template>
					<template #[`item.edit`]="{ item }">
						<div class="edit-icon-wrapper">
							<v-icon :outlined="true" @click="openStrength(item)"
								>mode_edit_outline</v-icon
							>
						</div>
					</template>
				</AdsDataTable>
			</template>
			<template #content2>
				<AdsDataTable
					:headers="goalsHeaders"
					:loading="!goalsReady"
					:items="goals"
				>
					<template #loading>
						<v-skeleton-loader
							:types="{ goalrow: 'table-cell@4' }"
							class="mt-3"
							:type="'goalrow@3'"
						></v-skeleton-loader>
					</template>
					<template #[`item.due`]="{ item }">
						<span class="secondary-text">
							{{ formatDate(item.due) }}
						</span>
					</template>
					<template #[`item.progress`]="{ item }">
						<span class="secondary-text">
							Last updated {{ getMostRecentProgressNote(item) }}
						</span>
					</template>
					<template #[`item.summary`]="{ item }">
						<span>
							{{
								item.summary.slice(0, 35) +
								(item.summary.length > 35 ? "..." : "")
							}}
						</span>
					</template>
					<template #[`item.status`]="{ item }">
						<progress-chip :type="2" :date="item.status" />
					</template>
					<template #[`item.edit`]="{ item }">
						<div class="edit-icon-wrapper">
							<v-icon :outlined="true" @click="openGoalEdit(item)"
								>mode_edit_outline</v-icon
							>
						</div>
					</template>
				</AdsDataTable>
			</template>
		</TabNavigation>
		<v-dialog
			v-model="isEditingStrength"
			max-width="1000px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<record-strength
				@handleBack="handleBack"
				:strength="selectedStrength"
				:title="selectedStrength.title"
				mode="dialog"
			/>
		</v-dialog>
		<v-dialog
			v-model="isEditingGoal"
			max-width="1000px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<goal-display
				@handleBack="handleBack"
				mode="edit"
				:goal-id="selectedGoal.id"
				:selected-issues="selectedGoalIssues"
				:summary="selectedGoal.summary"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import { Alert, TabNavigation, AdsDataTable } from "@nswdoe/doe-ui-core"
import RecordStrength from "./RecordStrength.vue"
import ProgressChip from "./ProgressChip.vue"
import GoalDisplay from "./GoalDisplay.vue"
import { mapGetters } from "vuex"

export default {
	name: "ImprovementPlan",
	props: {},
	data() {
		return {
			strengthHeaders: [
				{ text: "Element", value: "element" },
				{ text: "Summary", value: "summary" },
				{ text: "Exceeding theme", value: "exceedingTheme" },
				{ text: "Evidence", value: "evidenceAttached" },
				{
					text: "Updated",
					value: "updatedString",
					sort: (a, b) => a.updated - b.updated,
				},
				{ text: "", value: "edit", sortable: false, width: 40, align: "end" },
			],
			goalsHeaders: [
				{ text: "Due", value: "due" },
				{ text: "Summary", value: "summary" },
				{ text: "Progress notes", value: "progress" },
				{ text: "Status", value: "status" },
				{ text: "", value: "edit", sortable: false, width: 40, align: "end" },
			],
			isEditingStrength: false,
			isEditingGoal: false,
			collatedStrengths: [],
			selectedStrength: {},
			selectedGoal: {},
		}
	},
	created() {
		if (this.readyToLoadStrengths) {
			this.collatedStrengths = this.loadCollatedStrengths()
			this.$store.dispatch("fetchIssues")
			this.$store.dispatch("fetchGoals", this.selectedQualityArea.short)
		}
	},
	computed: {
		...mapGetters({
			issues: "issues",
			structure: "selectedQualityAreaStructure",
			assessment: "selectedAssessment",
			issuesAreLoading: "issuesAreLoading",
			assessmentIsLoading: "assessmentIsLoading",
			structureIsLoading: "qualityAreaStructureIsLoading",
			hasSelectedQualityArea: "hasSelectedQualityArea",
			selectedQualityArea: "selectedQualityArea",
			userProfile: "profile",
			goals: "goals",
			goalsAreLoading: "goalsAreLoading",
		}),
		selectedGoalIssues() {
			if (!this.selectedGoal.issues) return []

			let issues = []
			for (let i = 0; i < this.selectedGoal.issues.length; i++) {
				issues.push(
					this.issues.find((x) => x.id == this.selectedGoal.issues[i]),
				)
			}
			return issues
		},
		readyToLoadStrengths() {
			return (
				!(this.structureIsLoading || this.assessmentIsLoading) &&
				this.hasSelectedQualityArea
			)
		},
		loading() {
			return (
				!this.readyToLoadStrengths ||
				this.issuesAreLoading ||
				this.collatedStrengths == []
			)
		},
		goalsReady() {
			return !this.loading && !this.goalsAreLoading
		},
	},
	watch: {
		readyToLoadStrengths(newVal) {
			if (newVal == true) {
				this.collatedStrengths = this.loadCollatedStrengths()
				this.$store.dispatch("fetchIssues")
				this.$store.dispatch("fetchGoals", this.selectedQualityArea.short)
			}
		},
		isEditingStrength(newVal) {
			if (newVal == false) {
				this.collatedStrengths = this.loadCollatedStrengths()
			}
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return ""
			if (typeof date == "string" && new Date(date) != "Invalid Date")
				date = new Date(date)
			else if (typeof date == "string") return ""
			return typeof date == "object" ? date.toDateString().slice(4) : date
		},
		openGoalEdit(item) {
			this.selectedGoal = item
			this.isEditingGoal = true
		},
		handleBack() {
			this.isEditingStrength = false
			this.isEditingGoal = false
			// fetch updates for goals
			this.$store.dispatch("fetchGoals")
		},
		openStrength(row) {
			this.selectedStrength = row
			this.isEditingStrength = true
		},
		goBack() {
			this.$emit("goBack")
		},
		getMostRecentProgressNote(item) {
			if (item.progressNotes.length == 0) return "-"

			let dateString = new Date(
				JSON.parse(
					JSON.stringify(item.progressNotes),
				) /* yup, gotta avoid an infinite loop */
					.sort((a, b) => a.date - b.date)[0].date,
			)?.toLocaleDateString("en-au")

			if (!dateString) return "-"
			return dateString
		},
		loadCollatedStrengths() {
			if (!this.readyToLoadStrengths) return []

			let strengths = []
			let assessmentQualityArea = this.assessment.qualityAreas.find(
				(x) => x.name == this.selectedQualityArea.short,
			)

			for (let i in assessmentQualityArea.standards) {
				let standard = assessmentQualityArea.standards[i]
				for (let j in standard.elements) {
					let element = standard.elements[j]
					for (let k in element.strengths) {
						let strength = element.strengths[k]
						let title = this.structure.children.standards
							.find((x) => x.short == standard.name)
							?.children.standards.find((x) => x.short == element.name)
							?.children.strengths.find((x) => x.id == strength.id)?.text

						let nStrength = {
							...strength,
							evidenceAttached:
								strength.evidence.filter((e) => e.evidence).length > 0,
							exceedingTheme: [],
							summary: strength.text
								? strength.text.slice(0, 30) +
								  (strength.text.length > 30 ? "..." : "")
								: "-",
							element: element.number,
							title: title,
							updatedString: strength.updated
								? new Date(strength.updated).toLocaleDateString("en-au")
								: "",
						}
						for (let ev of nStrength.evidence) {
							if (ev.evidence == null) {
								ev.evidence = []
							}
						}
						if (strength.embed) nStrength.exceedingTheme.push("Embed")
						if (strength.reflect)
							nStrength.exceedingTheme.push("Critically reflect")
						if (strength.engagement)
							nStrength.exceedingTheme.push("Meaningful engagement")
						strengths.push(nStrength)
					}
				}
			}
			return strengths
		},
	},
	components: {
		Alert,
		TabNavigation,
		AdsDataTable,
		RecordStrength,
		ProgressChip,
		GoalDisplay,
	},
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
@import "../scss/custom-tab.scss";
.secondary-text {
	color: $ads-dark-60;
}
.edit-icon-wrapper {
	display: flex;
	justify-content: right;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
::v-deep .v-data-table__wrapper {
	tbody tr {
		cursor: pointer;
	}
}
::v-deep .v-skeleton-loader__trow.v-skeleton-loader__bone,
::v-deep .v-skeleton-loader__goalrow.v-skeleton-loader__bone {
	display: flex;
	justify-content: space-between;
	.v-skeleton-loader__table-cell {
		width: 150px;
		.v-skeleton-loader__text {
			height: 45%;
		}
	}
}
::v-deep .theme--light.v-data-table th,
::v-deep .theme--light.v-data-table td {
	padding: 0px 5px;
}
</style>
