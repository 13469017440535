import api from "../../api"

const centreModule = {
	state: {
		loading: {
			settings: true,
			centre: true,
		},
		status: {
			settings: "Saved",
		},
		centre: {
			settings: {
				service: {
					name: "",
					schoolmotto: "",
					principal: "",
					approvalNumber: "",
					contactName: "",
					contactNumber: "",
					provider: "",
					providerNumber: "",
					providerContact: "",
					educators: "",
				},
				notifications: {},
			},
		},
		locationsName: [],
		selectedLocation: { schoolCode: "", schoolFullName: "" },
	},
	getters: {
		settings: (state) => {
			return state?.centre?.settings
				? state.centre.settings
				: {
						service: {
							name: "",
							schoolmotto: "",
							principal: "",
							approvalNumber: "",
							contactName: "",
							contactNumber: "",
							provider: "",
							providerNumber: "",
							providerContact: "",
							educators: "",
						},
						notifications: {},
				  }
		},
		settingsAreLoading: (state) => state.loading["settings"],
		settingsStatus: (state) => state.status.settings,
		centre: (state) => state.centre,
		centreId: (state) => state.centre.acecqa_service_id,
		centreIsLoading: (state) => state.loading["centre"],

		locationsName: (state) => state.locationsName,
		selectedLocation: (state) => state.selectedLocation,
		selectedName: (state) =>
			state.selectedLocation ? state.selectedLocation.schoolFullName : "",
		selectedSchool: (state) =>
			state.selectedLocation ? state.selectedLocation.schoolCode : "",
	},
	mutations: {
		SET_SETTINGS: function (state, settings) {
			state.centre.settings = settings
		},
		SET_SETTINGS_LOADING: function (state, loading = true) {
			state.loading.settings = loading
		},
		SET_SETTINGS_STATUS: function (state, status = "Saved") {
			state.status.settings = status
		},
		SET_CENTRE(state, centre) {
			state.centre = centre
		},
		SET_CENTRE_LOADING: function (state, loading = true) {
			state.loading.centre = loading
			state.loading.settings = loading
		},
		SET_LOCATIONS: function (state, locations) {
			state.locationsName = locations
		},
		SET_SELECTED_LOCATION: function (state, location) {
			state.selectedLocation = location
		},
		SET_SELECTED_SCHOOL: function (state, code) {
			state.selectedLocation = state.locationsName.filter(
				(a) => a.schoolCode == code,
			)[0]
		},
	},
	actions: {
		getLocationsNames: function ({ dispatch, commit }) {
			let location = this.state.groupInfo.location

			let locations = [location]
			let locationsNames = []

			return new Promise((resolve, reject) => {
				api.locations
					.getLocation(locations)
					.then((data) => {
						let schoolCodes = data?.data?.schools || []
						if (schoolCodes && schoolCodes.length > 0) {
							locations.forEach((locationId) => {
								let myLocation = schoolCodes.find(
									(dataObj) => dataObj.schoolCode == locationId,
								)
								if (myLocation) locationsNames.push(myLocation)
							})
							if (locationsNames.length == 0) {
								//commit("setAppError", {
								//	errorStatus: true,
								//	errorMessage:
								//		"Could not find your location. (" + location + ")",
								//})
								console.warn('could not find your location - e119')
								locations.forEach((locationId) => {
									locationsNames.push({
										schoolCode: locationId,
										schoolFullName: locationId,
									})
								})
							}
						} else {
							/*commit("setAppError", {
								errorStatus: true,
								errorMessage: "Could not load school code data.",
							})*/
							console.warn('could not load school code data - e132')
							locations.forEach((locationId) => {
								locationsNames.push({
									schoolCode: locationId,
									schoolFullName: locationId,
								})
							})
						}
						if (locationsNames.length > 0) {
							commit("SET_SELECTED_LOCATION", locationsNames[0])
						}
						commit("SET_LOCATIONS", locationsNames)
						resolve(locationsNames)
					})
					.catch((e) => {
						//console.error(e)
						dispatch("displayApiError", [
							"locations",
							"fetch",
							e.reason || e.status,
						])
						reject(e)
					})
			})
		},
		fetchCentre: function ({ rootState, state, commit, dispatch }) {
			commit("SET_CENTRE_LOADING")
			return new Promise((resolve, reject) => {
				api.centre
					.getCentre(
						rootState.profile.username,
						state.selectedLocation.schoolCode,
					)
					.then((response) => {
						commit("SET_CENTRE", response)
						resolve(response)
					})
					.catch((e) => {
						if (!e) e = { reason: "error:unknown" }
						//console.error("APIERROR", e)
						dispatch("displayApiError", [
							"centre",
							"fetch",
							e.reason || e.status || "error:unknown",
						])
						reject(e)
					})
					.finally(() => {
						commit("SET_CENTRE_LOADING", false)
					})
			})
		},
		saveSettings: function ({ state, commit, dispatch }) {
			commit("SET_SETTINGS_STATUS", "Saving")
			api.centre
				.setSettings(state.centre?.acecqa_service_id, state.centre?.settings)
				.then((response) => {
					if (response.status >= 300 || response.status < 200)
						throw Promise.reject(response)
					commit("SET_SETTINGS_STATUS", "Saved")
				})
				.catch((e) => {
					//console.error(e)
					dispatch("displayApiError", [
						"settings",
						"save",
						e && e.reason ? e.reason || e.status : 500,
					])
					commit("SET_SETTINGS_STATUS", "Unsaved")
					return e
				})
		},
	},
}

export default centreModule
